// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client'
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";

const CONTAINERS_LIST = gql`
    query containers {
        containers(orderBy: {
            field: "id",
            order: DESC
        }){
            id
            name
            purchase_price
            notes
        }
    }`;

const EXCHANGE_RATES = gql`
    query sets($type: String!, $condition: String!, $sold: Boolean!) {
        sets(type: $type, condition: $condition, sold: $sold) {
            id
            set_num
            hash
            set {
                name
                category
            }
            container {
                id
                name
            }
            condition
            storage {
              id
              name
            }
            notes
            box
            instruction
            completeness
            purchase_price
            estimated_price
            selling_price
            sales_profit
            sale_at
        }
    }`;


const SetsListEntriesList = ({entries, container}) => {
  // console.log(container);
  return entries.sets.filter((node) => {
    if(!container || node.container === container) return true;
    return false;
  })
    .sort(function (a, b) {
      return (a.sale_at > b.sale_at ? -1 : 1)
    })
    .map((node) => {
      let percent = parseFloat((parseFloat(node.selling_price) / parseFloat(node.purchase_price)) * 100).toFixed(2);
      return (
        <div className={"grid table"} key={node.id}>
          <div className={"grid-start grid-end-1 grid-id-column"}>
            <b>{node.id}</b>
          </div>
          <div className={"grid-start-1 grid-end-2 grid-align-left image-widget-small-image"}>
            <ImageWidget set={node.set_num}
                         type={node.set_num.search('-1') !== -1 ? (node.condition === 'used' ? BrickLinkImageType.set : BrickLinkImageType.box) : BrickLinkImageType.minifig}/>
          </div>
          <div className={"grid-start-2 grid-end-7 grid-align-left"}>
            <b>{node.set_num} - {node.set.name}</b><br/>
            {node.storage ? <small>({node.storage.name})</small> : null}
            <br/><br/>
            <small>{node.sale_at}</small>
          </div>
          <div className={"grid-start-7 grid-end-8"}>
            {node.purchase_price ? <>{node.purchase_price} zł<br/><br/></> : <>-</>}
          </div>
          <div className={"grid-start-8 grid-end-9"}>
            {node.selling_price ? <>
              {node.selling_price} zł<br/>
              {node.purchase_price ? <>{percent} %</> : null}
            </> : <>-</>}
          </div>
          <div className={"grid-start-9 grid-end-10"}>
            {node.sales_profit ? <>{node.sales_profit} zł<br/><br/></> : <>-</>}
          </div>
          <div className={"grid-start-10 grid-end"}>
            <Link to={'/set/sell/' + node.id}>Edit</Link>
            <Link to={'/set/delete/' + node.id + '?return=/sets/sold'}>Delete</Link>
          </div>
        </div>
      )
    });
}

const TableHeader = () => <div className={'table header grid'}>
  <div className={"grid-start grid-end-7 grid-align-left"}>Set</div>
  <div className={"grid-start-7 grid-end-8"}>Purchase</div>
  <div className={"grid-start-8 grid-end-9"}>Selling</div>
  <div className={"grid-start-9 grid-end-10"}>Profit</div>
  <div className={"grid-start-10 grid-end options-cell"}>Options</div>
</div>

const SetsListWithData = ({entries, container, onLoadMore, hasNextPage}) => {
  return <>
    <TableHeader/>
    <SetsListEntriesList container={container} entries={entries}/>
  </>
}

const SoldSets = () => {

  const [containerId, setContainerId] = useState(false);
  const {data: containerData, loading: containerLoading, error: containerError} = useQuery(CONTAINERS_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const {data, loading, error} = useQuery(EXCHANGE_RATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: false,
      condition: false,
      sold: true
    },
  });

  useEffect(() => {
    console.log(window.location);
    const query = new URLSearchParams(window.location.search);
    console.log(query.has('container'));
    if (query.has('container')) {
      setContainerId(query.get('container'));
    }
  }, []);

  if (loading || containerLoading) return <p>Loading...</p>;
  if (error || containerError) return <p>Error :(</p>;

  console.log(data);

  return (
    <div>
      <nav className={"second-line"}>
        <div className={"grid"}>
          <div className={"grid-start grid-end-9 grid-align-left"}>
            <div className={"nav-title"}>
              Sold
            </div>
          </div>
          <div className={"grid-start-9 grid-end-10"}>
            <label>Filter by container:</label>
          </div>
          <div className={"grid-start-10 grid-end"}>
            <select name={"containers"} onChange={(event) => {
              setContainerId(event.target.value);
            }}>
              <option value={""}>All</option>
              {containerData ? containerData.containers.map((node) => (
                <option value={node.id} key={node.id} selected={node.id === containerId}>{node.name}</option>
              )) : null}
            </select>
          </div>
        </div>
      </nav>
      <SetsListWithData container={containerId} entries={data || {}}/>
    </div>
  )
}

export default SoldSets
