import React, { useEffect, useState } from 'react'
import { _delete, _get, apiPost } from '../functions'
import { BrickLinkImageType } from './BrickLinkImageSrc'

const InventorySetsActions = ({ set_num, user_sets = 0, user_sets_inventory = 0 }) => (
  <div className="inventory-part-actions">
    <a className="button" href={`/inventory/minifig/create?set_num=${set_num}`} target="_blank" rel="noreferrer">Add to Inventory</a>
    {user_sets ? (
      <a className="button blue hovered" href={`/minifigs?set_num=${set_num}&type=minifig`} target="_blank" rel="noreferrer">
        {user_sets} in inventory
      </a>
    ) : null}
    {user_sets_inventory ? (
      <a className="button blue" href={`/user/sets/contain/${set_num}/0`} target="_blank" rel="noreferrer">
        {user_sets_inventory} in another sets
      </a>
    ) : null}
  </div>
)

export default InventorySetsActions
