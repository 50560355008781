import React, {useContext} from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import {connect, ReactReduxContext} from 'react-redux'
import {ImageWidget} from "../../components/ImageWidget";
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {AllegroButtonNewSet} from "../../components/AllegroButton";
import BricklinkButtonSet from "../../components/BricklinkButton";
import PromoklockiButton from "../../components/PromoklockiButton";

const GET = gql`
    query set($id: ID) {
        set(id: $id){
            id,
            hash
            set_num,
            set {
                set_num
                name
            }
            notes,
            condition,
            instruction,
            box,
            container_id,
            storage_id,
            purchase_price,
            estimated_price
        }
    }`;

const UPDATE = gql`
    mutation updateSet($id: ID!, $notes: String, $container_id: Int, $storage_id: Int $instruction: Boolean, $box: Boolean, $purchase_price: String, $estimated_price: String, $stage: String) {
        updateSet(
            id: $id,
            notes: $notes,
            instruction: $instruction
            box: $box,
            container_id: $container_id
            storage_id: $storage_id
            purchase_price: $purchase_price
            estimated_price: $estimated_price
            stage: $stage
        ){
            id
        }
    }`;


const Update = (props) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {storages, containers} = state;

    console.log(props);
    let id = props.match.params.id;
    const { loading, error, data } = useQuery(GET,
        {
            variables: { id },
            fetchPolicy: 'cache-and-network',
        });

    const [updateSet, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(UPDATE);

    if (loading) return <p>download...</p>;
    if (error) return <p>Error :(</p>;

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    console.log(data);
    console.log(mutationData);

    if(mutationData){
        if(data.set.condition === 'used'){
            props.push('/sets/used');
        } else {
            props.push('/sets/new');
        }
    }

    return (
        <div className={"containers form"}>

            <Formik
                initialValues={data.set}
                validate={values => {
                    const errors = {};
                    if (!values.set_num) {
                        errors.set_num = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    updateSet({
                        variables: values
                    }).then(r => console.log('result: ' + r));

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Field type="hidden" name="id" />
                        <ul>
                            <li>
                                &nbsp;
                            </li>
                            <li>
                                <h2>
                                    {data.set.set.name} (#{data.set.hash})
                                </h2>
                            </li>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    <ImageWidget set={data.set.set.set_num} type={BrickLinkImageType.set}/>
                                </div>
                            </li>
                            <li>
                                <label htmlFor="set_num">Set</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="set_num"
                                    disabled={true}
                                />
                                {errors.set_num && touched.set_num && errors.set_num}
                            </li>

                            <li>
                                <label htmlFor="condition">Orgins</label>
                            </li>
                            <li>
                                <Field as="select" name="container_id">
                                    <option/>
                                    {containers.filter((node) => node != null).map((node) => (
                                        <option value={node.id} key={node.id} selected={node.id === data.set.container_id}>{node.name}</option>
                                    ))}
                                </Field>
                            </li>

                            <li>
                                <label htmlFor="condition">Storages</label>
                            </li>
                            <li>
                                <Field as="select" name="storage_id">
                                    <option value></option>
                                    {storages.filter((node) => node != null).map((node) => (
                                        <option value={node.id} key={node.id} selected={node.id === data.set.storage_id}>{node.name}</option>
                                    ))}
                                </Field>
                            </li>

                            <li>
                                <label htmlFor={"notes"}>Notes</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                            </li>

                            <li>
                                <label htmlFor={"purchase_price"}>Purchase price</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"input"} name={"purchase_price"}/>
                            </li>

                            <li>
                                <label htmlFor={"estimated_price"}>
                                    Estimate price
                                </label>
                            </li>
                            <li>
                                <Field type={"text"} as={"input"} name={"estimated_price"}/>
                            </li>
                            <li>
                                <label>&nbsp;</label>
                            </li>
                            <li>
                                <AllegroButtonNewSet set={data.set.set_num} />
                                &nbsp;
                                <BricklinkButtonSet set={data.set.set_num} />
                                &nbsp;
                                <PromoklockiButton set={data.set.set_num}/>
                            </li>

                            {data.set.condition === 'used' ?
                              <>
                                <li>
                                    <label htmlFor={"instruction"}>Instruction</label>
                                </li>
                                <li>
                                    <Field type={"checkbox"} as={"input"} name={"instruction"}/>
                                </li>
                              </>: null}

                            {data.set.condition === 'used' ?
                              <>
                                <li>
                                    <label htmlFor={"box"}>Box</label>
                                </li>
                                <li>
                                    <Field type={"checkbox"} as={"input"} name={"box"}/>
                                </li>
                              </>: null}

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Update
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Update)
