import React, {useEffect,} from 'react';
import {Link} from 'react-router-dom'
import {gql, useLazyQuery} from '@apollo/client';
import {BrickLinkImageSrc, BrickLinkImageType} from "../../components/BrickLinkImageSrc";

const ALBUM_CATEGORIES = gql`
    query getAlbumCategory {
        getAlbumCategory {
            cat_id
            name
            preview
        }
    }`;

const AlbumCategoriesList = () => {

  const [getCategories, {data, error}] = useLazyQuery(ALBUM_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    getCategories()
  }, []);

  if (!data) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return data.getAlbumCategory.map((node) => {
    const json = JSON.parse(node.preview);
    return (
      <li key={node.cat_id} className={"album-category"}>
        <div>
            {json.map((node) => {
              const url = BrickLinkImageSrc(node.part_num, node.col_id, BrickLinkImageType.part);
              return (<img key={node.part_num} src={url} alt={node.part_num} loading="lazy"/>)
            })}
        </div>
        <Link to={'/album/' + node.cat_id} className={"button"}>{node.name}</Link>
      </li>
    )
  });
};

const AlbumCategories = () => {

  return (
    <div className={"inventory parts list"}>
      <ul className={"tiles album"}>
        <AlbumCategoriesList/>
      </ul>
    </div>
  )
}

export default AlbumCategories
