import React, { useContext, useEffect, useState } from 'react'

import _ from 'lodash'
import { ReactReduxContext } from 'react-redux'
import { BrickLinkImageType } from '../../components/BrickLinkImageSrc'
import ImageWidget from '../../components/ImageWidget'
import { CategoryTag } from '../../components/CategoryTag'
import BricklinkButton from '../../components/BricklinkButton'
import {_get, showErrorNotification} from '../../functions'
import InventoryQuantityAction from "../../components/InventoryQuantityAction";
import SearchButton from "../../components/SearchButton";

// const SETS_LIST = gql`
//     query Sets {
//         allSets(orderBy: {
//             field: "id",
//             order: DESC
//         }){
//             id
//             set {
//                 set_num
//                 name
//                 basic_price
//             }
//             container {
//                 id
//                 name
//             }
//             parts
//             condition
//             notes
//             box
//             instruction
//             completeness
//             purchase_price
//             estimated_price
//         }
//     }`;

// const PARTS_LIST = gql`
//     query userSetInventoryMissingPart {
//         userSetInventoryMissingParts {
//             id
//             user_set_id
//             part {
//                 part_num
//                 cat_id
//                 category {
//                     name
//                 }
//                 name
//             }
//             minifig {
//                 name
//             }
//             part_num
//             minifig_num
//             type
//             quantity
//             expected
//             col_id
//         }
//     }
// `;

const FilterData = ({
  parts, set, sets, wanted, colors, categories,
}) => {
  const setList = []
  // sets
  sets.map((node) => {
    setList[node.id] = node
  })

  console.log(setList);

  let items = parts

  if (set) {
    console.log('filter by set')
    items = items.filter((node) => parseInt(node.user_set_id) === parseInt(set))
  }

  const groups = []
  items.map((node) => {
    const { part_num } = node.part
    let index = false

    groups.map((value, key) => {
      if (value.part_num === part_num) {
        index = key
      }
    })

    if (!index) {
      groups.push({
        part_num,
        part: node.part,
        sets: [],
        colors: [],
        category: categories[node.part.cat_id],
      })

      index = groups.length - 1
    }

    if (!groups[index].colors[node.col_id]) {
      groups[index].colors[node.col_id] = 0
    }

    const missing = (node.expected - node.quantity)

    groups[index].colors[node.col_id] += missing

    const _set = setList[node.user_set_id]

    console.log(_set);
    const obj = {
      set: {
        set_num: _set.set_num,
        user_set_id: node.user_set_id,
        name: _set.set.name,
        missing_parts: _set.parts,
        type: _set.type,
      },
      part: {
        id: node.id,
        col_id: node.col_id,
        quantity: node.quantity,
        expected: node.expected,
        missing,
      },
    }
    groups[index].sets[groups[index].sets.length] = obj

    return true
  })

  return <PartsList entries={groups} colors={colors} />
}

const PartsList = ({ entries, colors }) => {
  const [visible, setVisible] = useState(false)

  return entries.map((node) => {
    const color = Object.keys(node.colors)[0]
    return (
      <div className="grid grid-border-bottom" key={node.id}>
        <div className="grid-start grid-end-1">
          <ImageWidget set={node.part_num} color={color} type={BrickLinkImageType.part} />
          <SearchButton part_num={node.part_num} />
          <br />
          <BricklinkButton type="P" set={node.part_num} color={color} name={node.part_num} />
        </div>
        <div className="grid-start-1 grid-end grid-align-left">
          <CategoryTag name={node.category.name} />
          <br />
          <br />
          <br />
          {node.part.name}
          <br />
          <br />
          {node.colors.map((key, index) => {
            const ids = `${node.part_num}-${index}`
            return (
              <div
                style={{ width: '100%', float: 'left' }}
                className="grid-align-left"
                key={index}
              >
                {key} x <b>{colors[index].name}</b> &nbsp;&nbsp;
                <a
                  className="link-search"
                  onClick={() => setVisible(visible === ids ? false : ids)}
                >
                  {visible === ids ? 'HIDE' : 'SHOW'}
                </a>
                <br />
                <table
                  key={index}
                  border={1}
                  style={{
                    margin: '10px 0 30px 0',
                    float: 'left',
                    border: `3px solid #${colors[index].rgb}`,
                    display: visible === ids ? 'block' : 'none',
                  }}
                >
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Set</th>
                      <th>Quantity/Expected</th>
                      <th>Global missing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {node.sets.filter((node) => node.part.col_id === index).map((node) => {
                        console.log(node); return (
                              <tr key={node.part.id} id={`part_id_${node.part.id}`}>
                                <td className="image">
                                    <ImageWidget set={node.set.set_num} type={node.set.type} maxHeight={'40px'} />
                                  </td>
                                <td>
                                    <a
                                          href={`/set/inventory/${node.set.user_set_id}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <b>{node.set.user_set_id}</b> - {node.set.set_num}
                                        </a>
                                  </td>
                                <td style={{ textAlign: 'center' }}>
                                    <InventoryQuantityAction partId={node.part.id} quantity={node.part.quantity} expected={node.part.expected} />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {node.set.missing_parts}
                                  </td>
                              </tr>
                            )})}
                  </tbody>
                </table>

                {/*    return <> */}
                {/*        <div style={{width: '100px'}}> */}
                {/*            <ImageWidget set={sets[node.set_num]} type={BrickLinkImageType.set}/> */}
                {/*        </div> */}
                {/*        {node.missing} x */}
                {/*        <a href={"/set/inventory/" + node.set_num} */}
                {/*           target={"_blank"} */}
                {/*           rel="noopener noreferrer" */}
                {/*        > */}
                {/*            <b>{node.set_num}</b> - #{sets[node.set_num]} */}
                {/*        </a> ({node.quantiity}/{node.expected}) */}
                {/*    </> */}
                {/* })}<br/><br/><br/></> */}
              </div>
            )
          })}
        </div>
      </div>
    )
  })
}

const List = () => {
  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const { colors, categories } = state

  const [setId, setSetId] = useState(false)
  const [sets, setSets] = useState(false)
  const [wanted, setWanted] = useState(0)
  const [parts, setParts] = useState(false)

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    _get('user/sets', {
      condition: 'used',
      type: 'set,minifig',
      sold: '0',
      stage: 'reconstruction',
    }).then((data) => {
        setSets(data)
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })

    _get('missing/parts', {})
      .then((data) => {
        setParts(data)
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
  }, [])

  // let colorsRaw = [];
  // let setsRaw = [];

  // const {data, loading, error} = useQuery(SETS_LIST, {
  //     fetchPolicy: 'cache-and-network',
  // });

  // const {data: partsData, loading: partsLoading, error: partsError} = useQuery(PARTS_LIST, {
  //     fetchPolicy: 'cache-and-network'
  // });
  //
  // if (partsLoading ) return (<p>Loading...</p>);
  // if (partsError) return (<p>Error :(</p>);

  let sortedInventoryData = false

  if (parts) {
    // sortedInventoryData = parts.slice();

    const iteratees_category = (obj) => (obj.part ? obj.part.cat_id : 9999)
    const iteratees_name = (obj) => (obj.part ? obj.part.name : '')
    sortedInventoryData = _.sortBy(parts, [iteratees_category, iteratees_name])
  }

  return (
    <div className="sets list">
      <nav className="second-line">
        <div className="grid">
          <div className="grid-start grid-end">
            <div className="nav-title">
              Wanted parts
            </div>
          </div>
        </div>
      </nav>

      <div className="system-instruction">
        Tylko elementy oznaczone jako wanted w setach oznaczonych jako "reconstruction" pokażą się na tej liście.
      </div>

      {sets && parts && sortedInventoryData
        ? (
          <FilterData
            set={setId}
            sets={sets}
            parts={sortedInventoryData}
            wanted={wanted}
            colors={colors}
            categories={categories}
          />
        ) : <span>Loading...</span>}
    </div>
  )
}

export default List
