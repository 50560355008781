import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import './stylesheets/main.scss'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';

import { ThemeProvider } from 'styled-components'
import configureStore, { history } from './configureStore'
import App from './App'

// import _parts from './parts';
// import _parts from './resources/parts_json'

// const defaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
// //

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const store = configureStore()

const theme = {
  body: {
    border: '1px solid red',
  },
  fonts: {
    button: {
      fontSize: '10px',
      color: 'red',
    },
  },
}

if(process.env.SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


const render = () => {
  // console.log(_parts['2345p44']);
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <AppContainer>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <App history={history} store={store} />
          </Provider>
        </ApolloProvider>
      </AppContainer>
    </ThemeProvider>,
    document.getElementById('root'),
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render()
  })
}
