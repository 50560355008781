import React from 'react'

const IconInstruction = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.001 297.001" style={{ width: 16, display: 'inline-block', padding: 6 }}>
       <path d="m287.034 60.873-20.819-.001V39.321a9.965 9.965 0 0 0-8.49-9.856c-.852-.128-21.134-3.074-45.557 1.37-27.227 4.954-48.941 17.171-63.668 35.64-14.728-18.469-36.442-30.686-63.668-35.64-24.424-4.443-44.706-1.498-45.557-1.37a9.967 9.967 0 0 0-8.49 9.856v21.551H9.966C4.463 60.872 0 65.335 0 70.839v187.805a9.966 9.966 0 0 0 13.244 9.412c.748-.259 75.431-25.747 131.12-.345a9.963 9.963 0 0 0 8.273 0c55.533-25.33 130.376.088 131.12.345a9.973 9.973 0 0 0 9.051-1.287 9.966 9.966 0 0 0 4.193-8.124V70.84c-.001-5.504-4.463-9.967-9.967-9.967zM19.933 245.309V80.805h10.852v132.726a9.968 9.968 0 0 0 3.458 7.539 9.972 9.972 0 0 0 7.969 2.319c.55-.08 43.846-6.024 75.478 15.679-38.965-6.663-77.963 1.044-97.757 6.241zm118.601-15.229c-13.932-12.588-32.079-21.1-53.702-25.034-10.406-1.894-20.06-2.446-27.78-2.446-2.292 0-4.414.049-6.333.126V48.473h-.001c19.155-.864 65.752 1.184 87.816 38.587v143.02zm19.932-143.019c21.985-37.243 68.655-39.384 87.816-38.563v154.228c-8.383-.338-20.62-.136-34.114 2.32-21.623 3.934-39.77 12.445-53.702 25.034V87.061zm20.811 152.013c31.636-21.716 74.955-15.766 75.495-15.686a9.965 9.965 0 0 0 11.443-9.857V80.806h10.852v164.503c-19.8-5.199-58.814-12.909-97.79-6.235z"/>
    </svg>
  )
}

export default IconInstruction
