import React from 'react';
import { useQuery, gql } from '@apollo/client';

const USER_SET_INVENTORY = gql`
    query userSetInventory($id: ID){
        userSetInventory(user_set_id: $id){
            id
            part {
                part_num
                name
                category {
                    cat_id
                    name
                }
            }
            part_num
            minifig {
                set_num
                name
                image
                parts
                inventory {
                    part_num
                    part {
                        name
                        part_num
                    }
                    color {
                        col_id
                        name
                    }
                }
            }
            minifig_num
            color {
                col_id
                name
            }
            notes
            quantity
            expected
            type
        }
    }`;
const USER_SET = gql`
    query userSet($id: ID){
        userSet(id: $id){
            set_num
            notes
            condition,
            instruction,
            box,
            container_id,
            purchase_price,
            estimated_price
            set {
                set_num
                image
                parts
                minifigs
                name
            }
        }
    }
`;

const Inventory = (props) => {

    let id = props.match.params.id;

    const { data: inventoryData, loading: inventoryLoading } = useQuery(USER_SET_INVENTORY, {
        variables: {id}
    });

    const { data, loading, error} = useQuery(USER_SET, {
        variables: {id}
    })

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if(!inventoryData) return <p>Loading inventory...</p>;

    let sortedInventoryData = false;
    if(sortedInventoryData === false && inventoryLoading === false && inventoryData) {
        sortedInventoryData = inventoryData.userSetInventory.slice();
    }

    let csv = "Name;Part Number;Minifig Number;Color;Color ID;Quantity;Expected;Missing;Type\n";
    for(let index = 0; index < sortedInventoryData.length; index++){
        console.log(index);
        const node = sortedInventoryData[index];
        const name = node.part ? node.color.name + ", " + node.part.name : node.minifig.name;
        csv += name + ";" + node.part_num + ";" + node.minifig_num + ";" + node.color.name + ";" + node.color.col_id + ";" + node.quantity + ";" + node.expected + ";" + (node.expected - node.quantity) + ";" + node.type + "\n";
    }

    console.log(csv);

    let escapeCSV = "data:application/octet-stream," + escape(csv).toString();
    let filename = data.userSet.set.set_num + "-" + id + ".csv";

    return (
        <div className={"export list"}>
            <h2>{data.userSet.set.set_num} : {data.userSet.set.name}</h2>
            <a href={escapeCSV} download={filename}>save to file</a>

            <br/><br/><br/><br/>
            <b>Preview</b><br/><br/>
            <textarea>{csv}</textarea>
        </div>
    )
}

export default Inventory
