import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import {BrickLinkImageSrc, BrickLinkImageType} from "../../components/BrickLinkImageSrc";

const GAME_ASSETS = gql`
    query game {
        game {
            part_num
            col_id
            cat_id
            category {
                cat_id
                name
            }
        }
    }`;

const GameBoard = () => {

  const [result, setResult] = useState('');
  let [continuity, setContinuity] = useState(0);

  const [getGame, {data, error}] = useLazyQuery(GAME_ASSETS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      console.log('result');
      setResult('');
    }
  });

  useEffect(() => {
    getGame()
  }, []);

  if (!data) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const url = BrickLinkImageSrc(data.game.part_num, data.game.col_id, BrickLinkImageType.part);
  const correct = data.game.cat_id;

  return (
    <>
      <div className={"part"}>
        <div className={"count"}>
          {continuity}
        </div>
        <div className={result}>
          <img src={url} alt={data.game.part_num} loading="lazy"/>
        </div>
      </div>
      <ul>
        {data.game.category.map((node) => {
          return (<li className={result !== '' ? (node.cat_id === correct ? 'green' : 'red') : ''} onClick={() => {
            if(node.cat_id === correct){
              setResult('green');
              let cont = parseInt(continuity) + 1;
              setContinuity(cont);
              setTimeout(() => {
                getGame();
              }, 500);
            } else {
              setResult('red');
              alert('Your result: ' + continuity);
              setContinuity(0)
              setTimeout(() => {
                getGame();
              }, 5000);
            }
          }}>
            {node.name}
          </li>)
        })}
      </ul>
    </>
  );

  //
  // data.game.map((node) => {
  //   const json = JSON.parse(node.preview);
  //   return (
  //     <li key={node.cat_id} className={"album-category"}>
  //       <div>
  //         {json.map((node) => {
  //           const url = BrickLinkImageSrc(node.part_num, node.col_id, BrickLinkImageType.part);
  //           return (<img src={url} alt={node.part_num} loading="lazy"/>)
  //         })}
  //       </div>
  //       <Link to={'/album/' + node.cat_id} className={"button"}>{node.name}</Link>
  //     </li>
  //   )
  // });
};

const Game = () => {

  return (
    <div className={"game"}>
      <ul className={"board"}>
        <GameBoard/>
      </ul>
    </div>
  )
}

export default Game
