import React from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import {ImageWidget} from "../../components/ImageWidget";
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {apiPost} from "../../functions";

const GET = gql`
    query set($id: ID) {
        set(id: $id){
            id,
            hash
            set_num,
            set {
                set_num
                name
            }
            purchase_price,
            selling_commission_cost,
            selling_shipping_cost,
            selling_price,
            sales_profit,
            sold,
            sale_at
        }
    }`;

const SellSet = (props) => {

    console.log(props);
    let id = props.match.params.id;
    const { loading, error, data } = useQuery(GET,
        {
            variables: { id },
            fetchPolicy: 'cache-and-network',
        });

    // const [updateSet, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(UPDATE);

    const calculateProfit = () => {

        let purchase_price = parseFloat(document.getElementById('purchase_price').value.replace(",","."));
        let selling_price = parseFloat(document.getElementById('selling_price').value);
        let selling_shipping_cost = parseFloat(document.getElementById('selling_shipping_cost').value);
        let selling_commission_cost = parseFloat(document.getElementById('selling_commission_cost').value);

        console.log(purchase_price);

        if(!purchase_price) purchase_price = 0;

        if(!selling_price) selling_price = 0;
        if(!selling_commission_cost) selling_commission_cost = 0;
        if(!selling_shipping_cost) selling_shipping_cost = 0;

        let profit = selling_price - purchase_price - selling_shipping_cost - selling_commission_cost;
        document.getElementById('sales_profit').value = profit.toFixed(2);
    }

    if (loading) return <p>download...</p>;
    if (error) return <p>Error :(</p>;

    // if (mutationLoading) return <p>Sending...</p>;
    // if (mutationError) return <p>Error :(</p>;
    //
    // console.log(data);
    // console.log(mutationData);
    //
    // if(mutationData){
    //     props.push('/sets/new');
    // }

    return (
        <div className={"containers form"}>

            <Formik
                initialValues={{...data.set, purchase_price: data.set.purchase_price?.replace(",",".")}}
                validate={values => {
                    const errors = {};
                    if (!values.set_num) {
                        errors.set_num = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    calculateProfit();

                    const purchase_price = document.getElementById('purchase_price').value;
                    const selling_price = document.getElementById('selling_price').value;
                    const selling_shipping_cost = document.getElementById('selling_shipping_cost').value;
                    const selling_commission_cost = document.getElementById('selling_commission_cost').value;

                    const profit = selling_price - purchase_price - selling_shipping_cost - selling_commission_cost;
                    const sales_profit = profit.toFixed(2);

                    apiPost("user/sets/sold/" + values.id, {
                        purchase_price: purchase_price,
                        sales_profit: sales_profit,
                        selling_commission_cost: selling_commission_cost,
                        selling_price: selling_price,
                        selling_shipping_cost: selling_shipping_cost,
                        sold: true,
                        sale_at: new Date().toISOString()
                    }).then((data) => {
                        props.push('/sets/sold');
                    })

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} noValidate={true}>
                        <Field type="hidden" name="id" />
                        <Field type="hidden" name={"sold"} value={"true"} />
                        <ul>
                            <li>
                                &nbsp;
                            </li>
                            <li>
                                <h2>
                                    {data.set.set.name} ({data.set.set.set_num})
                                </h2>
                            </li>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    <ImageWidget set={data.set.set_num} type={data.set.set_num.search('-1') !== -1 ? (data.set.condition === 'used' ? BrickLinkImageType.set : BrickLinkImageType.box) : BrickLinkImageType.minifig}/>
                                    {/*<ImageWidget set={data.set.set.set_num} type={BrickLinkImageType.set}/>*/}
                                </div>
                            </li>

                            <li>
                                <label htmlFor={"purchase_price"}>Purchase price</label>
                            </li>
                            <li>
                                <Field id={"purchase_price"} type={"number"} as={"input"} name={"purchase_price"}/>
                            </li>

                            <li>
                                <label htmlFor={"selling_price"}>Selling price</label>
                            </li>
                            <li>
                                <Field id={"selling_price"} type={"number"} as={"input"} name={"selling_price"} onBlur={() => calculateProfit()}/>
                            </li>

                            <li>
                                <label htmlFor={"selling_shipping_cost"}>Shipping cost</label>
                            </li>
                            <li>
                                <Field id={"selling_shipping_cost"} type={"number"} as={"input"} name={"selling_shipping_cost"} placeholder={"8.99"} onBlur={() => calculateProfit()}/>
                            </li>

                            <li>
                                <label htmlFor={"selling_commission_cost"}>Commission cost</label>
                            </li>
                            <li>
                                <Field id={"selling_commission_cost"} type={"number"} as={"input"} name={"selling_commission_cost"} placeholder={"6.00"} onBlur={() => calculateProfit()}/>
                            </li>

                            <li>
                                <label htmlFor={"sales_profit"}>Sales profit</label>
                            </li>
                            <li>
                                <Field id={"sales_profit"} type={"number"} as={"input"} name={"sales_profit"} disabled={true} placeholder={"85.01"}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Sold
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(SellSet)
