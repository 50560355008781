import React, {useContext, useEffect, useState} from 'react'
import {ConnectedRouter} from 'connected-react-router'
import routes from './routes'
import {RouteComponentProps} from 'react-router-dom';
import {actionGetCategories, actionGetColors, actionGetContainers, actionGetStorages} from "./store/actionCreators";
import {ReactReduxContext} from "react-redux";

import configData from "./config.json";
import {_get} from "./functions";
import LoginPage from "./pages/Auth/LoginPage";

interface IDictionary {
    [index: string]: string;
}

const App: React.SFC<RouteComponentProps> = ({history}) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {colors, categories, storages, containers} = state;

    const [isContainers, setContainers] = useState(false);
    const [isStorages, setStorages] = useState(false);
    const [isCategories, setCategories] = useState(false);
    const [isColors, setColors] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("token") || null);

    // const { store } = useContext(ReactReduxContext);

    useEffect(() => {

        console.log('useEffect');

        if (!containers.length && !isContainers) {
            _get('user/containers', {}, false)
                .then(data => {
                    // @ts-ignore
                    store.dispatch(actionGetContainers(data));
                    setContainers(true);
                })
        } else {
            setContainers(true);
        }

        if (!storages.length && !isStorages) {
            _get('user/storages', {}, false)
                .then(data => {
                    // @ts-ignore
                    store.dispatch(actionGetStorages(data));
                    setStorages(true);
                })
        } else {
            setStorages(true);
        }

        if (!colors.length && !isColors) {
            _get('colors', {}, false)
                .then(data => {
                    // @ts-ignore
                    store.dispatch(actionGetColors(data));
                    setColors(true);
                });
        } else {
            setColors(true);
        }

        if (!categories.length && !isCategories) {
            _get('categories', {}, false)
                .then(data => {
                    // @ts-ignore
                    store.dispatch(actionGetCategories(data));
                    setCategories(true);
                })
        } else {
            setCategories(true);
        }

    }, [token]);

    if(!token){
        return <LoginPage setToken={(token) => {
            console.log('set token');
            console.log(token);
            localStorage.setItem('token', token);
            setToken(token);
        }
        }/>
    }

    if (!isStorages || !isCategories || !isColors || !isContainers) {
        return <div>Loading data</div>
    }

    return (
        <ConnectedRouter history={history}>
            {routes}
            <div className={"version"}>{configData.VERSION}</div>
        </ConnectedRouter>
    )
}

export default App
