import React from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client';
import ImageWidget from "../../components/ImageWidget";
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import IconBrick from "../../assets/icon-brick.svg";
import IconMinifig from "../../assets/icon-minifig.svg";
import IconInstruction from "../../assets/icon-instruction.svg";
import IconBox from "../../assets/icon-box.svg";
import BricklinkButtonSet from "../../components/BricklinkButton";

const INSTRUCTION_LIST = gql`
    query setsWithoutBox {
        setsWithoutBox {
            id
            hash
            set {
                set_num
                name
                basic_price
                parts
                minifigs
            }
            container {
                id
                name
            }
            condition
            notes
            box
            instruction
            completeness
            purchase_price
            estimated_price

            parts
            minifigs
            minifigs_parts

            wanted_count
            locked

            bricklink_wantedlist_id
        }
    }`;

const SetsListEntriesList = ({  entries, container}) => {
    // console.log(container);
    return entries.setsWithoutBox.filter((node) => {
        // console.log(node);
        if (!container) {
            // console.log('no filter');
            return true;
        }
        if (container && !node.container) {
            // console.log('no container');
            return false;
        }
        // console.log(node.container.id);
        // console.log(parseInt(node.container.id) === parseInt(container));
        return (parseInt(node.container.id) === parseInt(container));
    }).map((node) => (
        <div className={"grid table"} key={node.id}>

            <div className={"grid-start grid-end-1 grid-align-left image-widget-small-image"}>
                <ImageWidget set={node.set.set_num} type={BrickLinkImageType.box} />
            </div>
            <div className={"grid-start-1 grid-end-9 grid-align-left"}>
                <b>{node.set_num} | {node.set.name} </b><br/>
                {node.container ? <small>({node.container.name})<br/></small> : null}
                <small>{node.notes}</small>

            </div>
            <div className={"grid-start-9 grid-end-10 missing"}>
                {node.set.parts === node.parts && node.set.minifigs === node.minifigs ? <></> : null}
                {node.parts ? <div><IconBrick /><br/>{node.parts}</div> : null}
                {node.minifigs || node.minifigs_parts ? <div><IconMinifig/><br/>{node.minifigs}<br/>({node.minifigs_parts})</div> : null}
                {!node.instruction ? <div><IconInstruction/></div> : null}
                {!node.box ? <div><IconBox/></div> : null}
            </div>
            <div className={"grid-start-10 grid-end"}>
                <BricklinkButtonSet set={node.set_num} type={"I"} name={"Instruction"}/>
                <Link to={'/set/update/' + node.id}>Edit</Link>
                {node.condition === 'used' ? <><Link to={'/set/inventory/' + node.id}>Inventory</Link></> : "-"}
            </div>
        </div>
    ));
}

const TableHeader = () => <div className={'table header grid'}>
    <div className={"grid-start grid-end-9 grid-align-left"}>Set</div>
    <div className={"grid-start-9 grid-end-10"}>Missing</div>
    <div className={"grid-start-10 grid-end options-cell"}>Options</div>
</div>

const SetsListWithData = ({entries}) => {
    return <>
        <TableHeader/>
        <SetsListEntriesList entries={entries}/>
    </>
}

const List = () => {

    const {data, loading, error} = useQuery(INSTRUCTION_LIST, {
        fetchPolicy: 'cache-and-network',
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={"sets list"}>
            <h1>Missing instructions</h1>

            <div style={{padding: "10px 20px"}}>
                <SetsListWithData entries={data || {}} />
            </div>
        </div>
    )
}

export default List
