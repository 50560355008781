import * as actionType from './actionTypes';
import {
    userSetsAction,
    getCategoriesAction,
    categoriesState,
    colorsState,
    getColorsAction,
    storagesState,
    getStoragesAction,

    containersState,
    getContainersAction
} from "../types";

export function updateUserSets(){
    const action: userSetsAction = {
        type: actionType.UPDATE_USER_SETS
    }
    return action;
}

export function actionGetCategories(categories: categoriesState){
    const action: getCategoriesAction = {
        type: actionType.GET_CATEGORIES,
        payload: categories
    }
    return action;
}

export function actionGetColors(colors: colorsState){
    const action: getColorsAction = {
        type: actionType.GET_COLORS,
        payload: colors
    }
    return action;
}

export function actionGetStorages(storages: storagesState){
    const action: getStoragesAction = {
        type: actionType.GET_STORAGES,
        payload: storages
    }
    return action;
}

export function actionGetContainers(containers: containersState){
    const action: getContainersAction = {
        type: actionType.GET_CONTAINERS,
        payload: containers
    }
    return action;
}
