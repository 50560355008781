import React from 'react';
import {useQuery, gql} from '@apollo/client';
import {RouteComponentProps, withRouter} from "react-router";
import {_userSetInventory} from "../../types";
import WantedPartPreview from "../../components/Items/WantedPartPreview";

const PARTS = gql`
    query UserSetInventoryWantedParts {
        userSetInventoryWantedParts {
            id
            part {
                category {
                    name
                }
            }
            part_num,
            minifig_num,
            user_set_id,
            expected,
            quantity,
            col_id,
            type
        }
    }`;

const USER_INVENTORY = gql`
    query userInventory {
        userInventory {
            id
            part_num
            col_id
            quantity
        }
    }
`

interface __missingPartsForSelectedSets {
    userSetInventoryWantedParts: _userSetInventory[];
}

interface _formattedData {
    part_num: string
    color: number
    quantity: number
    note: string
}

interface IDictionary {
    [index: string]: _formattedData;
}

// TODO: Merge parts from wanted
// TODO: Link do set

const InventoryWantedPartsList = (props: RouteComponentProps<{ sets?: string }>) => {

    const {data, loading, error} = useQuery<__missingPartsForSelectedSets>(PARTS, {
        fetchPolicy: 'cache-and-network'
    });

    const {data: userInventoryData, loading: userInventoryLoading, error: userInventoryError} = useQuery(USER_INVENTORY, {
        fetchPolicy: 'cache-and-network'
    })

    if (loading || userInventoryLoading) return <p>Loading...</p>;
    if (error || userInventoryError) return <p>Error :(</p>;

    if (data) {
        return (
            <div>
                <ul className={"tiles"}>
                {data.userSetInventoryWantedParts.map((item: _userSetInventory) => {

                    let found: number = 0;
                    console.log(item.part_num + ' color: ' + item.col_id);
                    userInventoryData.userInventory.map((node : _userSetInventory) => {
                        if(
                            node.part_num === item.part_num &&
                            parseInt(node.col_id) === parseInt(item.col_id)
                        ){
                            found = node.quantity;
                        }

                        return true;
                    });

                    return (
                        <WantedPartPreview node={item} inInventory={found} key={item.id}/>
                    )
                })}
                </ul>
            </div>
        )
    }

    return <>Missing data!</>
}

export default withRouter(InventoryWantedPartsList);
