import React from 'react'
import { BrickLinkImageType } from '../BrickLinkImageSrc'
import SetInventoryItemLine from './SetInventoryItemLine'

const SetInventoryList = ({ filter, entries, colors }) => entries
  .filter((a) => a.group === filter)
  .map((node) => (
    <li key={Math.random()}>
      <SetInventoryItemLine key={node.id.toString()} node={node} colors={colors} />
      {node.type === BrickLinkImageType.minifig ? (
        <ol className="sub-parts">
          {entries.filter((item) => (item.reference && item.reference.toString() === node.id.toString())).map((item) => (
            <SetInventoryItemLine key={item.id.toString()} node={item} colors={colors} />
          ))}
        </ol>
      ) : null}
    </li>
  ))

export default SetInventoryList
