import React, { useContext, useEffect, useState } from 'react'
import { ReactReduxContext } from 'react-redux'
import { apiPost } from '../../../functions'
import LoadingPage from '../../../components/LoadingPage'
import ImageWidget from "../../../components/ImageWidget";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";
import CustomButton from "../../../components/CustomButton";
import IconBrick from "../../../assets/icon-brick.svg";
import IconMinifig from "../../../assets/icon-minifig.svg";
import IconInstruction from "../../../assets/icon-instruction.svg";
import IconBox from "../../../assets/icon-box.svg";

const UserSetsContain = (props) => {
  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const { colors } = state

  const _item = props.match.params.item
  const _col_id = props.match.params.col_id

  const [fetch, setFetch] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
      console.log('aaaa');
    const variables = {
      item: _item,
      col_id: _col_id,
    }
    apiPost('user/sets/contain', variables, true, 'Loading...', 'Done')
      .then((data) => {
          console.log('return');
          console.log(data);
        setData(data)
        setFetch(false)
      })
  }, [])

  if (fetch) {
    return <LoadingPage />
  }

  console.log(data);

  return (
    <div className={"grid"}>
        <div className={"grid-start grid-end-2"}>
            <ImageWidget set={data.item.item_num} color={data.item.col_id} type={data.item.item_type} maxHeight={"120px"} />
            {data.quantity} / {data.expected}
        </div>
        <div className={"grid-start-2 grid-end grid-align-left"}>
            <h1>Contain in sets</h1>
            <div className={"grid"}>
                <>
                    <div className={"grid-start grid-end-8"}>
                        Set
                    </div>
                    <div className={"grid-start-8 grid-end-9"}>
                        Stage
                    </div>
                    <div className={"grid-start-9 grid-end-10"}>
                        Missing
                    </div>
                    <div className={'grid-start-10 grid-end'}>
                        Actions
                    </div>
                    <div className={"grid-start grid-end"}>
                        <hr/>
                    </div>
                </>
            {data.contain_in.map((node) => {
                return (
                    <>
                        <div className={"grid-start grid-end-2"}>
                            <ImageWidget set={node.set_num} color={0} type={node.type} maxHeight={"60px"} />
                        </div>
                        <div className={"grid-start-2 grid-end-8 grid-align-left"}>
                            {node.user_set_id}<br/>
                            {node.set_num}<br/>
                            {node.name}<br/>

                        </div>
                        <div className={"grid-start-8 grid-end-9"}>
                            {node.stage}<br/>
                        </div>
                        <div className={"grid-start-9 grid-end-10"}>
                            {node.parts ? <div><IconBrick /><br/>{node.parts}</div> : null}
                            {node.minifigs || node.minifigs_parts ? <div><IconMinifig/><br/>{node.minifigs}<br/>({node.minifigs_parts})</div> : null}
                        </div>
                        <div className={'grid-start-10 grid-end'}>
                            <CustomButton url={`/set/inventory/${node.user_set_id}`} name="Open set" /><br/><br/>
                            <b>x {node.quantity} / {node.expected}</b>
                        </div>
                        <div className={"grid-start grid-end"}>
                            <hr/>
                        </div>
                    </>
                )
            })}
            </div>
        </div>
    </div>
  )
}

export default UserSetsContain
