import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useQuery, useLazyQuery, gql, useMutation} from '@apollo/client';
// import {UsedSet as AllegroSearchSetButton} from "../../components/AllegroButton"
import {BrickLinkImageSrc, BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import {_userSetInventory} from "../../types";
import BricklinkButtonSet from "../../components/BricklinkButton";
import {AllegroButtonUsedSet} from "../../components/AllegroButton";
import IconBrick from "../../assets/icon-brick.svg";
import IconBox from "../../assets/icon-box.svg";
import IconInstruction from "../../assets/icon-instruction.svg";
import IconMinifig from "../../assets/icon-minifig.svg";
import {onError} from "@apollo/client/link/error";
import {showErrorNotification, showSendingNotification, showSuccessNotification} from "../../functions";
import LoadingPage from "../../components/LoadingPage";
import {Field} from "formik";
import {ReactReduxContext} from "react-redux";
// import {BricklinkSet} from "../../components/BricklinkButton";

const EXCHANGE_RATES = gql`
    query sets($type: String!, $condition: String!, $sold: Boolean!) {
        sets(type: $type, condition: $condition, sold: $sold) {
            id
            hash
            set {
                set_num
                name
                basic_price
                parts
                minifigs
                type
            }
            container {
                id
                name
            }
            condition
            notes
            box
            instruction
            completeness
            purchase_price
            estimated_price
            
            parts
            minifigs
            minifigs_parts

            wanted_count
            instruction_wanted
            box_wanted
            
            locked
            stage
            on_sale
            
            bricklink_wantedlist_id
        }
    }`;

const UNLOCK = gql`
    mutation unlockUserSet($id: ID!) {
        unlockUserSet(
            id: $id
        ){
            id
        }
    }`;


const LOCK = gql`
    mutation lockUserSet($id: ID!) {
        lockUserSet(
            id: $id
        ){
            id
        }
    }`;

const SetsListEntriesList = ({filterBySetNum, filterByContainer, filterByStage, filterByOnSale, filterByReadyToSell, entries, refreshData = () => console.log('refreshData')}) => {
    // console.log(container);

    const [lockSet] = useMutation(LOCK);
    const [unlockSet] = useMutation(UNLOCK);

    if(!entries.sets) return (<LoadingPage/>);

    // console.log('filterByOnSale');
    // console.log(filterByOnSale);
    //
    // console.log('filterByStage');
    // console.log(filterByStage);
    //
    // console.log('filterByContainer');
    // console.log(filterByContainer);
    //
    // console.log('filterBySetNum');
    // console.log(filterBySetNum);

    return entries.sets.filter((node) => {
        if (!filterByContainer) return true;
        if (filterByContainer && !node.container) return false;
        return (parseInt(node.container.id) === parseInt(filterByContainer));
    }).filter((node) => {
        if (!filterBySetNum) return true;
        return (node.set.set_num === filterBySetNum);
    }).filter((node) => {
        if(!filterByStage) return true;
        return (node.stage === filterByStage);
    }).filter((node) => {
        // if(!filterByOnSale) return true;
        // console.log(node.on_sale);
        return (node.on_sale === filterByOnSale); // TODO: wymuszone pokazywanie tylko bez frali on_sale, poprawić to przy przerobienou zapytania na rest
    }).filter((node) => {
        if(!filterByReadyToSell) return true;
        return (node.stage === 'finished' || node.stage === 'completed');
    }).map((node) => {
        let color = node.locked ? '#99a5b4' : '#000';
        if(node.stage === 'finished' || node.stage === 'completed') {
            color = '#0c8f0c';
        }
        if(node.stage === 'sketch') {
            color = '#616267';
        }
        return (
            <div className={"table"} key={node.id} style={{color}}>
                <div className={"id no-print"}>
                    <b className={"print-text-normal"}>{node.id}</b>
                </div>
                <div className={"image print-image-width"}>
                        <ImageWidget set={node.set.set_num} type={node.set.type} maxHeight={"71.5px"} />
                    </div>
                    <div className={"name grid-align-left print-text-normal print-name-width"}>
                        <b className={"no-print"}>#{node.hash}</b> {node.set.set_num} - {node.set.name} <span className={"no-print"}>({node.stage})</span><br/>
                        {node.container ? <small>({node.container.name})<br/></small> : null}
                        <small style={{fontSize: 12}} className={"no-print"}>
                            {node.notes}
                        </small><br/>
                        {node.stage === 'finished' || node.stage === 'completed' ? <div style={{float: 'left', backgroundColor: 'green', color: '#fff', padding: '3px 5px', borderRadius: '3px', margin: '10px 5px 0'}} className={"no-print"}>Ready to sell</div> : null}
                        {node.on_sale ? <div style={{float: 'left', backgroundColor: 'red', color: '#fff', padding: '3px 5px', borderRadius: '3px', margin: '10px 5px 0'}} className={"no-print"}>On sale</div> : null}
                    </div>
                    <div className={"price no-print"}>
                        {node.estimated_price ? <>~{node.estimated_price}PLN<br/></> : null}
                        <AllegroButtonUsedSet set={node.set.set_num} name={node.set.set_num} /><br/>
                        <BricklinkButtonSet set={node.set.set_num} name={node.set.set_num} type={node.set.type} /><br/>
                    </div>
                    <div className={"missing no-print"}>
                        {node.set.parts === node.parts && node.set.minifigs === node.minifigs ? <></> : null}
                        {node.parts ? <div><IconBrick /><br/>{node.parts}</div> : null}
                        {node.minifigs || node.minifigs_parts ? <div><IconMinifig/><br/>{node.minifigs}<br/>({node.minifigs_parts})</div> : null}
                        {!node.instruction ? <div><IconInstruction/></div> : null}
                        {!node.box ? <div><IconBox/></div> : null}
                    </div>
                <div className={"wanted no-print"}>
                    {node.wanted_count ? <div><IconBrick/><br/>{node.wanted_count}</div> : null}
                    {node.instruction_wanted ? <div><IconInstruction/><br/>{node.instruction_wanted}</div> : null}
                    {node.box_wanted ? <div><IconBox/><br/>{node.box_wanted}</div> : null}
                </div>

                    <div className={"options no-print"}>
                        {/*<Link to={'/set/update/' + node.id}>Edit</Link>*/}
                        {!node.locked ? <>
                            <Link to={'/set/inventory/' + node.id} target={"_blank"}>Inventory</Link>
                            <Link to={'/set/delete/' + node.id}>Delete</Link>
                            <br/><br/>
                            <a onClick={() => lockSet({
                                variables: {
                                    id: node.id
                                }
                            }).then(r => {
                                console.log(r)
                                refreshData();
                            })
                            }>Lock</a>

                            <Link to={'/set/sell/' + node.id}>Sell</Link>


                            <br/><br/>
                            <Link to={'/set/print/' + node.id} target={"_blank"}>Print</Link>
                        </> : <>
                            <a onClick={() => unlockSet({
                                variables: {
                                    id: node.id
                                }
                            }).then(r => {
                                console.log(r)
                                refreshData();
                            })
                            }>Unlock</a>
                        </> }
                        {/*{node.wanted_count ? <a onClick={() => prepareWantedXML(node.id)}>Wanted list ({node.wanted_count})</a> : null}*/}
                        {node.bricklink_wantedlist_id ? <><br/><br/>Bricklink wantedlist id: {node.bricklink_wantedlist_id}</> : null}
                    </div>
            </div>
        )
    });
}

const TableHeader = () => {
    return <div className={'table header no-print'}>
        <div className={"id"}>ID</div>
        <div className={"name-cell"}>Set</div>
        <div className={"price"}>Price</div>
        <div className={"missing"}>Missing</div>
        <div className={"wanted"}>Wanted</div>
        <div className={"options-cell"}>Options</div>
    </div>
}

const SetsListWithData = ({filterBySetNum, filterByContainer, filterByStage, filterByOnSale, filterByReadyToSell, entries, onLoadMore, hasNextPage, onLock}) => {
    return <>
        <TableHeader/>
        <SetsListEntriesList
            filterBySetNum={filterBySetNum}
            filterByContainer={filterByContainer}
            filterByStage={filterByStage}
            filterByOnSale={filterByOnSale}
            filterByReadyToSell={filterByReadyToSell}
            entries={entries}
            refreshData={onLock}/>
    </>
}

const Sets = (props) => {
    return (<Used props={props} type={'set'} title={"Sets"} on_sale={false} ready_to_sell={false}/>);
}

const SetsReadyToSell = (props) => {
    return <Used title={"Sets - Ready to sell"} type={'set'} ready_to_sell={true} on_sale={false}/>;
}

const SetsOnSale = (props) => {
    return <Used title={"Sets - on sale"} type={'set'} ready_to_sell={false} on_sale={true}/>;
}

const Minifigs = (props) => {
    return (<Used props={props} type={'minifig'} title={"Minifigs"} on_sale={false} ready_to_sell={false}/>);
}

const MinifigsReadyToSell = (props) => {
    return <Used title={"Minifigs - Ready to sell"} type={'minifig'} ready_to_sell={true} on_sale={false}/>;
}

const MinifigsOnSale = (props) => {
    return <Used title={"Minifigs - on sale"} type={'minifig'} ready_to_sell={false} on_sale={true}/>;
}

const Used = ({title = 'Lorem ipsum', type = 'set', on_sale = false, ready_to_sell = false, props}) => {

    const { store } = useContext(ReactReduxContext)
    const state = store.getState()
    const { containers } = state

    const [filterByContainer, setFilterByContainer] = useState(false);
    const [filterByStage, setFilterByStage] = useState(false);
    const [filterByCondition, setFilterByCondition] = useState('used'); // static
    const [filterByType, setFilterByType] = useState(type); // static
    const [filterByOnSale, setFilterByOnSale] = useState(on_sale); // static
    const [filterByReadyToSell, setFilterByReadyToSell] = useState(ready_to_sell); // static
    const [filterByCategory, setFilterByCategory] = useState(false);
    const [filterBySetNum, setFilterBySetNum] = useState(false);

    // let _type = new URLSearchParams(props.location.search).get("type") || 'set';

    const {data: cachedData} = useQuery(EXCHANGE_RATES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            type: type,
            condition: 'used',
            sold: false
        }
    });

    const [getUserSets, {data, loading, error}] = useLazyQuery(EXCHANGE_RATES, {
        fetchPolicy: 'network-only',
        variables: {
            type: type,
            condition: 'used',
            sold: false
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (e) => {
            showSuccessNotification();
        },
        onError: (e) => {
            showErrorNotification(e.message);
        }
    })
    const cachedUserSetsData = data || cachedData

    // const {data: containerData, loading: containerLoading, error: containerError} = useQuery(CONTAINERS_LIST, {
    //     fetchPolicy: 'cache-and-network',
    // });

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if(query.has('container')){
            setFilterByContainer(query.get('container'));
        }
        if(query.has('stage')){
            setFilterByStage(query.get('stage'))
        }
        // if(query.has('on_sale')){
        setFilterByOnSale(on_sale);
        setFilterByReadyToSell(ready_to_sell);
        // }
        // setFilterByOnSale(false);

        if(query.has('set_num')){
            setFilterBySetNum(query.get('set_num'))
        }
    }, []);

    if(!cachedUserSetsData){
        return <LoadingPage/>
    }

    const _filterByStage = cachedUserSetsData.sets
        .filter((node) => {
            return (node.on_sale === on_sale);
        }).filter((node) => {
            if (!ready_to_sell) return true;
            return (node.stage === 'finished' || node.stage === 'completed');
        });

    return (
        <div>
            <nav className={"second-line"}>
                <div className={"grid"}>
                    <div className={"grid-start grid-end-7 grid-align-left"}>
                        <div className={"nav-title"}>
                            {title}
                        </div>
                    </div>

                    <div className={"grid-start-7 grid-end-8"}>
                        <label>Stage:</label>
                    </div>
                    <div className={"grid-start-8 grid-end-9"}>
                        <select name={"stage"} onChange={(event) => {
                            setFilterByStage(event.target.value);
                        }}>
                            <option value={""}>All ({_filterByStage.length})</option>
                            <option value="sketch" selected={'sketch' === filterByStage}>Sketch ({_filterByStage.filter((node) => node.stage === 'sketch').length})</option>
                            <option value="reconstruction" selected={'reconstruction' === filterByStage}>Reconstruction ({_filterByStage.filter((node) => node.stage === 'reconstruction').length})</option>
                            <option value="finished" selected={'finished' === filterByStage}>Finished ({_filterByStage.filter((node) => node.stage === 'finished').length})</option>
                            <option value="completed" selected={'completed' === filterByStage}>100% Completed ({_filterByStage.filter((node) => node.stage === 'completed').length})</option>
                        </select>
                    </div>

                    <div className={"grid-start-9 grid-end-10"}>
                        <label>Container:</label>
                    </div>
                    <div className={"grid-start-10 grid-end-11"}>
                        <select name={"containers"} onChange={(event) => {
                            setFilterByContainer(event.target.value);
                        }}>
                            <option value={""}>All</option>
                            {containers ? containers.map((node) => (
                                node ? <option value={node.id} key={node.id} selected={node.id === filterByContainer}>{node.name}</option> : null
                            )) : null}
                        </select>
                    </div>
                    <div className={"grid-start-12 grid-end"}>
                        <Link className={"button"} to="/set/create">Add new set</Link>
                    </div>
                </div>
            </nav>
            <SetsListWithData
                filterBySetNum={filterBySetNum}
                filterByContainer={filterByContainer}
                filterByStage={filterByStage}
                filterByOnSale={filterByOnSale}
                filterByReadyToSell={filterByReadyToSell}
                entries={cachedUserSetsData || {}}
                onLock={() => {
                    showSendingNotification();
                    getUserSets();
                }}
            />
        </div>
    )
}

export {
    Used,
    Sets,
    SetsReadyToSell,
    SetsOnSale,
    Minifigs,
    MinifigsReadyToSell,
    MinifigsOnSale
}
