import React, {useContext, useEffect} from 'react';
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import {_get} from "../../functions";
import {actionGetContainers} from "../../store/actionCreators";
import {ReactReduxContext} from "react-redux";

const EXCHANGE_RATES = gql`
    query containers {
        containers(orderBy: {
            field: "id",
            order: DESC
        }){
            id
            name
            purchase_price
            notes
        }
    }`;


const List = ({entries}) => {
    console.log(entries);
    return entries.map((node) => (
        <div className={"grid table"} key={node.id}>
            <div className={"grid-start grid-end-9 grid-align-left"}>
                {node.name}<br/>
                <small>{node.notes}</small>
            </div>
            <div className={"grid-start-9 grid-end-10"}>{node.purchase_price ? node.purchase_price + ' zł' : '-'}</div>
            <div className={"grid-start-10 grid-end"}>
                <Link to={'/container/update/' + node.id}>Edit</Link>
                <Link to={'/container/delete/' + node.id}>Delete</Link>
                <Link to={'/sets/used?container=' + node.id}>Sets</Link>
                <Link to={'/sets/sold?container=' + node.id}>Sold</Link>
            </div>
        </div>
    ));
}

const ContainersList = () => {

    const {store} = useContext(ReactReduxContext);
    const { data, loading, error} = useQuery(EXCHANGE_RATES, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    useEffect(() => {

        // update containers
        _get('user/containers', {}, false)
            .then(data => {
                // @ts-ignore
                store.dispatch(actionGetContainers(data));
            })

    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
      <div className={"list containers"}>
          <nav className={"second-line"}>
            <div className={"grid"}>
              <div className={"grid-start grid-end-2 grid-align-left"}>
                <div className={"nav-title"}>
                  Container
                </div>
              </div>
              <div className={"grid-start-10 grid-end"}>
                <Link className={"button"} to="/container/create">Add new container</Link>
              </div>
            </div>
          </nav>

          <div className="grid table header">
              <div className="grid-start grid-end-9 grid-align-left">Name</div>
              <div className="grid-start-9 grid-end-10">Price</div>
              <div className="grid-start-10 grid-end">Options</div>
          </div>
          <List entries={data.containers || {}} />
      </div>
    )
}

export default ContainersList
