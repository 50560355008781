import React from 'react'
import { BrickLinkImageSrc } from './BrickLinkImageSrc'

export const ImageWidget = (props) => {
  const {
    children,
    set,
    color,
    type,
    title = '',
    zoom = true,
    alwaysBig = false,
    content = null,
    colorBorder = true,
    colorName = false,
    maxWidth = '100%',
    maxHeight = '100%',
  } = props

  const url = BrickLinkImageSrc(set, color, type, alwaysBig)
  const zoomUrl = BrickLinkImageSrc(set, color, type, true)

  const submenu = children || content
  return (
    <div className="imageWidget">
      <div className={colorBorder ? `color-border-${color}` : null}>
        <div
          className="preview"
          title={title}
          style={{ textAlign: 'center' }}
        >
          <img src={url} alt={set} loading="lazy" title={title} style={{ maxWidth, maxHeight }} />
          {colorName ? <div className="color-name">{colorName}</div> : null}
          {zoom ? <div className="magnifier">
            <div className="submenu">
              {submenu}
              <br />
              <img
                className="original"
                src={zoomUrl}
                alt={title}
                loading="lazy"
                title={title}
              />
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  )
}

export default ImageWidget
