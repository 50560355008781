import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {apiPost} from "../../functions";

interface FormInputs {
    email: string;
    password: string;
}

interface Param {
    setToken: (arg0: string) => void
}


const LoginPage = ({setToken} : Param) => {

    const [isSubmiting, setSubmit] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>();

    const onSubmit = (data: FormInputs) => {
        setSubmit(true);
        console.log(data);

        apiPost('login', data).then((data) => {
            console.log(data);
            setSubmit(false);
            // @ts-ignore
            setToken(data.access_token);
        })
    };

    return (
        <div className={"containers form login-form"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul>
                    <li>
                        <input placeholder={'email'} {...register("email", {required: true})} autoFocus={true} />
                        {errors.email && <span className={'form-error'}>This field is required</span>}
                    </li>
                    <li>
                        <input type={"password"} placeholder={'password'} {...register("password", {required: true})}  />
                        {errors.password && <span className={'form-error'}>This field is required</span>}
                    </li>
                    <li>
                        <button type="submit" disabled={isSubmiting}>
                            Login
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    )
}

export default LoginPage
