import React from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import {ImageWidget} from "../../components/ImageWidget";
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";

const GET = gql`
    query set($id: ID) {
        set(id: $id){
            id
            hash
            set_num
            set {
                set_num
                name
            }
            condition
        }
    }`;

const DELETE = gql`
    mutation deleteSet($id: ID!) {
        deleteSet(
            id: $id
        ){
            id
        }
    }`;


const Delete = (props) => {

    console.log("props: ");
    console.log(props);
    let id = props.match.params.id;
    const { loading, error, data } = useQuery(GET,
        {
            variables: { id }
    });

    const [deleteSet, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(DELETE);

    if (loading) return <p>download...</p>;
    if (error) return <p>Error :(</p>;

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    console.log(data);
    console.log(mutationData);

    if(mutationData){
        const query = new URLSearchParams(window.location.search);
        if(query.has('return')){
            props.push(query.get('return'));
        } else {
            if(data.condition === 'user') {
                props.push('/sets/used');
            } else {
                props.push('/sets/new');
            }
        }
    }

    console.log(data);

    return (
        <div className={"sets form"}>

            <Formik
                initialValues={data.set}
                validate={values => {
                    const errors = {};
                    if (!values.set_num) {
                        errors.set_num = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    deleteSet({
                        variables: values
                    }).then(r => console.log(r));
                    // addContainer({
                    //     variables: values
                    // });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Field type="hidden" name="id" />
                        <ul>
                            <li>
                                &nbsp;
                            </li>
                            <li>
                                <h1 className={"big"}><span style={{color: 'black'}}>{id}</span><br/>
                                    <span style={{color: 'grey'}}>{data.set.set_num}</span><br/>
                                    <span style={{color: 'darkgrey'}}>{data.set.set.name}</span></h1>
                                <div className={"notes"}>
                                    <b>#{data.set.hash}</b><br/>{data.notes}
                                </div>
                            </li>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    <ImageWidget zoom={false} set={data.set.set_num} type={data.set.set_num.search('-1') !== -1 ? (data.set.condition === 'used' ? BrickLinkImageType.set : BrickLinkImageType.box) : BrickLinkImageType.minifig}/>
                                </div>
                            </li>
                            <li>
                                <label htmlFor="name">Set Number</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="set_num"
                                    disabled={true}
                                />
                                {errors.set_num && touched.set_num && errors.set_num}
                            </li>
                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Delete)
