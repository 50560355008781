import {_userSetInventory} from "../../types";
import {BrickLinkImageType} from "../BrickLinkImageSrc";
import {ImageWidget} from "../ImageWidget";
import {Field} from "formik";
import {CategoryTag} from "../CategoryTag";
import React from "react";
import CustomButton from "../CustomButton";

interface _partPreviewProps {
    node: _userSetInventory,
    key: string | null
}

const PartPreview = ({node}: _partPreviewProps) => {

    let imageSet = node.type !== 'minifig' ? node.color.col_id + '/' + node.part.part_num : node.minifig.set_num;
    let imageType = node.type !== 'minifig' ? BrickLinkImageType.part : BrickLinkImageType.minifig;

    let part = node.part ? node.part.part_num : node.minifig.set_num;
    let url = 'https://www.bricklink.com/v2/catalog/catalogitem.page?' + (node.part ? 'P=' : 'M=') + part;

    return (
        <ul>
            <li className={"checkbox"}>
                <Field type="checkbox" name="selected" value={node.id.toString()} />
            </li>
            <li className={"preview"}>
                <ImageWidget
                    set={imageSet}
                    type={imageType}
                    maxWidth={'100%'}
                    maxHeight={'100px'}
                >
                    <CustomButton url={url} name='Bricklink'/>
                </ImageWidget>
            </li>
            <li className={"description"}>
                {node.part ? <><b>{node.color.name}</b> / {node.part.name}<br/>
                    <CategoryTag name={node.part.category.name}/></> : null}
                {node.minifig ? <>{node.minifig.name}</> : null}
            </li>
        </ul>
    )
}

export default PartPreview;
