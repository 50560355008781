import {_userSetInventory} from "../../types";
import {BrickLinkImageType} from "../BrickLinkImageSrc";
import {ImageWidget} from "../ImageWidget";
import {CategoryTag} from "../CategoryTag";
import React from "react";
import CustomButton from "../CustomButton";
import ItemProps from "../ItemProps";

interface _partPreviewProps {
    node: _userSetInventory,
    inInventory: number;
    key: string | null
}

const WantedPartPreview = ({node, inInventory}: _partPreviewProps) => {

    let item = ItemProps(node);
    let color : string = 'white';
    if(inInventory > 0){
        color = 'yellow';
    }

    if(inInventory >= (item.expected - item.quantity)){
        color = 'green';
    }

    return (
        <li key={node.id} className={"part"} style={{border: "10px solid " + color}}>
            <ImageWidget title={item.num} color={item.color} set={item.num} type={item.type}/>
            {node.expected - node.quantity} x {node.part_num} {node.minifig_num}<br/>
            {inInventory ? <> (inventory: {inInventory})</> : null}
            <CustomButton url={"/set/inventory/" + node.user_set_id} name={"Open set"}/>
            {item.type === BrickLinkImageType.part ?
                <CategoryTag name={node.part?.category.name}/> :
                <CategoryTag name='Minifigs' />
            }
        </li>
    )
}

export default WantedPartPreview;
