import React from 'react'

const IconBox = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={{ width: 16, display: 'inline-block', padding: 6 }}>
      <path d="M253.77 95.52 10.8 190.54l-.65 308.27c-.43 245.36 0 308.49 2.17 309.36C198.45 881.28 498.48 998.43 500 998.43c1.08 0 110.64-42.52 243.41-94.58s242.75-95.24 244.27-95.67c2.17-.87 2.6-63.56 2.17-308.05l-.65-306.97-240.8-95.45C616.07 45.43 505.21 2.04 502.17 1.61c-3.69-.89-82.65 29.05-248.4 93.91zm464.04 27.99c119.53 47.29 218.02 86.78 218.89 87.43 1.52 1.3-78.75 34.06-137.76 56.62l-12.58 4.77-225.4-87.64C436.87 136.53 335.78 96.83 335.99 96.4c1.08-.87 160.32-58.57 162.7-58.79 1.31-.23 99.8 38.61 219.12 85.9zm-259.9 101.96c119.75 48.59 217.81 88.73 217.81 89.38 0 1.74-171.82 69.64-175.72 69.64-3.25 0-438.44-171.16-441.04-173.55-1.95-1.52 170.51-73.11 177.24-73.54 2.17-.01 102.18 39.48 221.71 88.07zM260.28 328.73c113.46 44.69 209.78 82.87 214.34 84.61l8.03 3.47v269c0 148.17-.65 269.22-1.3 269.22s-98.06-38.18-216.29-84.82C146.61 823.57 48.55 784.73 47.25 784.3c-2.17-.87-2.82-57.05-2.82-270.96V243.47l4.99 1.95c2.6 1.09 97.62 38.41 210.86 83.31zM954.7 783.01c-.43.43-36.23 14.75-79.62 31.89l-78.97 31.24-.65-269.22c-.22-147.95 0-269.44.43-269.88.43-.43 36.23-14.97 79.62-31.89l78.97-31.02.65 269.01c.23 147.95.01 269.43-.43 269.87zM714.77 607.72v268.79l-9.11 3.69c-5.21 1.95-49.03 19.52-97.62 39.27-48.59 19.52-88.95 35.58-89.6 35.58s-.87-121.27-.65-269.44l.65-269.66 96.54-38.18c53.15-21.26 97.19-38.62 98.27-38.62.87-.22 1.52 120.83 1.52 268.57z"/>
      <path d="M188.69 431.78c-44.47 15.4-66.82 52.93-66.82 112.16 0 94.15 68.55 195.68 150.34 223.23 52.72 17.57 97.19 1.74 119.53-42.52 37.53-73.98-3.04-198.07-85.26-261.2-38.39-29.72-86.33-42.52-117.79-31.67zm80.05 104.35c21.26 10.41 43.17 39.05 50.33 66.17 10.85 40.35-7.59 73.11-39.7 69.86-15.4-1.3-31.02-10.2-45.34-25.81-29.5-31.89-37.96-77.23-19.09-102.61 10.85-14.77 32.54-17.81 53.8-7.61z"/>
    </svg>
  )
}

export default IconBox
