import React from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client';
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import {AllegroButtonMinifig} from "../../components/AllegroButton";
import BricklinkButtonSet from "../../components/BricklinkButton";

const SEARCH_SETS_BY_MINIFIGS = gql`
    query searchSetsByMinifigsFromUserSets {
        searchSetsByMinifigsFromUserSets {
            set_num
            parts
            match
        }
    }`;

const SetsListEntriesList = ({entries, container}) => {
  return entries.searchSetsByMinifigsFromUserSets.map((node) => {

    let color = 'red';
    let desc = `${node.match} / ${node.parts}`;
    //
    if (node.parts === node.match) {
      color = 'green';
      desc = '100%';
    }

    const content = <div>
      <div className={"set"}>{node.set_num}</div>
      <div className={"desc"}><span className={color}>{desc}</span></div>
      <br/>
      <div className={"buttons"}>
        <Link to={'/set/preview/' + node.set_num} target="_blank" rel="noopener noreferrer">Preview</Link>
        <AllegroButtonMinifig set={node.set_num}/>
        <BricklinkButtonSet set={node.set_num} type={"S"} />
      </div>
    </div>;

    return (
      <li key={node.id} className={"part"}>
        <ImageWidget title={node.set_num} content={content} set={node.set_num} type={BrickLinkImageType.set}
                     maxWidth={'100%'} maxHeight={100}/>
        <div className={"set"}>{node.set_num}</div>
        <div className={"desc"}><span className={color}>{desc}</span></div>
      </li>
    )
  });
};

const SetsListWithData = ({entries}) => {
  return <ul className={"tiles"}>
    <SetsListEntriesList entries={entries}/>
  </ul>
}

const DiscoverySetsByMinifigs = () => {

  const {data, loading, error} = useQuery(SEARCH_SETS_BY_MINIFIGS, {
    fetchPolicy: 'cache-and-network'
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className={"inventory minifigs list"}>
      <nav className={"second-line"}>
        <ul className={"inventory-minifig"}>
          <li>
            <Link className={"button"} to="/inventory/minifig/create">Add new minifig</Link>
          </li>
        </ul>
      </nav>
      <SetsListWithData entries={data || {}}/>
    </div>
  )
}

export default DiscoverySetsByMinifigs
