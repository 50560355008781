import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {BrickLinkImageType} from '../../components/BrickLinkImageSrc';
import {ImageWidget} from '../../components/ImageWidget';
import {AllegroButtonNewSet} from '../../components/AllegroButton';
import BricklinkButtonSet from '../../components/BricklinkButton';
import PromoklockiButton from '../../components/PromoklockiButton';
import {ReactReduxContext} from 'react-redux';
import {apiGet, endpoint, showErrorNotification} from '../../functions';

const SetsListEntriesList = ({entries, storage, storageId}) => {

    return entries.filter((node) => {
        if (!storageId) {
            // console.log('no filter');
            return true;
        }
        if (storageId && !node.storage_id) {
            // console.log('no storage');
            return false;
        }
        // console.log(node.container.id);
        return (parseInt(node.storage_id) === parseInt(storageId));
    })
        .sort(function (a, b) {
            if (a.set_num && b.set_num) {
                return (parseInt(a.set_num.replace("-1", "")) > parseInt(b.set_num.replace("-1", "")) ? 1 : -1)
            }

            return false;
        })
        .map((node) => {
            let profit = node.purchase_price ? (parseFloat(node.purchase_price) * 1.4).toFixed(2) : parseFloat(0).toFixed();
            let percent = (parseFloat(node.estimated_price) / parseFloat(node.purchase_price)) * 100;

            let color = 'white';
            if (parseFloat(node.estimated_price) > parseFloat(node.purchase_price)) color = '#ffe397';
            if (parseFloat(node.estimated_price) > parseFloat(profit)) color = '#abf7a8';

            return (<div className={"grid table"} key={node.id} style={{backgroundColor: color}}>
                <div className={"grid-start grid-end-1 grid-align-left image-widget-small-image"}>
                    <ImageWidget set={node.set_num} type={BrickLinkImageType.box} maxHeight={"40px"}/>
                </div>
                <div className={"grid-start-1 grid-end-5 grid-align-left"}>
                    <b>{node.set_num} | {node.set.name} </b><br/>
                    {node.storage_id ? <small>({storage[node.storage_id].name})<br/></small> : null}
                    <small>{node.notes}</small>
                </div>
                <div className={"grid-start-5 grid-end-6"}>
                    {node.purchase_price ? <>{node.purchase_price} zł<br/><br/></> : <>-</>}
                </div>
                <div className={"grid-start-6 grid-end-7"}>
                    {node.estimated_price ? <>
                        {node.estimated_price} zł<br/>
                        {parseFloat(percent).toFixed(2)}%<br/>
                    </> : <>-</>}

                </div>
                <div className={"grid-start-7 grid-end-8"}>
                    {parseInt(profit) ? <>{profit} zł</> : <>-</>}
                </div>
                <div className={"grid-start-8 grid-end-10"}>
                    <AllegroButtonNewSet set={node.set.set_num}/>
                    <BricklinkButtonSet set={node.set.set_num} type={"S"}/>
                    <PromoklockiButton set={node.set.set_num}/>
                </div>
                <div className={"grid-start-10 grid-end"}>
                    <Link to={'/set/sell/' + node.id}>Sell</Link>
                    <Link to={'/set/update/' + node.id}>Edit</Link>
                    <Link to={'/set/delete/' + node.id}>Delete</Link>
                </div>
            </div>)
        });
}

const SetsListWithData = ({entries, storage, storageId}) => {
    return <>
        <TableHeader/>
        <SetsListEntriesList storage={storage} entries={entries} storageId={storageId}/>
    </>
}

const TableHeader = () => {
    return <div className={'table header grid'}>
        <div className={"grid-start grid-end-5 grid-align-left"}>Set</div>
        <div className={"grid-start-5 grid-end-6"}>Purchase</div>
        <div className={"grid-start-6 grid-end-7"}>Estimate</div>
        <div className={"grid-start-7 grid-end-8"}>+40%</div>
        <div className={"grid-start-8 grid-end-10"}>Links</div>
        <div className={"grid-start-10 grid-end options-cell"}>Options</div>
    </div>
}

const NewSetList = () => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {storages} = state;

    const [storageId, setStorageId] = useState(false);
    const [sets, setSets] = useState(false);

    useEffect(() => {

        // const condition = 'new';
        // const type = 'set';
        // const sold = 0;

        endpoint('user/sets', 'GET',
            {
                condition: 'new',
                type: 'set',
                sold: '0',
            }, false)
        .then((data) => {
            setSets(data);
        })

        // endpoint(`user/sets?condition=${condition}&type=${type}&sold=${sold}`,
        //     'GET',
        //     {},
        //     'Loading',
        //     'Done')
        // .then((data) => {
        //     console.log('AAA');
        //     setSets(data);
        // });

        const query = new URLSearchParams(window.location.search);
        if (query.has('storage')) {
            setStorageId(query.get('storage'));
        }
    }, []);

    return (<div>
        <nav className={"second-line"}>
            <div className={"grid"}>
                <div className={"grid-start grid-end-7 grid-align-left"}>
                    <div className={"nav-title"}>
                        New sets
                    </div>
                </div>
            <div className={"grid-start-7 grid-end-9"}>
                <label className={"grid-align-right"}>Filter by storage:</label>
            </div>
            <div className={"grid-start-9 grid-end-11"}>
                <select name={"storage"} onChange={(event) => {
                    setStorageId(event.target.value);
                }}>
                    <option value={""}>All</option>
                    {storages ? storages.filter((node) => node != null).map((node) => (
                        <option value={node.id} key={node.id}
                                selected={node.id === storageId}>{node.name}</option>)) : null}
                </select>
            </div>
            <div className={"grid-start-11 grid-end-12"}>
                <Link className={"button"} to="/set/create">Add new set</Link>
            </div>
            </div>
        </nav>
        {sets ? <SetsListWithData storage={storages} entries={sets} storageId={storageId}/> : <span>Loading...</span>}
    </div>)
}

export default NewSetList
