import React from 'react'

interface _customButtonInterface {
  url: string|undefined,
  name: string|boolean,
  title?: string|undefined
  className?: string|undefined
  target?: string|undefined
}

const CustomButton = ({
  url, name, title = '', className = '', target = '_blank',
} : _customButtonInterface ) => (
  <a className={className} href={url} title={title} target={target} rel="noopener noreferrer">{name}</a>
)

export default CustomButton
