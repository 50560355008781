import React, { useState, useEffect, useContext } from 'react'

import { ReactReduxContext } from 'react-redux'
import _ from 'lodash'

import { BrickLinkImageType } from '../../components/BrickLinkImageSrc'
import { ImageWidget } from '../../components/ImageWidget'
import { AllegroButtonUsedSet } from '../../components/AllegroButton'
import BricklinkButtonSet from '../../components/BricklinkButton'
import { _get } from '../../functions'

import SetInventoryList from "../../components/Set/SetInventoryList";

const SetPreview = (props) => {

  const { store } = useContext(ReactReduxContext)
  const state = store.getState()
  const { colors } = state

  const [filterByColor, setFilterByColor] = useState(false)
  const [sortByCategory, setSortByCategory] = useState(false)

  const [inventoryData, setInventoryData] = useState(false)
  const [instructionMatch, setInstructionMatch] = useState(0)

  const [setDetails, setSetDetails] = useState([])
  const [loading, setLoading] = useState(true)

  let sortedInventoryData = false

  const colorsRaw = []
  const { id } = props.match.params

  useEffect(() => {
    _get(`set/inventory/${id}`, {}, true, 'Loading...', 'Done')
      .then((data) => {
        setInventoryData(data.inventory)
        setInstructionMatch(data.storage.instruction)
        setSetDetails(data.set)
        setLoading(false)
      })
  }, [])

  if (loading || !inventoryData) return <p>Loading...</p>

  const groups = []

  // count color and group elements
  inventoryData.map((node) => {
    if (!groups[node.group]) {
      groups[node.group] = 0
    }
    groups[node.group] += 1

    if (!colorsRaw[node.col_id]) {
      colorsRaw[node.col_id] = { col_id: node.col_id, name: colors[node.col_id].name, quantity: 0 }
    }
    colorsRaw[node.col_id].quantity += node.expected

    return true
  })

  if (sortedInventoryData === false && inventoryData) {
    sortedInventoryData = inventoryData.slice()
  }

  if (!sortedInventoryData) return <p>Loading inventory...</p>

  if (sortByCategory) {
    const iterates_category = (obj) => obj.cat_id
    const iterates_name = (obj) => obj.name
    // eslint-disable-next-line camelcase
    sortedInventoryData = _.sortBy(sortedInventoryData, [iterates_category, iterates_name])
  }

  if (filterByColor) {
    sortedInventoryData = _.filter(sortedInventoryData, (obj) => parseInt(obj.col_id, 10) === parseInt(filterByColor, 10))
  }

  return (
    <div>
      <nav className="second-line">
        <div className="grid">
          <div className="grid-start grid-end-8">
            <div className="nav-title">
              Inventory
            </div>
          </div>
          <div className="grid-start-9 grid-end-10">
            <label>Filter by color:</label>
          </div>
          <div className="grid-start-10 grid-end-11">
            <select
              name="color"
              onChange={(event) => {
                setFilterByColor(event.target.value)
              }}
            >
              <option value="">All</option>
              {colorsRaw ? colorsRaw.map((node) => (
                <option value={node.col_id} key={node.col_id} defaultValue={filterByColor}>
                  {node.name} ({node.quantity})
                </option>
              )) : null}
            </select>
          </div>
          <div className="grid-start-11 grid-end">
            <label style={{ float: 'none', width: 'auto' }}>
              <input
                type="checkbox"
                name="sortByCategory"
                checked={sortByCategory}
                onChange={() => {
                  setSortByCategory(!sortByCategory)
                  sortedInventoryData = false
                }}
              /> Sort by category
            </label>
          </div>
        </div>
      </nav>
      <form>
        <ul>
          <li>
            &nbsp;
          </li>
          <li>
            <h1 className="big">
              <span style={{ color: 'grey' }}>{setDetails.set_num}</span>
              <br />
              <span style={{ color: 'darkgrey' }}>{setDetails.name}</span>
            </h1>
          </li>
          <li>
            <label>Preview</label>
          </li>
          <li>
            <div style={{ float: 'left' }}>
              <ImageWidget
                zoom={false}
                set={setDetails.set_num}
                type={setDetails.type}
                alwaysBig
                maxWidth="360px"
              />
            </div>
          </li>

          <li>
            <label htmlFor="instruction">Instruction</label>
          </li>
          <li>
            {instructionMatch ? (
              <div className="match">
                {instructionMatch} in inventory
              </div>
            ) : <>-</>}
          </li>

          <li>
            <label htmlFor="actions">Actions</label>
          </li>
          <li>
            {setDetails.type === BrickLinkImageType.set ? (
              <>
                <a className="button medium" target="_blank" rel="noopener noreferrer" href={`/set/create/?set_num=${setDetails.set_num}&condition=new`} >
                  Add new
                </a>
                <a className="button medium" target="_blank" rel="noopener noreferrer" href={`/set/create/?set_num=${setDetails.set_num}&condition=used`} >
                  Add used
                </a>
              </>
            ) : (
              <>
                <a
                  className="button medium"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/inventory/minifig/create/?set_num=${setDetails.set_num}&condition=new`}
                >
                  Add new
                </a>
                <a
                  className="button medium"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/inventory/minifig/create/?set_num=${setDetails.set_num}&condition=used`}
                >
                  Add used
                </a>
              </>
            )}
          </li>

          <li>
            &nbsp;
          </li>
          <li>
            <AllegroButtonUsedSet set={setDetails.set_num} />
              &nbsp;
            <BricklinkButtonSet
              set={setDetails.set_num}
              type={setDetails.type}
            />
          </li>

          <li>
            <hr />
          </li>
        </ul>
      </form>

      <form onSubmit={(e) => {
        console.log(e);
        e.preventDefault();

        const formData = new FormData(e.target);
        const formProps = Object.fromEntries(formData);

        console.log(formProps);
      }}>

        {groups.decorated ? (
          <>
            <h2>Decorated</h2>
            <ol>
              <SetInventoryList
                key="decorated"
                colors={colors}
                filter="decorated"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.counterpart ? (
          <>
            <h2>Counterparts</h2>
            <ol>
              <SetInventoryList
                key="Counterparts"
                colors={colors}
                filter="counterpart"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.minifig ? (
          <>
            <h2>Minifig</h2>
            <ol>
              <SetInventoryList
                key="minifig"
                colors={colors}
                filter="minifig"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.regular ? (
          <>
            <h2>Regular items</h2>
            <ol>
              <SetInventoryList
                key="regular"
                colors={colors}
                filter="regular"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.gear ? (
          <>
            <h2>Gear</h2>
            <ol>
              <SetInventoryList
                key="gear"
                colors={colors}
                filter="gear"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.extra ? (
          <>
            <h2 style={{ color: 'red' }}>Extra</h2>
            <ol style={{ border: '2px solid red' }}>
              <SetInventoryList
                key="extra"
                colors={colors}
                filter="extra"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        {groups.alternative ? (
          <>
            <h2 style={{ color: 'red' }}>Alternative</h2>
            <ol style={{ border: '2px solid red' }}>
              <SetInventoryList
                key="alternative"
                colors={colors}
                filter="alternative"
                entries={sortedInventoryData}
              />
            </ol>
          </>
        ) : null}

        <button>
          submit
        </button>
      </form>
    </div>
  )
}

export default SetPreview