import React from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client';
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import _ from "lodash"
import BricklinkButton from "../../components/BricklinkButton";
import SearchButton from "../../components/SearchButton";

const ALBUM_CATEGORIES_ELEMENTS = gql`
    query getAlbumCategoryParts($cat_id: Int){
        getAlbumCategoryParts(cat_id: $cat_id){
            cat_id
            col_id
            part_num
            name
        }
    }`;

const AlbumCategoriesElementList = ({category}) => {

  const {data, error} = useQuery(ALBUM_CATEGORIES_ELEMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      cat_id: category
    }
  });

  if (!data) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const elements = _.orderBy(data.getAlbumCategoryParts, ['name'], ['asc']);

  return elements.map((node) => {
    return (
      <li key={node.cat_id} className={"album-element"}>
          <ImageWidget set={node.part_num} color={node.col_id} type={BrickLinkImageType.part} title={node.name} colorBorder={false}>
            <>
              <div className={"note"}>
                {node.name}
              </div>
            </>
          </ImageWidget>
          <BricklinkButton set={node.part_num} color={node.col_id} type={"P"}/><br/>
          <SearchButton part_num={node.part_num} cat_id={category}/>
      </li>
    )
  });
};

const AlbumCategoryElements = (props) => {
  let cat_id = props.match.params.cat_id;
  return (
    <div className={"inventory parts list"}>
      <ul className={"tiles album"}>
        <AlbumCategoriesElementList category={cat_id} />
      </ul>
    </div>
  )
}

export default AlbumCategoryElements
