import React, {useContext} from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql, useMutation} from '@apollo/client';
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import BricklinkButtonSet from "../../components/BricklinkButton";
import {showErrorNotification, showSendingNotification, showSuccessNotification} from "../../functions";
import {ReactReduxContext} from "react-redux";
import InventoryPartActions from "../../components/InventoryPartActions";
import SearchButton from "../../components/SearchButton";

const EXCHANGE_RATES = gql`
    query decorations {
        decorations {
            id
            part_num
            part {
                name
                category {
                    cat_id
                    name
                }
            }
            quantity
            color {
                name
            }
            col_id
            condition
            notes
#            sets {
#                set_num
#            }
        }
    }`;

const REMOVE_PART = gql`
    mutation removePartFromUserInventory($id: Int!) {
        removePartFromUserInventory(
            id: $id
        ){
            id
            quantity
            msg
            status
        }
    }`;


const PartsListEntries = ({entries, container, colors}) => {

    const [removePart, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(REMOVE_PART);

    if(mutationLoading){
        showSendingNotification();
    }

    if(mutationData){
        let data = mutationData.removePartFromUserInventory;
        console.log(data);
        if(data.status){
            showSuccessNotification();
            document.getElementById('part-' + data.id).remove();
        } else {
            showErrorNotification(data.msg);
        }
    }

    if(mutationError){
        showErrorNotification();
    }

    return entries.decorations.filter((node) => {
        return  true;
    }).map((node) => {

        console.log(node.part_num);

        let content = <div>
            <small><b>Name:</b> {node.part.name}</small><br/>
            <small><b>Color:</b> {colors[node.col_id].name} (ID: {node.col_id})</small><br/>
            <small><b>Condition:</b> {node.condition === 'new' ? 'NEW' : 'USED'}</small><br/>
            <div className={"tag"}>{node.part.category.name}</div>
        </div>

        return (
          <li key={node.id} id={`part-${node.id}`} className={"part"}>
              <ImageWidget content={content} color={node.col_id} set={node.part_num} type={BrickLinkImageType.part}
                           maxHeight={'100px'} maxWidth={'100%'}/>
              <div className={"toolbar"}>
                  <InventoryPartActions part_num={node.part_num} col_id={node.col_id} quantity={node.quantity} />
                  <br/><br/>
                  <BricklinkButtonSet name={node.part_num} set={node.part_num} type={"P"} />
                  <SearchButton part_num={node.part_num} cat_id={node.part.cat_id} />
              </div>
          </li>
        )}
    )};

const PartsList = ({entries, colors}) => {
    return <ul className={"tiles"}>
        <PartsListEntries entries={entries} colors={colors} />
    </ul>
}

const Decorations = () => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {colors} = state;

    const {data, loading, error} = useQuery(EXCHANGE_RATES, {
        fetchPolicy: 'cache-and-network'
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={"inventory decorations list"}>
            <nav className={"second-line"}>
                <ul className={"inventory-parts"}>
                    <li>
                        <Link className={"button"} to="/inventory/part/create">Add new part</Link>
                    </li>
                </ul>
            </nav>
            <PartsList entries={data || {}} colors={colors}/>
        </div>
    )
}

export default Decorations
