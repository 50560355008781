import React, {useState, useEffect, useContext} from 'react';
import {Link, } from 'react-router-dom'
import {gql, useMutation} from '@apollo/client';
import {ImageWidget} from "../../components/ImageWidget";
import {AllegroButtonUsedSet} from "../../components/AllegroButton";
import BricklinkButtonSet from "../../components/BricklinkButton";
import {ReactReduxContext} from "react-redux";
import {
  _get,
  apiPut,
  showErrorNotification,
  showSuccessNotification
} from "../../functions";
import _ from "lodash";
import SetInventoryList from "../../components/Set/SetInventoryList";

import {useForm, useWatch} from "react-hook-form";
import LoadingPage from "../../components/LoadingPage";

const UPDATE_SET_INVENTORY = gql`
    mutation updateUserSetInventory($input: inputUpdateUserSetInventory){
        updateUserSetInventory(input: $input){
            id
        }
    }
`;

const Inventory = (props) => {

  const {store} = useContext(ReactReduxContext);
  const state = store.getState();
  const {colors} = state;

  const [onlyMissingParts, setOnlyMissingParts] = useState(false);
  const [onlyWantedParts, setOnlyWantedParts] = useState(false);

  const [filterByColor, setFilterByColor] = useState(false);
  const [sortByCategory, setSortByCategory] = useState(false);

    const [inventoryData, setInventoryData] = useState(false)
    const [instructionMatch, setInstructionMatch] = useState(0)

    const [setDetails, setSetDetails] = useState([])
    const [loading, setLoading] = useState(true)

  let sortedInventoryData = false;

  let colorsRaw = [];
  let id = props.match.params.id;

    const [updateUserSetInventory, {
    loading: updateInventoryLoading,
    error: updateInventoryError
  }] = useMutation(UPDATE_SET_INVENTORY, {
    onCompleted: ({updateUserSetInventory}) => {
      if(updateUserSetInventory.id){
        showSuccessNotification();
      } else {
        showErrorNotification();
      }
    }
  });

  const [isSubmiting, setSubmit] = useState(false);
  const { register, control, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {

    let variables = {
      id: data.id,
      box: data.box,
      box_wanted: data.box_wanted,
      instruction: data.instruction,
      instruction_wanted: data.instruction_wanted,
      notes: data.notes,
      estimated_price: data.estimated_price,
      purchase_price: data.purchase_price,
      stage: data.stage,
      on_sale: data.on_sale,
    }

    setSubmit(true);
    apiPut('user/sets/' + data.id, variables)
        .then((data) => {
          console.log(data);

          setInventoryData(data.inventory)
          setInstructionMatch(data.storage.instruction)

          data.set.instruction_wanted = parseInt(data.set.instruction_wanted);
          data.set.instruction = parseInt(data.set.instruction);
          data.set.box = parseInt(data.set.box);
          data.set.box_wanted = parseInt(data.set.box_wanted);
          data.set.on_sale = parseInt(data.set.on_sale);

          setSetDetails(data.set);
          reset(data.set);
        })
  };

  const reloadData = () => {

    setLoading(true);
    _get(`user/set/inventory/${id}`, {}, true, 'Loading...', 'Done')
        .then((data) => {
          setInventoryData(data.inventory)
          setInstructionMatch(data.storage.instruction)

          data.set.instruction_wanted = parseInt(data.set.instruction_wanted);
          data.set.instruction = parseInt(data.set.instruction);
          data.set.box = parseInt(data.set.box);
          data.set.box_wanted = parseInt(data.set.box_wanted);
          data.set.on_sale = parseInt(data.set.on_sale);

          setSetDetails(data.set)
          setLoading(false)
          reset(data.set);
        })
  }

  useEffect(() => {
    reloadData();


    //   _get('user/set/inventory/' + id, {}, true, "Loading...")
    //       .then((data) => {
    //           console.log(data);
    //       })
    //
    // getUserInventory();
    // getUserSets();
  }, [])

    if (loading || !inventoryData) return <LoadingPage />

    const groups = []

  // if(updateInventoryLoading){
  //   console.log('updateInventoryLoading');
  //   showSendingNotification();
  // }
  //
  // if(updateInventoryError){
  //   showErrorNotification();
  // }
  //
  // const {data: inventoryData, loading: inventoryLoading, error: inventoryError} = useQuery(USER_SET_INVENTORY, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: {id}
  // });

  // const [getUserInventory, {data: userInventoryData}] = useLazyQuery(USER_INVENTORY, {
  //   fetchPolicy: 'cache-and-network'
  // })

  // const [getUserSets, {data: userSetsData}] = useLazyQuery(USER_SETS, {
  //   fetchPolicy: 'cache-and-network'
  // });
  //
  // const {data, loading, error} = useQuery(USER_SET, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: {id}
  // })

  // if (loading || inventoryLoading) return <p>Loading...</p>;
  // if (error || inventoryError) return <p>Error :(</p>;

  let wanted = [];
  // let types = [];

  inventoryData.map((node) => {

    if(!groups[node.group]) {
        groups[node.group] = 0;
    }
      groups[node.group] += 1;

    wanted[node.id] = node.wanted;

    if (!colorsRaw[node.col_id]) {
      colorsRaw[node.col_id] = {col_id: node.col_id, name: colors[node.col_id].name, quantity: 0};
    }

    colorsRaw[node.col_id].quantity += node.expected

    return true;
  });

  // console.log(types);

  // let string = '';
  // colors.colors.map((node) => {
  //   if (colorsRaw[node.col_id]) {
  //     colorsRaw[node.col_id].name = node.name;
  //   }
  //
  //   string += "\n" +
  //     "\n" +
  //     `.color-border-${node.col_id} { `+ "\n" +
  //     "@include color-border;" +
  //     `border-color: #${node.rgb};` + "\n" +
  //     "}" +
  //     "\n" +
  //     "\n";
  //
  //   return true;
  // });

// console.log(string);

  if (sortedInventoryData  === false && inventoryData) {
    sortedInventoryData = inventoryData.slice();
  }

  if (!sortedInventoryData) return <p>Loading inventory...</p>;

  if (sortByCategory) {
    const iterates_category = obj => obj.cat_id;
    const iterates_name = obj => obj.name;
    sortedInventoryData = _.sortBy(sortedInventoryData, [iterates_category, iterates_name]);
  }

  if (filterByColor) {
    sortedInventoryData = _.filter(sortedInventoryData, (obj) => parseInt(obj.col_id, 10) === parseInt(filterByColor, 10))
  }

  if(onlyWantedParts){
    sortedInventoryData = _.filter(sortedInventoryData, obj => obj.wanted);
  }

  if(onlyMissingParts){
    sortedInventoryData = _.filter(sortedInventoryData, obj => obj.quantity !== obj.expected);
  }

  // if(userInventoryData) {
  //   userInventoryData.userInventory.map((node) => {
  //     if (!userParts[node.part_num + '/' + node.col_id]) {
  //       userParts[node.part_num + '/' + node.col_id] = 0;
  //     }
  //     userParts[node.part_num + '/' + node.col_id] += parseInt(node.quantity);
  //
  //     return true;
  //   });
  // }

  // if(userSetsData) {
  //   userSetsData.userSets.map((node) => {
  //     if(node.type === 'minifig' && node.sold === false) {
  //
  //       if (!userSets[node.set_num]) {
  //         userSets[node.set_num] = 0;
  //       }
  //       userSets[node.set_num] += 1;
  //     }
  //
  //     return true;
  //   });
  // }

  // if(!instructionMatch) {
  //     _get('user/instructions/match/' + data.userSet.set_num, {}, false).then((data) => {
  //         console.log(data);
  //         setInstructionMatch(data.length);
  //     })
  // }

  console.log(groups);

    return (
    <div>
      <nav className={"second-line"}>
        <div className={"grid"}>
          <div className={"grid-start grid-end-4"}>
            <div className={"nav-title"}>
              Inventory
            </div>
          </div>
          <div className={"grid-start-4 grid-end-5"}><label>Filter by color:</label></div>
          <div className={"grid-start-5 grid-end-7"}>
            <select name={"color"} onChange={(event) => {
              setFilterByColor(event.target.value);
            }}>
              <option value={""}>All</option>
              {colorsRaw ? colorsRaw.map((node) => node ? (
                <option value={node.col_id} key={node.col_id} defaultValue={filterByColor}>
                  {node.name} ({node.quantity})
                </option>
              ) : null) : null}
            </select>
          </div>
          <div className={"grid-start-7 grid-end-8"}>
            <label style={{float: 'none', width: 'auto'}}>
              <input type={"checkbox"} name={"sortByCategory"} checked={sortByCategory} onChange={() => {
                setSortByCategory(!sortByCategory);
                sortedInventoryData = false;
              }}/> Sort by category
            </label>
          </div>
          <div  className={"grid-start-8 grid-end-9"}>
            <label style={{float: 'none', width: 'auto'}}>
              <input type={"checkbox"} name={"onlyMissingParts"} checked={onlyMissingParts} onChange={() => {
                reloadData();
                setOnlyMissingParts(!onlyMissingParts);
                sortedInventoryData = false;
              }}/> Show only missing
            </label>
          </div>
          <div  className={"grid-start-9 grid-end-10"}>
            <label style={{float: 'none', width: 'auto'}}>
              <input type={"checkbox"} name={"onlyWantedParts"} checked={onlyWantedParts} onChange={() => {
                reloadData();
                setOnlyWantedParts(!onlyWantedParts);
                sortedInventoryData = false;
              }}/> Show only wanted
            </label>
          </div>
        <div className={"grid-start-10 grid-end-11"}>
                <Link to={'/set/print/' + setDetails.id} className={"button"} target={"_blank"}>Print summary</Link>
        </div>
          <div className={"grid-start-11 grid-end"}>
              <Link to={'/set/delete/' + setDetails.id} className={"button danger"}>Remove set</Link>
          </div>
        </div>
      </nav>

      <form onSubmit={handleSubmit(onSubmit)}>
            <ul>
              <li>
                &nbsp;
              </li>
              <li>
                <h1 className={"big"}><span style={{color: 'black'}}>{id}</span><br/>
                  <span style={{color: 'grey'}}>{setDetails.set_num}</span><br/>
                  <span style={{color: 'darkgrey'}}>{setDetails.name}</span></h1>
                <div className={"notes"}>
                  <b>#{setDetails.hash}</b><br/>{setDetails.notes}
                </div>
              </li>
              <li>
                <label>Set preview</label>
              </li>
              <li>
                <div style={{maxWidth: 250}}>
                    <ImageWidget
                        zoom={false}
                        set={setDetails.set_num}
                        type={setDetails.type}
                        alwaysBig
                        maxWidth="360px"
                    />
                </div>
              </li>

                <li>
                    <label htmlFor="stage">Stage</label>
                </li>
                <li>
                    <select as="select" name="stage" defaultValue={setDetails.stage} {...register("stage")}>
                        <option value="sketch">Sketch</option>
                        <option value="reconstruction">Reconstruction</option>
                        <option value="finished">Finished</option>
                        <option value="completed">100% Completed</option>
                    </select>
                </li>
              <li>
                <label htmlFor={"notes"}>Notes</label>
              </li>
              <li>
                <textarea type={"text"} as={"textarea"} name={"notes"} rows={"5"} {...register("notes")}/>
              </li>

              <li>
                <label htmlFor={"purchase_price"}>Purchase price</label>
              </li>
              <li>
                <input type={"number"} as={"input"} name={"purchase_price"} {...register("purchase_price")}/>
              </li>

              <li>
                <label htmlFor={"estimated_price"}>Estimate price</label>
              </li>
              <li>
                <input type={"number"} as={"input"} name={"estimated_price"} {...register("estimated_price")}/>
                <br/><br/>
                  <AllegroButtonUsedSet set={setDetails.set_num} />
                  &nbsp;
                  <BricklinkButtonSet
                      set={setDetails.set_num}
                      type={setDetails.type}
                  />
              </li>

              <li>
                <label htmlFor={"instruction"}>Instruction</label>
              </li>
              <li>
                  <div className={"checkbox"}>
                        <input type={"checkbox"} as={"input"} name={"instruction"} {...register("instruction")}/> Instruction
                  </div>
                <div className={"wanted"}>
                        <input type={"checkbox"} is={"input"} name={"instruction_wanted"}
                               {...register("instruction_wanted")}
                        /> Wanted<br/>
                </div>
                {instructionMatch ? (
                    <div className="match">
                      {instructionMatch} in inventory
                    </div>
                ) : <>-</>}
              </li>
              <li>
                <label htmlFor={"box"}>Box</label>
              </li>
              <li>
                <div className={"checkbox"}>
                  <input type={"checkbox"} as={"input"} name={"box"} {...register("box")}/> Box
                </div>
                <div className={"wanted"}>
                  <input type={"checkbox"} as={"input"} name={"box_wanted"} {...register("box_wanted")}/> Wanted
                </div>
              </li>

            <li>
                <label htmlFor={"box"}><b>On sale</b></label>
            </li>
            <li>
                <input type={"checkbox"} as={"input"} name={"on_sale"} {...register("on_sale")}/> On sale
            </li>

            <li>&nbsp;</li>

              <li>
                <button type="submit">
                  Save
                </button>
              </li>
              <li>
                <hr/>
              </li>
            </ul>
      </form>

        <form>

          {groups.decorated ? (
              <>
                <h2>Decorated</h2>
                <ol>
                  <SetInventoryList
                      key="decorated"
                      colors={colors}
                      filter="decorated"
                      entries={sortedInventoryData}
                  />
                </ol>
              </>
          ) : null}

            {groups.counterpart ? (
                <>
                    <h2>Counterparts</h2>
                    <ol>
                        <SetInventoryList
                            key="Counterparts"
                            colors={colors}
                            filter="counterpart"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

            {groups.minifig ? (
                <>
                    <h2>Minifig</h2>
                    <ol>
                        <SetInventoryList
                            key="minifig"
                            colors={colors}
                            filter="minifig"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

            {/*{(onlyWantedParts || onlyMissingParts) && groups.minifig ? <>*/}
            {/*        <h2>Wanted minifig parts</h2>*/}
            {/*        <ol>*/}
            {/*            <SetInventoryList*/}
            {/*                key="minifig"*/}
            {/*                colors={colors}*/}
            {/*                filter="minifig-part"*/}
            {/*                entries={sortedInventoryData}*/}
            {/*            />*/}
            {/*        </ol>*/}
            {/*    </>*/}
            {/*    : null}*/}

            {groups.regular ? (
                <>
                    <h2>Regular items</h2>
                    <ol>
                        <SetInventoryList
                            key="regular"
                            colors={colors}
                            filter="regular"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

            {groups.gear ? (
                <>
                    <h2>Gear</h2>
                    <ol>
                        <SetInventoryList
                            key="gear"
                            colors={colors}
                            filter="gear"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

            {groups.extra ? (
                <>
                    <h2 style={{ color: 'red' }}>Extra</h2>
                    <ol style={{ border: '2px solid red' }}>
                        <SetInventoryList
                            key="extra"
                            colors={colors}
                            filter="extra"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

            {groups.alternative ? (
                <>
                    <h2 style={{ color: 'red' }}>Alternative</h2>
                    <ol style={{ border: '2px solid red' }}>
                        <SetInventoryList
                            key="alternative"
                            colors={colors}
                            filter="alternative"
                            entries={sortedInventoryData}
                        />
                    </ol>
                </>
            ) : null}

          </form>

    </div>
  )
}

export default Inventory
