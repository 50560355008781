import {IDictionary} from "./types";

function _fetch(method: string,
                path: string,
                variables: {} = {}): Promise<Response> {

    const token = localStorage.getItem('token') || ''
    let requestOptions: {} = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    if(method.toLowerCase() === 'put') {
        variables = {...variables, ...{'_method': 'PUT'}};
        method = 'POST'
    }

    console.log('call endpoint');
    console.log('path: ' + path);
    console.log('method: ' + method);
    console.log('variables');

    let call = process.env.REACT_APP_API_URL + '/api/' + path;

    if (method.toLowerCase() !== 'get') {
        console.log(variables);
        requestOptions = {...requestOptions, body: JSON.stringify(variables)}
    } else {
        console.log(encodeDataToURL(variables));
        call += '?' + encodeDataToURL(variables);
    }

    console.log('final endpoint: ' + call)

    return fetch(call, requestOptions);
}

function _delete(path: string,
                 variables: {} = {},
                 withNotification: boolean = true,
                 sendingNotification: string = "",
                 successNotification: string = "",
                 method: string = 'DELETE'): Promise<any> {

    if (withNotification) {
        showSendingNotification(sendingNotification);
    }

    return _fetch(method, path, variables)
        .then(function(response) {
            // if(response.status!==200) {
            //     localStorage.removeItem('token');
            //     window.location.reload();
            // }
            return response;
        })
        .then(data => {
            console.log('_delete');
            console.log(data);
            if (withNotification) {
                showSuccessNotification(successNotification);
            }
            return data;
        })
        .catch(error => {
            showErrorNotification(error.message);
        });
}

function _get(path: string,
              variables: {} = {},
              withNotification: boolean = true,
              sendingNotification: string = "",
              successNotification: string = "",
              method: string = 'GET'): Promise<any> {
    return _fetch(method, path, variables)
        .then(function(response) {
            // if(response.status!==200) {
            //     localStorage.removeItem('token');
            //     window.location.reload();
            // }
            return response;
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (withNotification) {
                showSuccessNotification(successNotification);
            }
            return data;
        })
        .catch(error => {
            console.log('error');
            console.log(error);
            showErrorNotification(error.message);
        });
  }

function endpoint(path: string,
                  method: string = 'GET',
                  variables: {} = {},
                  withNotification: boolean = true,
                  sendingNotification: string = "",
                  successNotification: string = ""): Promise<Response> {

    let token = localStorage.getItem('token') || '';
    let requestOptions: {} = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    console.log('call endpoint');
    console.log('path: ' + path);
    console.log('method: ' + method);
    console.log('variables');

    let call = process.env.REACT_APP_API_URL + '/api/' + path;

    if (method.toLowerCase() !== 'get') {
        console.log(variables);
        requestOptions = {...requestOptions, body: JSON.stringify(variables)}
    } else {
        console.log(encodeDataToURL(variables));
        call += '?' + encodeDataToURL(variables);
    }

    console.log('final endpoint: ' + call)

    if (withNotification) {
        showSendingNotification(sendingNotification);
    }

    return fetch(call, requestOptions)
        .then(function(response) {
            // if(response.status!==200) {
            //     localStorage.removeItem('token');
            //     window.location.reload();
            // }
            return response;
        })
        .then(response => response.json())
        .then(data => {
            if (withNotification) {
                showSuccessNotification(successNotification);
            }
            return data;
        })
        .catch(error => {
            showErrorNotification(error.message);
        });
}

function apiPut(path: string,
                variables: {} = {},
                withNotification: boolean = true,
                sendingNotification: string = "",
                successNotification: string = ""): Promise<Response> {
    const _variables = {...variables, ...{'_method': 'PUT'}};
    return endpoint(path, 'POST', _variables, withNotification, sendingNotification, successNotification);
}

// function apiDelete(path: string,
//                    variables: {} = {},
//                    withNotification: boolean = true,
//                    sendingNotification: string = "",
//                    successNotification: string = ""): Promise<Response> {
//     return _delete(path, variables, withNotification, sendingNotification, successNotification);
// }

function apiPost(path: string,
                 variables: {} = {},
                 withNotification: boolean = true,
                 sendingNotification: string = "",
                 successNotification: string = ""): Promise<Response> {
    return endpoint(path, 'POST', variables, withNotification, sendingNotification, successNotification);
}

// function apiGet(path: string,
//                 variables: {} = {},
//                 withNotification: boolean = true,
//                 sendingNotification: string = "",
//                 successNotification: string = ""): Promise<Response> {
//     return endpoint(path, 'GET', variables, withNotification, sendingNotification, successNotification);
// }

function debounce(func: () => void, wait: number, immediate: boolean = false) {
    let timeout: ReturnType<typeof setTimeout>
    return () => {
        // @ts-ignore
        const context: any = this, args: any = arguments;
        const later = function () {
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

const encodeDataToURL = (data: IDictionary) => {
    return Object
        .keys(data)
        .filter((value) => data[value])
        .map((value) => `${value}=${encodeURIComponent(data[value])}`)
        .join('&');
}

const clearNotifications = () => {
    document.getElementById('data-error')?.classList.remove('visible')
    document.getElementById('data-sending')?.classList.remove('visible')
    document.getElementById('data-success')?.classList.remove('visible')
}

const showSendingNotification = (msg?: string) => {
    clearNotifications()

    msg = msg || 'Sending...'
    let div = document.getElementById('data-sending');
    if (div) {
        div.innerHTML = msg;
        div.classList.add('visible');
    }
}

const showSuccessNotification = (msg?: string, timeout = 3000) => {
    clearNotifications()

    msg = msg || 'Saved!';
    let div = document.getElementById('data-success');
    if (div) {
        div.innerHTML = msg
        div.classList.add('visible')
    }

    setTimeout(() => {
        document.getElementById('data-success')?.classList.remove('visible')
    }, timeout)
}

const showErrorNotification = (msg?: string, timeout = 9000) => {
    clearNotifications()

    msg = msg || 'Error!';
    let div = document.getElementById('data-error');
    if (div) {
        div.innerHTML = msg;
        div.classList.add('visible');
    }
    setTimeout(() => {
        document.getElementById('data-error')?.classList.remove('visible')
    }, timeout)
}

export {
    showSendingNotification,
    showSuccessNotification,
    showErrorNotification,
    encodeDataToURL,
    debounce,
    endpoint,
    // apiGet,
    apiPost,
    apiPut,
    // apiDelete,
    _delete,
    _get
}
