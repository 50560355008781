import React, {useContext, useEffect, useState} from 'react';
import {useForm, useWatch, SubmitHandler, Control} from "react-hook-form";
import {useHistory} from "react-router-dom";
import ImageWidget from "./ImageWidget";
import {BrickLinkImageType} from "./BrickLinkImageSrc";
import {_get} from "../functions";
import {_color, _minifig, _part, _userSetInventory} from "../types";
import {LoaderIcon} from "react-hot-toast";
import LoadingPage from "./LoadingPage";
import SearchButton from "./SearchButton";
import {ReactReduxContext} from "react-redux";
import {CategoryTag} from "./CategoryTag";
import InventoryPartActions from "./InventoryPartActions";
import BricklinkButton from "./BricklinkButton";


interface __spotlightSets {
    set_num: string,
    name: string
}

interface __spotlightUserSets {
    id: number
    set_num: string,
    name: string
    condition: string
}

interface __spotlightUserInventory {
    id: number
    part_num: string
    col_id: number
    cat_id: number
    name: string
    quantity: number
}

interface __spotlightUserMinifigure {
    id: number
    set_num: string
    name: string
    condition: string
    parts: number
    expected: number
}

interface __spotlightMinifigure {
    set_num: string
    name: string
}

interface __spotlightPart {
    part_num: string
    col_id: number
    cat_id: number
    name: string
}

interface __spotlight {
    sets: __spotlightSets[]
    sets_count: number

    user_sets: __spotlightUserSets[]
    user_sets_count: number

    user_inventory: __spotlightUserInventory[]
    user_inventory_count: number

    user_minifigure: __spotlightUserMinifigure[]
    user_minifigure_count: number

    minifigures: __spotlightMinifigure[]
    minifigures_count: number

    parts: __spotlightPart[]
    parts_count: number

    clean: boolean
}

const Spotlight = () => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    let {categories} = state;

    const spotlightInitialStage : __spotlight = {
        sets: [],
        sets_count: 0,
        user_sets: [],
        user_sets_count: 0,
        user_inventory: [],
        user_inventory_count: 0,
        user_minifigure: [],
        user_minifigure_count: 0,
        minifigures: [],
        minifigures_count: 0,
        parts: [],
        parts_count: 0,
        clean: true
    };

    // const {register, control, handleSubmit, watch, formState: {errors}} = useForm<FormInputs>();
    const [phase, setPhase] = useState("");
    const [spotlightResult, setSpotlightResult] = useState<__spotlight>(spotlightInitialStage);
    const [searchData, setSearchData] = useState(false);

    const searchPhase = () => {
        setSearchData(true);
        _get('spotlight', {phase: phase}, false).then((data) => {
            console.log('result');
            console.log(data);
            data.clean = false;
            setSpotlightResult(data);
            setSearchData(false);
        }).catch((error) => {
            console.log(error);
            setSearchData(false);
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(phase.length >= 3) {
                searchPhase();
            } else {
                setSpotlightResult(spotlightInitialStage);
            }
            // Send Axios request here
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [phase])

    return (
        <div className={"nav-search"}>
            <form onSubmit={(e) => {
                if(phase.length >= 3) {
                    searchPhase();
                }
                e.preventDefault();
            }}>
                <input autoFocus={true} onChange={(e) => {
                    setPhase(e.target.value);
                }}/>
            </form>
            {searchData ? <div className={"spotlight"}>
                <LoadingPage />
            </div> : null}
            {!spotlightResult.clean ?
                <div className={"spotlight"}>
                    <div className={"spotlight-close-button"}>
                        <div onClick={() => {setSpotlightResult(spotlightInitialStage);
                        }}>X</div>
                    </div>
                    <div className={"spotlight-header"}>

                        {spotlightResult.sets.length > 0 ? <>
                            <h3>Sets {spotlightResult.sets_count > 8 ? <>and {spotlightResult.sets_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.sets.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.set_num}<br/>
                                                {data.name}<br/>
                                            </>} set={data.set_num} type={BrickLinkImageType.box} maxHeight={"60px"}/>
                                            <a className={"button"} href={"/set/preview/" + data.set_num}>Preview</a>
                                            <a className={"button"} href={"/set/create/?set_num=" + data.set_num + '&condition=new'}>Add new</a>
                                            <a className={"button"} href={"/set/create/?set_num=" + data.set_num + '&condition=used'}>Add used</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}

                        {spotlightResult.user_sets.length > 0 ? <>
                            <h3>User sets {spotlightResult.user_sets_count > 8 ? <>and {spotlightResult.user_sets_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.user_sets.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.name}<br/>
                                            </>} set={data.set_num} type={BrickLinkImageType.set} maxHeight={"60px"}/>
                                            {data.condition == 'used' ?
                                                <>
                                                    <a className={"button"} href={"/set/inventory/" + data.id}>Inventory</a>
                                                </> : null
                                            }
                                            <a className={"button"} href={"/set/sell/" + data.id}>Sell</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}

                        {spotlightResult.user_inventory.length > 0 ? <>
                            <h3>User Inventory {spotlightResult.user_inventory_count > 8 ? <>and {spotlightResult.user_inventory_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.user_inventory.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.name}<br/>
                                                <CategoryTag name={categories[data.cat_id].name}/>
                                            </>
                                            } set={data.part_num} color={data.col_id} type={BrickLinkImageType.part} maxHeight={"60px"}/>
                                            <InventoryPartActions part_num={data.part_num} col_id={data.col_id} quantity={data.quantity} />
                                            <SearchButton part_num={data.part_num} col_id={data.col_id} cat_id={data.cat_id} />
                                            <BricklinkButton set={data.part_num} color={data.col_id.toString()} type={BrickLinkImageType.part} />

                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}

                        {spotlightResult.parts.length > 0 ? <>
                            <h3>Parts {spotlightResult.parts_count > 8 ? <>and {spotlightResult.parts_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.parts.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.name}<br/>
                                            </>} set={data.part_num} color={data.col_id} type={BrickLinkImageType.part} maxHeight={"60px"}/>
                                            <SearchButton part_num={data.part_num} col_id={data.col_id} cat_id={data.cat_id} /><br/>
                                            <CategoryTag name={categories[data.cat_id].name}/>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}

                        {spotlightResult.minifigures.length > 0 ? <>
                            <h3>Minifigures {spotlightResult.minifigures_count > 8 ? <>and {spotlightResult.minifigures_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.minifigures.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.name}<br/>
                                            </>} set={data.set_num} type={BrickLinkImageType.minifig} maxHeight={"60px"}/>
                                            <a className={"button"} href={"/set/preview/" + data.set_num}>Preview</a>
                                            <a className={"button"} href={"/inventory/minifig/create/?set_num=" + data.set_num + '&condition=used'}>Add used</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}

                        {spotlightResult.user_minifigure.length > 0 ? <>
                            <h3>User minifigure {spotlightResult.user_minifigure_count > 8 ? <>and {spotlightResult.user_minifigure_count - 8} more</> : null}</h3>
                            <ul className={"spotlight-group tiles"}>
                                {spotlightResult.user_minifigure.map((data) => {
                                    return (
                                        <li className={"spotlight-node part"}>
                                            <ImageWidget content={<>
                                                {data.name}<br/>
                                            </>} set={data.set_num} type={BrickLinkImageType.minifig} maxHeight={"60px"}/>
                                            {data.condition == 'used' ?
                                                <>
                                                    <a className={"button"} target={"_blank"} href={"/set/inventory/" + data.id}>Inventory {data.expected - data.parts}/{data.expected}</a>
                                                </> : <>
                                                    New
                                                </>
                                            }
                                            <a className={"button"} href={"/set/sell/" + data.id}>Sell</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </> : null}


                    </div>
                </div> : null}

        </div>
    )
}

export default Spotlight;