import React, {useContext, useEffect, useState} from 'react';
import {gql, useMutation} from '@apollo/client';
import {Field, Formik} from "formik";
import {BrickLinkImageType} from "../components/BrickLinkImageSrc";
import {ImageWidget} from "../components/ImageWidget";
import {ReactReduxContext} from "react-redux";
import BricklinkButtonSet from "../components/BricklinkButton";
import {
    _get,
    apiPost,
    encodeDataToURL,
} from "../functions";
import SearchButton from "../components/SearchButton";
// import _parts from '../../assets/parts_json'

import _ from "lodash";
import FoundInWanted from "../FoundInWanted";
import InventoryPartActions from "../components/InventoryPartActions";

// const ADD_PART = gql`
//     mutation addPartToInventory($number: String!, $condition: String!, $quantity: Int!, $color: Int!,  $notes: String) {
//         addPartToInventory(
//             number: $number
//             condition: $condition
//             quantity: $quantity
//             color: $color
//             notes: $notes
//         ){
//             id
//             status
//             part_num
//             col_id
//             quantity
//             sets {
//                 id
//                 set_num
//                 hash
//                 set {
//                     name
//                     set_num
//                 }
//                 parts
//                 minifigs
//                 minifigs_parts
//                 notes
//                 wanted_count
//             }
//         }
//     }`;

const MOVE_PART = gql`
    mutation moveUserInventoryPartToUserSet($id: Int, $user_set_id: Int, $quantity: Int) {
        moveUserInventoryPartToUserSet(
            id: $id,
            user_set_id: $user_set_id,
            quantity: $quantity
        ){
            status
            part_num
            col_id
            quantity
        }
    } `


const FormOptions = ({max}) => {
    let content = [];
    for (let i = 0; i <= max; i++) {
        content.push(<option key={i} value={i}>{i}</option>);
    }
    return content;
}
const PartsListEntries = ({entries, colors, container, categories, onSelect}) => {
    console.log(categories);
    return entries.map((node) => {
            // console.log(node);
            // let _part = _parts[node.id];
            // // console.log(node.id);
            // // console.log(_part);
            // // let _part = _source;
            // if(!_part){
            //     console.log('missing');
            //     console.log(node.id);
            //    _part = {
            //         name: 'unknown',
            //         cat_id: 1
            //    };
            // }
            let _part = node;
            let content = <div>
                <small><b>Name:</b> {_part.name}</small><br/>
                <small><b>Color:</b> {colors[node.col_id].name} (ID: {node.col_id})</small><br/>
                <div className={"tag"}>{categories[_part.cat_id].name}</div>
            </div>
            return (
                <li key={node.part_num + '-' + node.col_id} className={"part"}>
                    <ImageWidget content={content} color={node.col_id} set={node.part_num} type={BrickLinkImageType.part}
                                 colorName={colors[node.col_id].name} maxHeight={"80px"}/>
                    <div className={"toolbar"}>
                        <BricklinkButtonSet set={node.part_num} color={node.col_id} name={node.id} title={_part.name} type={"P"}/>
                        &nbsp;
                        <SearchButton part_num={node.part_num} cat_id={_part.cat_id}/>
                        <div className={"form"}>
                            <InventoryPartActions part_num={node.part_num} col_id={node.col_id} quantity={node.user_inventory} />
                        </div>
                    </div>
                </li>
            )
        }
    )
};

const Search = (props) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    let {colors, categories} = state;

    let _name = new URLSearchParams(props.location.search).get("name") || '';
    let _part_num = new URLSearchParams(props.location.search).get("part_num") || '';
    let _cat_id = new URLSearchParams(props.location.search).get("cat_id") || '';
    let _col_id = new URLSearchParams(props.location.search).get("col_id") || '';

    const searchParts = (variables) => {
        setFetchSearch(true);
        _get('search', variables, true, "Searching...", "Done")
            .then((data) => {
                setFoundParts(data)
                setFetchSearch(false);
            })
        window.history.pushState({}, "Search", '/search?' + encodeDataToURL(variables));
    }

    const [foundParts, setFoundParts] = useState(false);
    const [fetchSearch, setFetchSearch] = useState(false);

    const addPart = (variables) => {
        apiPost('user/inventory', variables, 'true', 'Adding...', 'Done')
            .then((data) => {
                console.log(data);
                if(data.sets.length){
                    setFoundInWanted(data);
                }
            })
    }

    const [foundInWanted, setFoundInWanted] = useState(false);

    const movePart = (variables) => {

        console.log(variables);

        apiPost('user/inventory/move-to-set', variables, true)
            .then(data => {
                if (data.quantity !== 0) {
                    addPart({
                        part_num: data.part_num,
                        col_id: data.col_id,
                        condition: 'used',
                        quantity: 0,
                        notes: ''
                    });
                }
            })
    }

    useEffect(() => {
        if (_part_num || _name || (_cat_id && _col_id)) {
            searchParts({
                name: _name,
                part_num: _part_num,
                col_id: _col_id,
                cat_id: _cat_id
            });
        }
    }, [])

    return (
        <div className={"inventory search list"}>
            <nav className={"second-line"}>
                <Formik
                    initialValues={{name: _name, part_num: _part_num, col_id: _col_id, cat_id: _cat_id}}
                    onChange={(values) => {
                        console.log(values);
                    }}
                    onSubmit={(values, {setSubmitting}) => {

                        // console.log('submit');
                        // console.log(values);
                        searchParts(
                            values
                        );

                        setSubmitting(false);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className={"form_search"}>
                            <div className={"grid"}>
                                <div className={"grid-start grid-end-4"}>
                                    <Field type={"text"} name={"name"} placeholder={'torso, black belt'}/>
                                </div>
                                <div className={"grid-start-4 grid-end-6"}>
                                    <Field type={"text"} name={"part_num"} placeholder={'973pb0849c01'}/>
                                </div>
                                <div className={"grid-start-6 grid-end-8"}>
                                    <Field as="select" name="col_id" placeholder={"Color"}>
                                        <option value={0}>Any color</option>
                                        {_.sortBy(colors.filter(node => node !== null), [(o) => o.name])
                                            .map((node) => (
                                            <option key={node.col_id} value={node.col_id}
                                                    style={{backgroundColor: '#' + node.rgb}}>{node.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div className={"grid-start-8 grid-end-10"}>
                                    <Field as="select" name="cat_id" placeholder={"Category"}>
                                        <option value={0}>Any category</option>
                                        {_.sortBy(categories.filter(node => node !== null), [(o) => o.name])
                                            .filter(node => node.parts > 0)
                                            .map((node, index) => (
                                            <option key={node.cat_id} value={node.cat_id}>{node.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div className={"grid-start-10 grid-end"}>
                                    <button type={"submit"}>Search</button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </nav>

            {foundInWanted ? <FoundInWanted
                node={foundInWanted}
                onMoveToSet={(from, to, qty) => {
                    console.log(from);
                    console.log(to);
                    movePart({
                        user_inventory_id: parseInt(from),
                        user_set_id: parseInt(to),
                        quantity: parseInt(qty)
                    });
                    setFoundInWanted(false);
                }}
                onClose={() => {
                    console.log('found false');
                    setFoundInWanted(false);
                }}
            /> : null}

            {fetchSearch ?
                <div style={{textAlign: 'center', padding: '20px'}}>Search....</div> :
                foundParts ?
                    <ul className={"tiles"}>
                        <PartsListEntries entries={foundParts || {}} colors={colors} categories={categories}
                                          onSelect={(node) => {
                                              console.log(node);
                                              let quantity = prompt("Quantity", 1);
                                              if (quantity) {
                                                  addPart({
                                                      part_num: node.part_num,
                                                      col_id: node.col_id,
                                                      condition: 'used',
                                                      quantity,
                                                      notes: ''
                                                  });
                                              }
                                          }
                                          }/>
                    </ul>
                    :
                    <div style={{textAlign: 'center', padding: '20px'}}>Nothing found!</div>
            }
        </div>
    )
}

export default Search;
