import React from 'react';
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';

const EXCHANGE_RATES = gql`
    query settings {
        settings(orderBy: {
            field: "id",
            order: DESC
        }){
            id
            key
            value
        }
    }`;


const List = ({entries}) => {
    console.log(entries);
    return entries.map((node) => (
        <div className={"grid table"} key={node.id}>
            <div className={"grid-start grid-end-3 grid-align-left"}>
                {node.key}
            </div>
            <div className={"grid-start-3 grid-end-10 grid-align-left"}>{node.value ? node.value : '-'}</div>
            <div className={"grid-start-10 grid-end grid-align-left"}>
                <Link to={'/setting/update/' + node.id}>Edit</Link>
                <Link to={'/setting/delete/' + node.id}>Delete</Link>
            </div>
        </div>
    ));
}

const SettingsList = () => {

    const { data, loading, error} = useQuery(EXCHANGE_RATES, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
      <div className={"list containers"}>
          <nav className={"second-line"}>
            <div className={"grid"}>
              <div className={"grid-start grid-end-2 grid-align-left"}>
                <div className={"nav-title"}>
                  Settings
                </div>
              </div>
              <div className={"grid-start-10 grid-end"}>
                <Link className={"button"} to="/setting/create">Add new key</Link>
              </div>
            </div>
          </nav>

          <div className="grid table header">
              <div className="grid-start grid-end-3 grid-align-left">Key</div>
              <div className="grid-start-3 grid-end-10">Value</div>
              <div className="grid-start-10 grid-end">Options</div>
          </div>
          <List entries={data.settings || {}} />
      </div>
    )
}

export default SettingsList
