import React from 'react';
import {useQuery, gql} from '@apollo/client';
import {Form, Formik} from "formik";
import {PageHeader} from "../../../components/PageHeader";
import {RouteComponentProps, withRouter} from "react-router";
import {_userSetInventory} from "../../../types";
import PartPreview from "../../../components/Items/PartPreview";
import {PageNavigation} from "../../../components/PageNavigation";
import {PageNavigationSeparator} from "../../../components/PageNavigationSeparator";

const PARTS = gql`
    query MissingPartsForSelectedSets($sets: String!) {
        missingPartsForSelectedSets(sets: $sets) {
            id
            part {
                name
                category {
                    name
                }
                part_num
            }
            part_num,
            minifig {
                name
                set_num
            }
            minifig_num,
            user_set_id,

            expected,
            quantity,
            color {
                name
                col_id
            }
            type
        }
    }`;

interface __missingPartsForSelectedSets {
    missingPartsForSelectedSets: _userSetInventory[];
}

interface __missingSetsPartsListItems {
    sets: string | undefined
}

const MissingSetsPartsListItems = ({sets}: __missingSetsPartsListItems) => {

    const {data, loading, error} = useQuery<__missingPartsForSelectedSets>(PARTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            sets: sets
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (data) {
        return <>
            {data.missingPartsForSelectedSets.map((item: _userSetInventory) => (
                <PartPreview node={item} key={item.id}/>
            ))}
        </>
    }

    return <>Undefined!</>
};

const MissingSetsPartsList = (props: RouteComponentProps<{ sets?: string }>) => {
    console.log('a');
    const sets = props.match.params.sets;
    return (
        <div>
            <PageHeader title={"List of missing parts from selected sets"}/>
            <PageNavigation>
                Select all
                <PageNavigationSeparator/>
                Unselect all
            </PageNavigation>
            <Formik
                initialValues={{
                    selected: []
                }}
                onSubmit={async (values) => {
                    console.log(values);
                    // props.push('/index')
                }}
            >
                {({values}) => (
                    <Form>
                        <div className={"missing_sets_part_list"}>
                            <MissingSetsPartsListItems sets={sets}/>
                        </div>
                        <button type="submit" name="submit">
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default withRouter(MissingSetsPartsList);
