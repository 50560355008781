import React from 'react';
import { Formik, Field } from 'formik';
import { useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

const ADD_SETTINGS = gql`
    mutation createSetting($key: String!, $value: String) {
        createSetting(
            key: $key,
            value: $value
        ){
            id
        }
    }`;


const CreateSetting = (props) => {

    const [createSetting, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(ADD_SETTINGS);

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    console.log(mutationData);
    if(mutationData){
        props.push('/settings');
    }

    return (
        <div className={"containers form"}>

            <Formik
                initialValues={{key: '', value: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.key) {
                        errors.key = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    createSetting({
                        variables: values
                    }).catch((e) => {
                      console.log(e);
                    });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li>
                                <label htmlFor="key">Key</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="key"
                                />
                                {errors.key && touched.key && errors.key}
                            </li>

                            <li>
                                <label htmlFor="value">Value</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"value"} rows={"5"}/>
                                {errors.value && touched.value && errors.value}
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Create
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(CreateSetting)
