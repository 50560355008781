import React from 'react'

const IconBrick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={{ width: 16, display: 'inline-block', padding: 6 }}>
      <path d="M170.97 116.54c-12.63 4.79-26.22 17.8-32.73 31.2-4.79 9.38-4.79 10.34-5.36 68.33l-.57 58.57-37.71.77c-35.98.57-38.28.77-48.43 5.55-13.02 6.12-22.97 15.69-30.05 29.09L10.95 320l-.57 277.16L10 874.51l6.51 6.51 6.51 6.51h953.96l6.51-6.51 6.51-6.51-.38-276.39-.57-276.2-4.98-10.72c-6.32-13.59-16.65-23.93-30.24-30.24-10.33-4.79-12.44-4.98-48.42-5.55l-37.71-.57-.57-58.76c-.57-58-.57-58.95-5.36-68.33-6.7-13.97-20.1-26.41-33.31-31.39-11.1-4.02-12.63-4.21-107.19-3.64l-95.89.57-9.57 5.17c-12.63 6.7-22.59 17.23-28.52 30.24l-4.98 10.53v115.81H417.9l-.57-59.91-.57-59.72-5.17-9.76c-6.7-12.63-20.1-24.5-33.3-29.48-9.95-3.63-14.55-3.83-103.74-3.63-89.8-.02-93.62.17-103.58 4zm196.58 39.81c3.45 2.11 7.08 6.13 8.04 8.81 1.15 2.68 1.91 27.94 1.91 57.42v52.44H172.7v-51.49c0-33.12.77-53.4 2.11-57.42 1.34-3.45 4.98-7.85 8.23-9.95 5.55-3.44 10.72-3.64 92.07-3.64 82.68.01 86.31.2 92.44 3.83zm448.46-.76c10.53 5.36 11.29 10.53 11.29 67.76v51.68H622.31l.57-55.31c.57-52.26.76-55.51 4.4-59.53 2.1-2.3 5.55-4.98 7.66-5.93 2.1-.77 42.49-1.53 89.58-1.53 74.26-.2 86.52.18 91.49 2.86zm126.52 167.1l6.32 5.55.57 259.55.38 259.55H50.2l.38-259.16.57-259.36 5.55-5.74 5.74-5.94H936.4l6.13 5.55z"/>
    </svg>
  )
}

export default IconBrick;
