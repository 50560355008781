import React, { useState } from 'react'
import { apiPost } from '../functions'

const Options = ({ max, id }) => {
  const content = []
  for (let i = 0; i <= max; i++) {
    content.push(<option key={`${id}-${i}`} value={i}>{i}</option>)
  }
  return content
}

const InventoryQuantityAction = ({
  partId, quantity = 0, expected = 0, updateInventory = true,
}) => {
  const [qty, setQuantity] = useState(quantity)

  const update = (variables) => {
    if (updateInventory) {
      apiPost(`user/set/inventory/quantity/${partId}`, variables, 'true', 'Wanted...', 'Done')
        .then((data) => {
          console.log(data)
          setQuantity(data.quantity)
        })
    }
  }

  let inputStyle = {
    padding: '3px',
    fontWeight: 'bold',
  }

  if (qty === expected) {
    inputStyle = {
      backgroundColor: 'green',
      color: 'white',
      padding: '3px',
      fontWeight: 'bold',
    }
  }

  return (
    <div className="inventory-quantity-actions" style={inputStyle}>
      <select
        name={`parts['${partId.toString()}']`}
        className="formSetPartQuantity"
        defaultValue={qty}
        onChange={(e) => {
          update({
            quantity: e.target.value,
          })
        }}
      >
        <Options max={expected} id={partId} />
      </select>
      <div className="expected">{` / ${expected}`}</div>
    </div>
  )
}

export default InventoryQuantityAction
