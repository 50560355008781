import React from 'react';
import {Link} from 'react-router-dom'
import {useQuery, gql} from '@apollo/client';

import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import {AllegroButtonMinifig} from "../../components/AllegroButton";
import BricklinkButtonSet from "../../components/BricklinkButton";


const EXCHANGE_RATES = gql`
    query sets($type: String!, $condition: String!, $sold: Boolean!) {
        sets(type: $type, condition: $condition, sold: $sold) {
            id
            set {
                set_num
                name
                parts
            }
            parts
            condition
            completeness
        }
    }`;

const SetsListEntriesList = ({entries, container}) => {
  return entries.sets.map((node) => {

    let color = 'red';
    let desc = `${node.set.parts - node.parts} / ${node.set.parts}`;

    if (node.parts === 0) {
      color = 'green';
      desc = '100%';
    }

    if(node.condition === 'new'){
        color = 'blue';
        desc = 'new';
    }

    const content = <div>
      <div className={"set"}>{node.set.set_num}</div>
      <div className={"desc"}>{node.set.name}</div>
        <div className={"desc"}><span className={color}>{desc}</span></div>
      <br/>
      <div className={"buttons"}>
        <Link to={'/set/sell/' + node.id}>Sell</Link>
        <Link to={'/set/inventory/' + node.id}>Inventory</Link>
        <Link to={'/set/delete/' + node.id}>Delete</Link>
        <AllegroButtonMinifig set={node.set.set_num}/>
        <BricklinkButtonSet set={node.set.set_num} type={"M"} />
      </div>
      {node.notes ? <div className={'note'}>{node.notes}</div> : null}
    </div>;

    return (
      <li key={node.id} className={"part"}>
        <ImageWidget title={node.set.set_num} content={content} set={node.set.set_num} type={BrickLinkImageType.minifig}
                     maxWidth={'100%'} maxHeight={100}/>
        <div className={"set"}>{node.set.set_num}</div>
        <div className={"desc"}><span className={color}>{desc}</span></div>
      </li>
    )
  });
};

const SetsListWithData = ({entries}) => {
  return <ul className={"tiles"}>
    <SetsListEntriesList entries={entries}/>
  </ul>
}

const Minifigs = () => {

  const {data, loading, error} = useQuery(EXCHANGE_RATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: 'minifig',
      condition: 'used',
      sold: false
    }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className={"inventory minifigs list"}>
      <nav className={"second-line"}>
        <ul className={"inventory-minifig"}>
          <li>
            <Link className={"button"} to="/inventory/minifig/create">Add new minifig</Link>
          </li>
        </ul>
      </nav>
      <SetsListWithData entries={data || {}}/>
    </div>
  )
}

export default Minifigs
