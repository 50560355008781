import React, {useEffect, useState} from 'react';
import {_get, apiPut} from "../../../functions";

import {useForm, useWatch} from "react-hook-form";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";
import ImageWidget from "../../../components/ImageWidget";
import {useHistory} from "react-router-dom";

interface FormInputs {
    set_num: string;
    quantity: number;
    expected: number;
    notes: string;
}

const Edit = (props: any) => {

    console.log('edit');

    const history = useHistory();
    const [isSubmiting, setSubmit] = useState(false);
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm<FormInputs>();
    const set_num = useWatch({
        control,
        name: "set_num",
        defaultValue: ""
    });

    useEffect(() => {
        console.log('useEffect');
        console.log(props);
        let id = props.match.params.id;
        console.log(id);
        _get('user/instructions/' + id, {}, false).then((data) => {
            console.log(data);
            // @ts-ignore
            reset(data);
        })
    }, []);

    const onSubmit = (data: FormInputs) => {
        setSubmit(true);
        let id = props.match.params.id;
        apiPut('user/instructions/' + id, data).then((data) => {
            console.log(data);
            setSubmit(false);
            history.push('/user/instructions');
        })
    };

    return (
        <div className={"containers form"}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <ul>

                <li>Preview:</li>
                <li>
                    <div style={{maxWidth: 250}}>
                        {set_num.includes('-1') ? <ImageWidget set={set_num} type={BrickLinkImageType.instruction}/> : null}
                    </div>
                </li>

                <li>Set num:</li>
                <li>
                    <input {...register("set_num", {required: true})} autoFocus={true} />
                    {errors.set_num && <span className={'form-error'}>This field is required</span>}
                </li>

                <li>
                    Book:
                </li>
                <li>
                    <input {...register("quantity")} />
                </li>

                <li>
                    Notes:
                </li>
                <li>
                    <textarea {...register("notes")} />
                </li>

                <li>
                    <button type="submit" disabled={isSubmiting}>
                        Save
                    </button>
                </li>
            </ul>
            </form>
        </div>
    )
}

export default Edit
