import React from 'react'

const IconMinifig = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" style={{ width: 16, display: 'inline-block', padding: 6 }}>
      <path d="M375.85 13.89c-12.88 5-26.52 17.1-33.06 29.4l-5.19 9.8-.58 59.77-.58 59.96-44.59.96c-36.71.77-47.47 1.54-60.54 5-34.79 8.84-64.77 26.14-88.98 51.5-23.06 24.21-37.09 48.62-46.32 80.53L91.61 326v392.06l4.42 15.18c9.23 31.9 23.25 56.31 46.32 80.52 25.56 26.71 59 45.36 94.56 52.47l16.91 3.46.58 56.12c.58 52.08.77 56.31 4.23 60.16l3.65 4.03h475.45l3.65-4.03c3.46-3.84 3.65-8.07 4.23-60.16l.58-56.12 16.91-3.46c35.55-7.11 68.99-25.75 94.55-52.47 23.06-24.21 37.09-48.62 46.32-80.52l4.42-15.18V522.99c0-194.3 0-195.07-4.23-210.83-8.26-30.94-23.06-57.08-45.74-81.1-24.02-25.18-53.24-42.67-86.87-51.51-16.91-4.42-23.45-5-63.42-5.77l-44.59-.96-.58-59.96-.56-59.76-5.19-9.8c-6.73-12.69-20.18-24.6-33.44-29.6-10.19-3.65-14.41-3.84-124.34-3.65-108.59 0-114.16.19-123.58 3.84zm237.16 40.17c3.46 2.11 7.11 6.15 8.07 8.84 1.15 2.69 1.92 28.06 1.92 57.66v52.66H377v-52.66c0-29.6.77-54.97 1.92-57.66.96-2.69 4.61-6.73 8.07-8.84 6.15-3.65 9.8-3.84 113-3.84 103.21 0 106.86.19 113.02 3.84zm157.01 167.59c22.49 7.69 38.25 17.68 56.7 36.13 18.26 18.07 28.83 35.75 36.9 61.5l4.42 14.41v376.68l-4.42 14.41c-8.07 25.75-18.64 43.44-36.9 61.5-18.45 18.45-34.21 28.44-56.7 36.13l-15.37 5.19H245.36l-15.38-5.19c-22.49-7.69-38.24-17.68-56.69-36.13-18.26-18.06-28.83-35.75-36.9-61.5l-4.42-14.41V333.69l4.42-14.41c15.95-50.74 52.85-86.87 102.44-99.94 13.84-3.46 22.1-3.65 265.02-3.27l250.8.39 15.37 5.19zm-64.77 689.94-.58 40.93H295.32l-.58-40.93-.39-40.74H705.64l-.39 40.74z"/>
      <path d="M363.93 421.71c-38.25 14.03-35.55 68.04 4.04 78.6 24.22 6.34 49.39-13.84 49.39-39.78-.19-28.06-27.67-48.04-53.43-38.82zM609.93 421.71c-30.36 11.15-37.09 50.74-11.92 70.73 33.06 26.33 79.37-10.57 61.69-49.39-8.26-18.26-31.13-28.07-49.77-21.34zM358.55 615.05c-3.65 2.31-7.11 6.53-8.26 10.38-3.27 9.42.58 17.49 13.45 29.02 76.3 68.61 196.22 68.61 272.52 0 15.95-14.22 18.64-25.56 8.84-36.13-9.42-10.19-19.03-9.03-34.02 3.84-32.29 27.87-62.84 40.36-103.39 42.47-25.75 1.35-46.12-1.92-68.8-11.15-15.95-6.34-36.9-19.22-49.01-30.17-14.03-12.87-20.76-14.8-31.33-8.26z"/>
    </svg>
  )
}

export default IconMinifig
