import React, {useContext} from 'react';
import {Formik, Field} from 'formik';
import {useQuery, useMutation, gql} from '@apollo/client';
import {push} from 'connected-react-router'
import {connect, ReactReduxContext} from 'react-redux'
import {ImageWidget} from "../../components/ImageWidget";
import {BrickLinkImageType} from '../../components/BrickLinkImageSrc';
import {AllegroButtonNewSet} from "../../components/AllegroButton";
import BricklinkButtonSet from "../../components/BricklinkButton";
import PromoklockiButton from "../../components/PromoklockiButton";

const ADD_SET = gql`
    mutation addSetToInventory($number: String!, $condition: String!, $container: Int, $storage: Int, $notes: String, $purchase_price: String, $estimated_price: String) {
        addSetToInventory(
            number: $number
            condition: $condition
            container: $container
            storage: $storage
            notes: $notes
            purchase_price: $purchase_price
            estimated_price: $estimated_price
        ){
            id,
            status,
            condition
        }
    }`;


const Create = (props) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {storages, containers} = state;

    const [addSet, {loading: mutationLoading, error: mutationError}] = useMutation(ADD_SET, {
        onCompleted: ({addSetToInventory}) => {
            if (addSetToInventory.condition === 'new') {
                props.push('/sets/new');
            } else {
                props.push('/set/print/' + addSetToInventory.id);
            }
        }
    });

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    let _set_num = new URLSearchParams(props.location.search).get("set_num") || '';
    let _condition = new URLSearchParams(props.location.search).get("condition") || 'new';

    return (
        <div className={"form"}>

            <Formik
                initialValues={{number: _set_num, condition: _condition}}
                validate={values => {
                    const errors = {};
                    if (!values.number) {
                        errors.number = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {

                    console.log(values);
                    addSet({
                        variables: values
                    });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    {values.number ?
                                        <ImageWidget set={values.number} type={BrickLinkImageType.set}/> : null}
                                </div>
                            </li>
                            <li>
                                <label htmlFor="number">Set Number</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="number"
                                />
                                {errors.number && touched.number && errors.number}
                            </li>
                            <li>
                                <label htmlFor="condition">Condition</label>
                            </li>
                            <li>
                                <Field as="select" name="condition" defaultValue={_condition}>
                                    <option value="new">New (brand new)</option>
                                    <option value="used">Used (for reconstruction)</option>
                                </Field>
                            </li>
                            {errors.condition && touched.condition && errors.condition}

                            <li>
                                <label htmlFor="condition">Orgins</label>
                            </li>
                            <li>
                                <Field as="select" name="container">
                                    <option value={0}/>
                                    {containers.filter((node) => node != null).map((node) => (
                                        <option value={node.id} key={node.id}>{node.name}</option>
                                    ))}
                                </Field>
                            </li>

                            <li>
                                <label htmlFor="condition">Storages</label>
                            </li>
                            <li>
                                <Field as="select" name="storage">
                                    <option value={0}/>
                                    {storages.filter((node) => node != null).map((node) => (
                                        <option value={node.id} key={node.id}>{node.name}</option>
                                    ))}
                                </Field>
                            </li>

                            <li>
                                <label htmlFor={"notes"}>Notes</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                            </li>

                            <li>
                                <label htmlFor={"purchase_price"}>Purchase price</label>
                            </li>
                            <li>
                                <Field type={"number"} as={"input"} name={"purchase_price"}/>
                            </li>

                            <li>
                                <label htmlFor={"estimated_price"}>
                                    Estimate price
                                </label>
                            </li>
                            <li>
                                <Field type={"number"} as={"input"} name={"estimated_price"}/>
                            </li>
                            <li>
                                <label>&nbsp;</label>
                            </li>
                            <li>
                                <AllegroButtonNewSet set={values.number}/>
                                &nbsp;
                                <BricklinkButtonSet set={values.number}/>
                                &nbsp;
                                <PromoklockiButton set={values.number}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Create
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Create)
