import React, {useContext} from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import {connect, ReactReduxContext} from 'react-redux'
import {ImageWidget} from "../../../components/ImageWidget";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";

const ADD_SET = gql`
    mutation addMinifigToInventory($number: String!, $condition: String!, $storage: Int, $notes: String) {
        addMinifigToInventory(
            number: $number
            condition: $condition
            storage: $storage
            notes: $notes
        ){
            id,
            status,
            condition
        }
    }`;

const Create = (props) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {storages} = state;

    const [addSet, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(ADD_SET);

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    if(mutationData){
        console.log(mutationData);
        if(mutationData.addMinifigToInventory.condition === 'new'){
            props.push('/inventory/minifigs');
        } else {
            props.push('/set/inventory/' + mutationData.addMinifigToInventory.id);
        }
    }

    let _set_num = new URLSearchParams(props.location.search).get("set_num") || '';
    let _condition = new URLSearchParams(props.location.search).get("condition") || 'used';

    // let {name, number, notes} = data.set;
    return (
        <div className={"sets form"}>

            <Formik
                initialValues={{number: _set_num, condition: _condition}}
                validate={values => {
                    const errors = {};
                    if (!values.number) {
                        errors.number = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    addSet({
                        variables: values
                    });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    {values.number ? <ImageWidget set={values.number} type={BrickLinkImageType.minifig}/> : null}
                                </div>
                            </li>
                            <li>
                                <label htmlFor="number">Minifig Number</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="number"
                                    autocomplete="off"
                                />
                                {errors.number && touched.number && errors.number}
                            </li>
                            <li>
                                <label htmlFor="condition">Condition</label>
                            </li>
                            <li>
                                <Field as="select" name="condition">
                                    <option value="new">New</option>
                                    <option value="used">Used</option>
                                </Field>
                            </li>
                            {errors.condition && touched.condition && errors.condition}

                            <li>
                                <label htmlFor="condition">Storages</label>
                            </li>
                            <li>
                                <Field as="select" name="storage">
                                    <option value/>
                                    {storages.filter((node) => node != null).map((node) => (
                                        <option value={node.id} key={node.id}>{node.name}</option>
                                    ))}
                                </Field>
                            </li>

                            <li>
                                <label htmlFor={"notes"}>Notes</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Create
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Create)
