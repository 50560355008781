import React from 'react'
import CustomButton from './CustomButton';

const PromoklockiButton = ({ set, name = 'Promoklocki', title = 'Look in promoklocki'}) => {

  const _set = set.replace("-1", "");

  const url = `https://promoklocki.pl/${_set}`
  return (
    <CustomButton className="link-bricklink" url={url} title={title} name={name} />
  )
}

export default PromoklockiButton
