import React from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

const GET = gql`
    query setting($id: ID) {
        setting(id: $id){
            id,
            key,
            value
        }
    }`;

const UPDATE = gql`
    mutation updateSetting($id: ID!, $key: String!, $value: String) {
        updateSetting(
            id: $id,
            key: $key, 
            value: $value
        ){
            id,
            key,
            value
        }
    }`;


const Update = (props) => {

    console.log(props);
    let id = props.match.params.id;
    const { loading, error, data } = useQuery(GET,
        {
            variables: { id }
        });

    const [updateSetting, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(UPDATE);

    if (loading) return <p>download...</p>;
    if (error) return <p>Error :(</p>;

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    console.log(data);
    console.log(mutationData);

    if(mutationData){
        props.push('/settings');
    }

    return (
        <div className={"containers form"}>

            <Formik
                initialValues={data.setting}
                validate={values => {
                    const errors = {};
                    if (!values.key) {
                        errors.key = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    updateSetting({
                        variables: values
                    }).then(r => console.log('result: ' + r));
                    // addContainer({
                    //     variables: values
                    // });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Field type="hidden" name="id" />
                        <ul>
                            <li>
                                <label htmlFor="key">Key</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="key"
                                />
                                {errors.key && touched.key && errors.key}
                            </li>

                            <li>
                                <label htmlFor={"value"}>value</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"value"} rows={"5"}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Update
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Update)
