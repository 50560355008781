import ImageWidget from "./components/ImageWidget";
import {BrickLinkImageType} from "./components/BrickLinkImageSrc";
import React from "react";
import IconBrick from "./assets/icon-brick.svg";
import IconMinifig from "./assets/icon-minifig.svg";
// import {BrickLinkImageType} from "./BrickLinkImageSrc";
// import ImageWidget from "./ImageWidget";

const FoundInWanted = ({node, onMoveToSet, onClose}) => {
    return (
        <div className={"found-in-wanted"}>
            <div className={"source"}>
                <div className={"part"}>
                    <ImageWidget color={node.part.col_id} set={node.part.part_num}
                                 type={BrickLinkImageType.part} colorBorder={false}/>
                </div>
                <h1>Part {node.part.part_num} fouded in inventory, in
                    quantity {node.part.quantity}, needed in sets:</h1>
                <div className={"close-btn"} onClick={() => {
                    onClose();
                }}>close
                </div>
            </div>
            <div className={"sets"}>
                {node.sets ? node.sets.map((item) => {
                    const json = JSON.parse(item.notes);
                    const qty = node.part.quantity > (json.expected - json.quantity) ? (json.expected - json.quantity) : node.part.quantity;
                    return (<div className={"set"} key={item.id}>
                        <div className={"desc"}>
                            <ImageWidget set={item.set_num} type={BrickLinkImageType.set} maxWidth={100}
                                         maxHeight={"80px"} />
                            <b>{item.id} : #{item.hash} {item.set.name} ({item.set.set_num})</b>
                            <small>
                                <div className={"icons"}>
                                    {item.parts ? <div><IconBrick /><br/>{item.parts}</div> : null}
                                    {item.minifigs || item.minifigs_parts ? <div><IconMinifig/><br/>{item.minifigs}<br/>({item.minifigs_parts})</div> : null}
                                </div>
                                <strike>Quantity:</strike> {json.quantity} / <strike>{json.expected}</strike> &nbsp;
                                <strike>Type</strike> {json.type} &nbsp;
                                <strike>Wanted:</strike> {json.wanted ? 'yes' : 'no'}
                            </small>
                            <div className={"btn btn-move"} onClick={() => {
                                console.log('move ' + node.part.id + ' to ' + item.id);
                                let quantity = prompt("Quantity", qty);
                                if (quantity) {
                                    onMoveToSet(node.part.id, item.id, quantity);
                                }
                            }}>Add to set
                            </div>
                            <a href={"/set/inventory/" + item.id} target={"_blank"} className={"btn btn-move"}>Open
                                set</a>
                        </div>
                    </div>);
                }) : null}
            </div>
        </div>
    )
}

export default FoundInWanted;