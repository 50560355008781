import React from 'react'
import CustomButton from './CustomButton';

interface _searchButtonInterface {
  part_num: string|boolean,
  col_id: number|boolean,
  cat_id: number|boolean
  title?: string|undefined
  name?: string|undefined
}

const SearchButton = ({ part_num = false, col_id = false, cat_id = false, title = 'Search', name = 'Search' } : _searchButtonInterface) => {

  let url = '/search?'
  if(part_num){
    url += 'part_num=' + part_num + '&';
  }

  if(col_id){
    url += 'col_id=' + col_id + '&';
  }

  if(cat_id){
    url += 'cat_id=' + cat_id + '&';
  }

  return (
    <CustomButton className="link-search" url={url} title={title} name={name} />
  )
}

export default SearchButton
