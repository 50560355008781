import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {debounce} from "../functions";
import Spotlight from "../components/Spotlight";

const NavBar = () => {

    const [menuIsOpened, setMenuOpened] = useState(false);
    const [isLocalhost, setLocalhost] = useState(false);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    let navClass = 'nav-menu ';
    if(menuIsOpened) navClass +=  "mobile-opened ";

    // new function:
    const handleScroll = debounce(() => {
        // console.log('scroll');
        // find current scroll position
        const currentScrollPos = window.pageYOffset;
        // console.log(prevScrollPos);
        // console.log(currentScrollPos);

        // set state based on location info (explained in more detail below)
        // setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
        if(currentScrollPos > (prevScrollPos + 30) && currentScrollPos > 200){
            setVisible(false)
            setMenuOpened(false);
        } else {
            if(currentScrollPos + 30 < prevScrollPos){
                setVisible(true);
            }
        }

        // set state to new scroll position
        setPrevScrollPos(currentScrollPos);
    }, 10);

    // new useEffect:
    useEffect(() => {
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
            setLocalhost(true);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, visible, handleScroll]);

    return (
        <nav className={isLocalhost ? "localhost global" : "global"} style={{top: visible ? 0 : -60}}>
            {visible ? <Spotlight /> : null}
            <div className={"hamburger"}>
                <button className="toggle" onClick={() => { setMenuOpened(!menuIsOpened) }}>{menuIsOpened ? "Close" : "Menu"}</button>
            </div>
            <div className={navClass}>
                <ul>
                    <li className={"section"}><b>Discovery:</b></li>
                    <li><Link to="/discovery/minifigs" onClick={() => {setMenuOpened(false)}}>Minifigs</Link></li>
                    <li><Link to="/discovery/sets_by_parts" onClick={() => {setMenuOpened(false)}}>Sets by parts</Link></li>
                    <li><Link to="/discovery/sets_by_minifigs" onClick={() => {setMenuOpened(false)}}>Sets by minifigs</Link></li>
                </ul>
                <ul>
                    <li className={'section'}>Other</li>
                    <li><Link to="/settings" onClick={() => {setMenuOpened(false)}}>Settings</Link></li>
                    <li><Link to="/album" onClick={() => {setMenuOpened(false)}}>Album</Link></li>
                    <li><Link to="/game" onClick={() => {setMenuOpened(false)}}>Game</Link></li>
                </ul>
                <ul>
                    <li className={'section'}><b>Missing:</b></li>
                    <li><Link to="/inventory/wanted/parts/list" onClick={() => {setMenuOpened(false)}}>Wanted Parts</Link></li>
                    <li><hr/></li>
                    <li><Link to="/sets/missing_parts" onClick={() => {setMenuOpened(false)}}>Missing parts</Link></li>
                    <li><Link to="/sets/instructions" onClick={() => {setMenuOpened(false)}}>Missing instructions</Link></li>
                    <li><Link to="/sets/box" onClick={() => {setMenuOpened(false)}}>Missing Box</Link></li>
                </ul>
                <ul>
                    <li className={'section'}><b>Inventory</b></li>
                    <li><Link to="/search" onClick={() => {setMenuOpened(false)}}>Search</Link></li>
                    <li><hr/></li>
                    <li><Link to="/inventory/parts" onClick={() => {setMenuOpened(false)}}>Parts</Link></li>
                    <li><Link to="/inventory/decorations" onClick={() => {setMenuOpened(false)}}>Decoration</Link></li>
                    <li><hr/></li>
                    <li><Link to="/set/create" onClick={() => {setMenuOpened(false)}}><b>Add set</b></Link></li>
                    <li><Link to="/inventory/minifig/create" onClick={() => {setMenuOpened(false)}}><b>Add minifig</b></Link></li>
                    <li><Link to="/inventory/part/create" onClick={() => {setMenuOpened(false)}}><b>Add part</b></Link></li>
                </ul>
                <ul>
                    <li className={'section'}><b>Inventory:</b></li>
                    <li><Link to="/containers" onClick={() => {setMenuOpened(false)}}>Container</Link></li>
                    <li><Link to="/storages" onClick={() => {setMenuOpened(false)}}>Storages</Link></li>
                    <li><hr/></li>
                    <li><Link to="/sets/new" onClick={() => {setMenuOpened(false)}}>New sets</Link></li>
                    <li><hr/></li>
                    <li><Link to="/sets/used" onClick={() => {setMenuOpened(false)}}>Used sets</Link></li>
                    <li><Link to="/sets/ready_to_sell" onClick={() => {setMenuOpened(false)}}>Ready to sell</Link></li>
                    <li><Link to="/sets/on_sale" onClick={() => {setMenuOpened(false)}}>On sale</Link></li>
                    <li><hr/></li>
                    <li><Link to="/minifigs" onClick={() => {setMenuOpened(false)}}>Minifigs</Link></li>
                    <li><Link to="/minifigs/on_sale" onClick={() => {setMenuOpened(false)}}>On sale</Link></li>
                    <li><Link to="/minifigs/ready_to_sell" onClick={() => {setMenuOpened(false)}}>Ready to sell</Link></li>
                    <li><hr/></li>
                    <li><Link to="/sets/sold" onClick={() => {setMenuOpened(false)}}>Sold sets</Link></li>

                    <li><Link to="/inventory/minifigs" onClick={() => {setMenuOpened(false)}}>Minifigs (old)</Link></li>
                    <li><hr/></li>
                    <li><Link to="/user/instructions" onClick={() => {setMenuOpened(false)}}>Instructions</Link></li>
                </ul>
            </div>
        </nav>

    )
}

export default NavBar
