import {BrickLinkImageType} from "../BrickLinkImageSrc";
import BricklinkButtonSet from "../BricklinkButton";
import SearchButton from "../SearchButton";
import {CategoryTag} from "../CategoryTag";
import InventoryPartActions from "../InventoryPartActions";
import InventorySetsActions from "../InventorySetsActions";
import {Link} from "react-router-dom";
import React from "react";
import { ImageWidget } from "../ImageWidget"
import InventoryWantedAction from "../InventoryWantedAction";
import InventoryQuantityAction from "../InventoryQuantityAction";

const SetInventoryItemLine = ({ node, colors, updateInventory = true, withWanted = true }) => {
    const num = node.type === BrickLinkImageType.part ? node.part_num : node.minifig_num
    return (

            <ul>
                <li className="image" style={{ flexBasis: '120px', textAlign: 'center' }}>
                    <ImageWidget set={num} color={node.col_id} type={node.type} maxHeight="60px" />
                    <BricklinkButtonSet name={num} set={num} color={node.col_id} type={node.type} />
                    <br />
                    {node.type === BrickLinkImageType.part ? <SearchButton part_num={node.part_num} cat_id={node.cat_id} /> : null}
                </li>
                <li className="tiny" style={{ flexBasis: '120px' }}>
                    <InventoryQuantityAction partId={node.id.toString()} quantity={node.quantity} expected={node.expected} updateInventory />
                </li>
                <li className="description">
                    <b>{colors[node.col_id].name}</b> / {node.name}
                    <br />
                    <CategoryTag cat_id={node.cat_id} />
                </li>
                <li className="wanted">
                    {withWanted ? <InventoryWantedAction partId={node.id.toString()} wanted={node.wanted} /> : null}
                    {node.type === BrickLinkImageType.part ? (
                        <InventoryPartActions
                            part_num={node.part_num.toString()}
                            col_id={node.col_id}
                            quantity={node.user_inventory}
                        />
                    ) : null}
                    {node.type === BrickLinkImageType.minifig ? <InventorySetsActions set_num={node.minifig_num.toString()} user_sets={node.user_sets} user_sets_inventory={node.user_sets_inventory} /> : null}
                    {node.user_set_inventory
                        ? (
                            <Link className={"button blue"} target={"_blank"} to={`/user/sets/contain/${node.part_num}/${node.col_id}`}>
                                {node.user_set_inventory} items in other sets
                            </Link>
                        ) : null}
                </li>
            </ul>

    )
}

export default SetInventoryItemLine