import React from 'react'
import CustomButton from './CustomButton';

const AllegroButtonMinifig = ({ set }) => {
  const url = `https://allegro.pl/kategoria/klocki-lego-17865?string=${set}&order=p&bmatch=dict210105-ctx-bab-1-5-0112`
  return (
    <CustomButton className="link-allegro" url={url} title={set} name="Allegro" />
  )
}

const AllegroButtonNewSet = ({ set }) => {
  const _set = set.substring(0, set.indexOf('-'))
  const url = `https://allegro.pl/kategoria/lego-zestawy-305601?string=${_set}&stan=nowe&order=p&bmatch=dict210105-ctx-bab-1-5-0112`
  return (
    <CustomButton className="link-allegro" url={url} title={set} name="Allegro" />
  )
}

const AllegroButtonUsedSet = ({ set }) => {
  const _set = set.substring(0, set.indexOf('-'))
  const url = `https://allegro.pl/kategoria/lego-zestawy-305601?string=${_set}&stan=używane&order=p&bmatch=dict210105-ctx-bab-1-5-0112`
  return (
    <CustomButton className="link-allegro" url={url} title={_set} name="Allegro" />
  )
}

export { AllegroButtonUsedSet, AllegroButtonMinifig, AllegroButtonNewSet }
