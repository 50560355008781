import {combineReducers} from 'redux'
import {connectRouter, RouterState} from 'connected-react-router'
import {
    categoriesState,
    getCategoriesAction,

    colorsState,
    getColorsAction,

    userSetsState,
    userSetsAction,

    storagesState,
    getStoragesAction,

    containersState,
    getContainersAction

} from "../types";
import {History} from "history";
import * as actionType from "../store/actionTypes";

// const initialUsesState: userSetsState = {
//     sets: [],
// }

const initialCategoriesState: categoriesState = JSON.parse(localStorage.getItem("categories") || "{}"); //.filter((node: any) => { return node !== null });
const initialColorsState: colorsState = JSON.parse(localStorage.getItem("colors") || "{}"); //.filter((node: any) => { return node !== null });
const initialStoragesState: storagesState = JSON.parse(localStorage.getItem("storages") || "{}"); //.filter((node: any) => { return node !== null });
const initialContainersState: containersState = JSON.parse(localStorage.getItem("containers") || "{}"); //.filter((node: any) => { return node !== null });

// const userSetsReducer = (
//     state: userSetsState = initialUsesState,
//     action: userSetsAction
// ): userSetsState => {
//     switch (action.type) {
//         case actionType.UPDATE_USER_SETS: {
//             return {
//                 ...state,
//                 sets: state.sets
//             }
//         }
//     }
//
//     return state;
// }

const categoriesReducer = (
    state: categoriesState = initialCategoriesState,
    action: getCategoriesAction
): categoriesState => {
    // console.log('categoriesReducer');
    switch (action.type) {
        case actionType.GET_CATEGORIES: {
            // console.log(action.payload)
            let categories: never[] = [];
            for (let i = 0; i < action.payload.length; i++) {
                // @ts-ignore
                categories[action.payload[i].cat_id] = action.payload[i];
            }

            localStorage.setItem("categories", JSON.stringify(categories));

            // @ts-ignore
            return categories;
        }
    }

    return state;
}

const colorsReducer = (
    state: colorsState = initialColorsState,
    action: getColorsAction
): colorsState => {
    switch (action.type) {
        case actionType.GET_COLORS: {
            let colors: never[] = [];
            // console.log(action.payload)
            for (let i = 0; i < action.payload.length; i++) {
                // @ts-ignore
                colors[action.payload[i].col_id] = action.payload[i];
            }

            localStorage.setItem("colors", JSON.stringify(colors));

            // @ts-ignore
            return colors;
        }
    }
    return state;
}

const storagesReducer = (
    state: storagesState = initialStoragesState,
    action: getStoragesAction
): storagesState => {
    switch (action.type) {
        case actionType.GET_STORAGES: {
            let storages: never[] = [];
            // console.log(action.payload)
            for (let i = 0; i < action.payload.length; i++) {
                // @ts-ignore
                storages[action.payload[i].id] = action.payload[i];
            }

            localStorage.setItem("storages", JSON.stringify(storages));

            // @ts-ignore
            return storages;
        }
    }
    return state;
}

const containersReducer = (
    state: containersState = initialContainersState,
    action: getContainersAction
): containersState => {
    switch (action.type) {
        case actionType.GET_CONTAINERS: {
            let containers: never[] = [];
            // console.log(action.payload)
            for (let i = 0; i < action.payload.length; i++) {
                // @ts-ignore
                containers[action.payload[i].id] = action.payload[i];
            }

            localStorage.setItem("containers", JSON.stringify(containers));

            // @ts-ignore
            return containers;
        }
    }
    return state;
}

interface StoreEnhancerState {
}

export interface RootState extends StoreEnhancerState {
    router: RouterState,
    // sets: userSetsState,
    categories: categoriesState,
    colors: colorsState,
    storages: storagesState,
    containers: containersState
}

const rootReducer = (history: History) => combineReducers<RootState>({
    router: connectRouter(history),
    // sets: userSetsReducer,
    categories: categoriesReducer,
    colors: colorsReducer,
    storages: storagesReducer,
    containers: containersReducer
})

export default rootReducer
