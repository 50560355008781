import React, {useEffect, useState} from 'react';
import {_delete, _get} from "../../../functions";

import {useForm, useWatch} from "react-hook-form";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";
import ImageWidget from "../../../components/ImageWidget";
import {useHistory} from "react-router-dom";

interface FormInputs {
    set_num: string;
    quantity: number;
    expected: number;
}

const Delete = (props: any) => {

    const history = useHistory();
    const [isSubmitting, setSubmit] = useState(false);
    const {register, control, handleSubmit, formState: {errors}, reset} = useForm<FormInputs>();
    const set_num = useWatch({
        control,
        name: "set_num",
        defaultValue: ""
    });

    useEffect(() => {
        let id = props.match.params.id;
        _get('user/instructions/' + id, {}, false).then((data) => {
            reset(data);
        })
    }, []);

    const onSubmit = (data: FormInputs) => {
        setSubmit(true);
        let id = props.match.params.id;
        _delete('user/instructions/' + id, {}, true, 'Removing...', 'Removed').then((data) => {
            setSubmit(false);
            history.push('/user/instructions');
        })
    };

    return (
        <div className={"containers form"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ul>

                    <li>Preview:</li>
                    <li>
                        <div style={{maxWidth: 250}}>
                            {set_num.includes('-1') ?
                                <ImageWidget set={set_num} type={BrickLinkImageType.instruction}/> : null}
                        </div>
                    </li>

                    <li>Set num:</li>
                    <li>
                        <input {...register("set_num")} disabled={true}/>
                        {errors.set_num && <span className={'form-error'}>This field is required</span>}
                    </li>

                    <li>
                        <button type="submit" disabled={isSubmitting}>
                            Remove
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    )
}

export default Delete
