import React, { useState } from 'react'
import { apiPost } from '../functions'

const InventoryWantedAction = ({ partId, wanted = false }) => {
  const [isWanted, setWanted] = useState(wanted)

  const update = (variables) => {
    apiPost(`user/set/inventory/wanted/${partId}`, variables, 'true', 'Wanted...', 'Done')
      .then((data) => {
        setWanted(!!data.wanted)
      })
  }

  return (
    <div className="inventory-wanted-actions">
      <input
        type="checkbox"
        name={`wanted['${partId.toString()}']`}
        value={wanted}
        checked={isWanted}
        onChange={(e) => {
          update({
            wanted: e.target.checked ? '1' : '0',
          })
        }}
      />
      Wanted
    </div>
  )
}

export default InventoryWantedAction
