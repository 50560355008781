import {_userSetInventory} from "../types";
import {BrickLinkImageType} from "./BrickLinkImageSrc";

const ItemProps = (node: _userSetInventory) => {

  let num = node.part_num ? node.part_num : node.minifig_num;
  let type = node.part_num ? BrickLinkImageType.part : BrickLinkImageType.minifig;

  return {
    type: type,
    num: num,
    color: node.col_id,
    expected: node.expected,
    quantity: node.quantity
  }
};

export default ItemProps;
