import React, {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {Formik} from "formik";
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import {Link} from 'react-router-dom'
import QRCode from "react-qr-code";

const USER_SET_INVENTORY = gql`
    query userSetInventory($id: ID){
        userSetInventory(user_set_id: $id){
            id
            part {
                part_num
                name
                category {
                    cat_id
                    name
                }
            }
            part_num
            minifig {
                set_num
                name
                parts
                type
            }
            minifig_num
            col_id
            color {
                col_id
                name
            }
            notes
            quantity
            expected
            type
        }
    }`;
const USER_SET = gql`
    query userSet($id: ID){
        userSet(id: $id){
            id
            hash
            set_num
            notes
            condition,
            instruction,
            box,
            completeness,
            container_id,
            purchase_price,
            estimated_price
            parts
            minifigs
            minifigs_parts
            set {
                set_num
                parts
                minifigs
                name
                type
            }
        }
    }
`;

const List = ({filter, entries, emptyPrint, onlyMissingParts, title}) => {
    let items = entries.filter(node => {
        let on = onlyMissingParts ? node.expected !== node.quantity : true;
        return node.type === filter && on
    })
    return <ol>{title && items.length ? <h2>{title}</h2> : null}{items.map(node => {
        const name = node.part ? node.color.name + ", " + node.part.name : node.minifig.name;
        const quantity = node.quantity && emptyPrint ? node.quantity : "...";
        const className = node.quantity !== node.expected && !onlyMissingParts && emptyPrint ? 'item red' : 'item';
        return (
            <li className={className}>
                {node.part_num ?
                    <ImageWidget color={node.col_id} set={node.part_num} type={BrickLinkImageType.part}
                                 maxHeight={'100px'} maxWidth={'100%'}/>
                    :
                    <ImageWidget color={node.col_id} set={node.minifig_num} type={BrickLinkImageType.minifig}
                                 maxHeight={'100px'} maxWidth={'100%'}/>
                }
                <div className={"num"}>{quantity} / {node.expected}</div>
                <div className={"name"}>{name.split(",")[0]}</div>
            </li>
        )
    })}</ol>

}

const Inventory = (props) => {


    const [emptyPrint, setEmptyPrint] = useState(false);
    const [onlyMissingParts, setOnlyMissingParts] = useState(false);
    const [hideAllParts, setHideAllParts] = useState(true);

    const [hideImage, setHideImage] = useState(false);
    const [hideQR, setHideQR] = useState(false);

    // console.log(props);
    let id = props.match.params.id;

    const {data: inventoryData, loading: inventoryLoading} = useQuery(USER_SET_INVENTORY, {
        variables: {id},
        onCompleted: ({data}) => {
            setTimeout(() => {
                window.print();
            }, 1000);
        }
    });

    const {data, loading, error} = useQuery(USER_SET, {
        variables: {id}
    })

    // console.log(inventoryData);
    // console.log(data);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if (!inventoryData) return <p>Loading inventory...</p>;

    let sortedInventoryData = false;
    if (sortedInventoryData === false && inventoryLoading === false && inventoryData) {
        // REMEMBER: Sort by category, defaulty is sorted by color
        sortedInventoryData = inventoryData.userSetInventory.slice(); /*.sort(function (a, b) {
            if (a.part && b.part) {

                // alphabetic
                if (a.part.category.name < b.part.category.name) {
                    return -1;
                }
                if (a.part.category.name > b.part.category.name) {
                    return 1;
                }
                return 0;

                // by id
                // return parseInt(a.part.category.cat_id) - parseInt(b.part.category.cat_id);
            }
            return 1;
        })*/
    }

    // let additionalTitle = '#' + data.notes;
    // const image = BrickLinkImageSrc(data.userSet.set_num, BrickLinkImageType.set);

    const fullSet = !(data.userSet.parts > 0 || data.userSet.minifigs > 0 || data.userSet.minifigs_parts > 0);

    console.log(fullSet);
    return (
        <div>
            <div className={"no-print"}>
                <nav className={"second-line"}>
                    <div class={"grid"}>
                        <div className={"grid-start-6 grid-end-7"}>
                            <label style={{float: 'none', width: 'auto'}}>
                                <input type={"checkbox"} name={"hideImage"} checked={hideImage} onChange={() => {
                                    setHideImage(!hideImage)
                                }}/> Hide image
                            </label>
                        </div>
                        <div className={"grid-start-7 grid-end-8"}>
                            <label style={{float: 'none', width: 'auto'}}>
                                <input type={"checkbox"} name={"hideQR"} checked={hideQR} onChange={() => {
                                    setHideQR(!hideQR)
                                }}/> Hide QR Code
                            </label>
                        </div>
                        <div className={"grid-start-8 grid-end-9"}>
                            <label style={{float: 'none', width: 'auto'}}>
                                <input type={"checkbox"} name={"emptyPrint"} checked={hideAllParts} onChange={() => {
                                    setHideAllParts(!hideAllParts)
                                }}/> Hide all parts
                            </label>
                        </div>
                        <div className={"grid-start-9 grid-end-10"}>
                            <label style={{float: 'none', width: 'auto'}}>
                                <input type={"checkbox"} name={"emptyPrint"} checked={emptyPrint} onChange={() => {
                                    setEmptyPrint(!emptyPrint)
                                }}/> Parts quantity
                            </label>
                        </div>
                        <div className={"grid-start-10 grid-end-11"}>
                            <label style={{float: 'none', width: 'auto'}}>
                                <input type={"checkbox"} name={"onlyMissingParts"} checked={onlyMissingParts}
                                       onChange={() => {
                                           setOnlyMissingParts(!onlyMissingParts);
                                       }}/> Only missing
                            </label>
                        </div>
                        <div className={"grid-start-11 grid-end"}>
                            <Link to={"/set/inventory/" + id} className={"button"}>Open set</Link>
                        </div>
                    </div>
                </nav>
            </div>

            <div style={{float: 'left', width: '100%'}}>
                {hideImage ? null :
                    <div style={{float: 'left', width: '20%', marginRight: '5%'}}>
                        <ImageWidget set={data.userSet.set_num} type={data.userSet.set.type}/>
                    </div>
                }
                <div style={{float: 'left', width: '75%', position: 'relative'}}>
                    <h1 className={"big"}><span style={{color: 'black'}}>{id.slice(0, -1)}</span><span
                        style={{color: 'black', textDecoration: 'underline'}}>{id[id.length - 1]}</span></h1>
                    <h2>
                        <span style={{color: 'grey'}}>{data.userSet.set.set_num}</span><br/>
                        <span style={{color: 'darkgrey'}}>{data.userSet.set.name}</span>
                    </h2>
                    {hideQR ? null :
                        <div>
                    <div style={{
                        position: 'absolute',
                        top: '100px',
                        right: '165px',
                        fontSize: '24px',
                        color: 'darkgrey',
                        transform: 'rotateZ(-90deg)'
                    }}>Instrukcja
                    </div>
                    <div>
                        <div style={{
                            position: 'absolute',
                            top: '20px',
                            right: 0,
                            width: '170px',
                            height: 'auto',
                            borderRadius: '15px',
                            border: '3px solid darkgrey',
                            padding: '10px'
                        }}>
                            <QRCode
                                value={'https://www.lego.com/pl-pl/service/buildinginstructions/' + data.userSet.set.set_num.slice(0, -2)}
                                level={"L"} size={170}/>
                        </div>

                    </div>
                        </div>
                    }
                    {false ? <div className={"notes"}>
                        <b>#{data.userSet.hash}</b><br/>{data.userSet.notes}
                    </div> : null}
                </div>
            </div>
            {false ?
                <div style={{float: 'left', width: '100%'}}>
                    <div style={{float: 'left', width: '100%'}} className={"print"}>
                        <div className={"line"}>
                            <div className={fullSet ? "point full-set" : 'point'}>100%</div>
                            <div className={"point"}>Build</div>
                            <div className={"point"}>Verified</div>
                            <div className={"point"}>Photographed</div>
                            <div className={"point"}>On sale</div>
                        </div>
                    </div>
                </div> : null}
            {!hideAllParts ?
                <div className={"print"} style={{float: 'left', width: '100%'}}>
                    <Formik
                        initialValues={data.userSet}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            console.log(values);

                            setSubmitting(false);
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <List filter={"regular"} entries={sortedInventoryData} emptyPrint={emptyPrint}
                                      onlyMissingParts={onlyMissingParts}/>
                                <List title={"Minifig"} filter={'minifig'} entries={sortedInventoryData}
                                      emptyPrint={emptyPrint} onlyMissingParts={onlyMissingParts}/>
                                <List title={"Minifig parts"} filter={'minifig-part'} entries={sortedInventoryData}
                                      emptyPrint={emptyPrint} onlyMissingParts={onlyMissingParts}/>
                                <List title={"Counterparts"} filter={'counterpart'} entries={sortedInventoryData}
                                      emptyPrint={emptyPrint} onlyMissingParts={onlyMissingParts}/>
                                <List title={"Decoration"} filter={'decorated'} entries={sortedInventoryData}
                                      emptyPrint={emptyPrint} onlyMissingParts={onlyMissingParts}/>
                            </form>
                        )}
                    </Formik>
                </div> : <></>}
        </div>
    )
}

export default Inventory
