import React, {useContext} from 'react';
import {ReactReduxContext} from "react-redux";

const CategoryTag = ({ name, cat_id = 0 }) => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState();
  const { categories } = state;
  const _name = name || categories[cat_id].name
  return (
    <a href={`/inventory/search?cat_id=${cat_id}&col_id=0`} className="link-category">{_name}</a>
  )
}

export {CategoryTag};
