import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {_get} from "../../../functions";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";
import ImageWidget from "../../../components/ImageWidget";
import LoadingPage from "../../../components/LoadingPage";
import EmptyPage from "../../../components/EmptyPage";

// @ts-ignore
const List = ({entries}) => {
    return entries.map((node: { id: React.Key | null | undefined; set_num: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; notes: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; purchase_price: string; matched: number }) => (
        <div className={"grid table"} key={node.id} style={{backgroundColor: node.matched ? 'lightgreen' : ''}}>
            <div className="grid-start grid-end-1">
                <ImageWidget set={node.set_num} type={BrickLinkImageType.instruction} maxHeight={"60px"}/>
            </div>
            <div className="grid-start-1 grid-end-8 grid-align-left">
                {node.set_num}<br/>
                {node.notes}<br/>
            </div>
            <div className={"grid-start-8 grid-end-9"}>
                {node.matched ? <Link to={'/sets/used?set_num=' + node.set_num}>Show sets ({node.matched})</Link> : null}
            </div>
            <div className={"grid-start-9 grid-end"}>
                <Link to={'/user/instruction/edit/' + node.id}>Edit</Link>
                <Link to={'/user/instruction/delete/' + node.id}>Delete</Link>
                <Link to={'/set/preview/' + node.set_num}>Preview</Link>
            </div>
        </div>
    ));
}

const ContainersList = () => {

    const [instructions, setInstructions] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        _get('user/instructions', [], false).then((data) => {
            setInstructions(data);
            setLoading(false);
        })
    }, []);

    if (loading) {
        return <LoadingPage/>
    }

    if(!instructions.length){
        return <>
            <EmptyPage/>
            <Link className={"standalone-button"} to="/user/instruction/create">Add new instruction</Link>
        </>

    }

    return (
        <div className={"list containers"}>
            <nav className={"second-line"}>
                <div className={"grid"}>
                    <div className={"grid-start grid-end-2 grid-align-left"}>
                        <div className={"nav-title"}>
                            Instructions
                        </div>
                    </div>
                    <div className={"grid-start-10 grid-end"}>
                        <Link className={"button"} to="/user/instruction/create">Add new instruction</Link>
                    </div>
                </div>
            </nav>

            <div className="grid table header">
                <div className="grid-start grid-end-1 grid-align-left">Picture</div>
                <div className="grid-start-1 grid-end-8">Match</div>
                <div className="grid-start-8 grid-end-9">Set</div>
                <div className="grid-start-9 grid-end">Options</div>
            </div>
            <List entries={instructions}/>
        </div>
    )
}

export default ContainersList
