import React, {useContext, useEffect} from 'react';
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client';
import {_get} from "../../functions";
import {actionGetStorages} from "../../store/actionCreators";
import {ReactReduxContext} from "react-redux";

const EXCHANGE_RATES = gql`
    query storages {
        storages(orderBy: {
            field: "id",
            order: DESC
        }){
            id
            name
            notes
        }
    }`;


const List = ({entries}) => {
    console.log(entries);
    return entries.map((node) => (
        <div className={"grid table"} key={node.id}>
            <div className={"grid-start grid-end-10 grid-align-left"}>
                {node.name}
                <small>{node.notes}</small>
            </div>
            <div className={"grid-start-10 grid-end"}>
                <Link to={'/storage/update/' + node.id}>Edit</Link>
                <Link to={'/storage/delete/' + node.id}>Delete</Link>
                <Link to={'/sets/new?storage=' + node.id}>Sets</Link>
            </div>
        </div>
    ));
}

const ContainersList = () => {

    const {store} = useContext(ReactReduxContext);
    const { data, loading, error} = useQuery(EXCHANGE_RATES, {
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        _get('user/storages', {}, false)
            .then(data => {
                // @ts-ignore
                store.dispatch(actionGetStorages(data));
            })
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <div className={"containers list"}>
            <nav className={"second-line"}>
              <div className={"grid"}>
                <div className={"grid-start grid-end-5 nav-title"}>
                    Storage
                </div>
                <div className={"grid-start-11 grid-end"}>
                  <Link className={"button"} to="/storage/create">Add new storage</Link>
                </div>
              </div>
            </nav>
            <div className="grid table header">
                <div className="grid-start grid-end-10 grid-align-left">Name</div>
                <div className="grid-start-10 grid-end">Options</div>
            </div>
            <List entries={data.storages || {}} />
        </div>
    )
}

export default ContainersList
