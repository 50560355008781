import React, { useState } from 'react'
import { _delete, _get, apiPost } from '../functions'
import FoundInWanted from '../FoundInWanted'

const InventoryPartActions = ({part_num, col_id, quantity = 0}) => {
  const [loading, setLoading] = useState(false)

  const [id, setID] = useState(part_num)
  const [qty, setQuantity] = useState(quantity)

  const [foundInWanted, setFoundInWanted] = useState(false)

  const addPart = (variables) => {
    apiPost('user/inventory', variables, 'true', 'Adding...', 'Done')
      .then((data) => {
        getPart()
        if (data.sets.length) {
          setFoundInWanted(data)
        }
      })
  }

  const deletePart = (variables) => {
    _delete('user/inventory', variables, 'true', 'Removing...', 'Done')
      .then((data) => {
          console.log(data);
        getPart()
      })
  }

  const movePart = (variables) => {
    console.log(variables)

    apiPost('user/inventory/move-to-set', variables, true)
      .then((data) => {
        if (data.quantity !== 0) {
          addPart({
            part_num: data.part_num,
            col_id: data.col_id,
            condition: 'used',
            quantity: 0,
            notes: '',
          })
        } else {
          getPart()
        }
      })
  }

  const getPart = () => {
    setLoading(true)
    _get(`user/inventory/${part_num}/${col_id}`, {}, false)
      .then((data) => {
        setQuantity(data.quantity)
        setID(data.id)
        setLoading(false)
      })
  }

  return (
    <div className="inventory-part-actions">

      {foundInWanted ? (
        <FoundInWanted
          node={foundInWanted}
          onMoveToSet={(from, to, qty) => {
            console.log(from)
            console.log(to)
            movePart({
              user_inventory_id: parseInt(from),
              user_set_id: parseInt(to),
              quantity: parseInt(qty),
            })
            setFoundInWanted(false)
          }}
          onClose={() => {
            console.log('found false')
            setFoundInWanted(false)
          }}
        />
      ) : null}

        <div
            className="button inventory-part-remove"
            onClick={() => {
                if(qty > 0) {
                    const q = 1; //prompt('How many elements removed?', 1)
                    if (q) {
                        deletePart({
                            part_num,
                            col_id,
                            quantity: q
                        })
                    }
                }
            }}
        >
            -
        </div>
      <div className="inventory-part-qty">{qty ? qty : '-'}</div>

        <div
            className="button inventory-part-add"
            onClick={() => {
                const q = 1; //prompt('How many elements add?', 1)
                if (q) {
                    addPart({
                        part_num,
                        col_id,
                        condition: 'used',
                        quantity: q,
                        notes: '',
                    })
                }
            }}
        >
            +
        </div>
    </div>
  )
}

export default InventoryPartActions
