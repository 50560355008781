import React from 'react'
import CustomButton from './CustomButton'
import { BrickLinkImageType } from './BrickLinkImageSrc'

const BricklinkButtonSet = ({
  set, color = '11', name = 'Bricklink', title = 'Look in bricklink', type = 'S',
}) => {
  let _set = set
  let _type = null
  if (type === 'S' || type === BrickLinkImageType.set) {
    _type = 'S'
  }

  if (type === BrickLinkImageType.minifig) {
    _type = 'M'
  }

  let url = `https://www.bricklink.com/v2/catalog/catalogitem.page?${_type}=${_set}&loc=PL`
  if (type === 'P' || type === BrickLinkImageType.part) {
    _set = `${set}&color=${color}`
    _type = 'P'
    url = `https://www.bricklink.com/v2/search.page?type=${_type}&q=${_set}&loc=PL&tab=A`
  }

  return (
    <CustomButton className="link-bricklink" url={url} title={title} name={name} />
  )
}

export default BricklinkButtonSet
