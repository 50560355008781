import React from 'react';
import { Link } from 'react-router-dom'
import {useQuery, gql, useMutation} from '@apollo/client';
import {Field, Formik} from "formik";
import {BrickLinkImageSrc, BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";

const USER_SET_INVENTORY = gql`
    query userSetInventory($id: ID){
        userSetInventory(user_set_id: $id){
            id
            part {
                part_num
                name
                category {
                    cat_id
                    name
                }
            }
            part_num
            minifig {
                set_num
                name
                image
                parts
                inventory {
                    part_num
                    part {
                        name
                        part_num
                    }
                    color {
                        col_id
                        name
                    }
                }
            }
            minifig_num
            color {
                col_id
                name
            }
            notes
            quantity
            expected
            type
        }
    }`;
const USER_SET = gql`
    query userSet($id: ID){
        userSet(id: $id){
            id,
            set_num
            notes
            condition,
            instruction,
            box,
            container_id,
            purchase_price,
            estimated_price
            set {
                set_num
                image
                parts
                minifigs
                name
            }
        }
    }
`;
const UPDATE_SET_INVENTORY = gql`
    mutation updateUserSetInventory($input: inputUpdateUserSetInventory){
        updateUserSetInventory(input: $input){
            id
        }
    }
`;

const Options = ({max,id}) => {
    let content = [];
    for (let i = 0; i <= max; i++) {
        content.push(<option key={id + "-" + i} value={i}>{i}</option>);
    }
    return content;
}

const List = ({filter, entries}) => {
    return entries.filter(node => {
        return node.type === filter && node.quantity !== node.expected
    }).map(node => {
        console.log(node.type);

        let imageSet = node.type !== 'minifig' ? node.color.col_id + '/' + node.part.part_num : node.minifig.set_num;
        let imageType = node.type !== 'minifig' ? BrickLinkImageType.part : BrickLinkImageType.minifig;

        let part = node.part ? node.part.part_num : node.minifig.set_num;
        let url = 'https://www.bricklink.com/v2/catalog/catalogitem.page?' + (node.part ? 'P=' : 'M=') + part;

        return (
            <li key={Math.random()}>
                <ul key={node.id.toString()}>
                    <li className={"image"} style={{flexBasis: "120px", textAlign: 'center'}}>
                        <ImageWidget set={imageSet} type={imageType} maxWidth={100} maxHeight={50} transformLeft={'-30px'} />
                        <a style={{fontSize: 12}} href={url} target={"_blank"} rel={"noopener noreferrer"} title={'Open in bricklink'}>{part}</a>
                    </li>
                    <li className={"tiny"} style={{flexBasis: "120px"}}>
                        <Field as="select" name={"parts['" + node.id.toString() + "']"} className="formSetPartQuantity" defaultValue={node.quantity}>
                            <Options max={node.expected} id={node.id}/>
                        </Field><br/>
                        of {node.expected}

                        <br/>
                        <button type="submit" style={{fontSize: '14px', border: '1px solid silver', padding: 3, margin: 0, width: 'auto', backgroundColor: '#fff'}}>
                            save
                        </button>
                    </li>
                    <li className={"description"}>
                        {node.part ? <>{node.color.name} / {node.part.name} - #{node.part.part_num}<br/>{node.part.category.name}</> : null}
                        {node.minifig ? <>{node.minifig.name}</> : null}
                    </li>
                </ul>
            </li>
        )
    });

}

const MissingParts = (props) => {

    // console.log(props);
    let id = props.match.params.id;

    const [updateUserSetInventory, {loading: updateInventoryLoading}] = useMutation(UPDATE_SET_INVENTORY);

    const { data: inventoryData, loading: inventoryLoading } = useQuery(USER_SET_INVENTORY, {
        fetchPolicy: 'cache-and-network',
        variables: {id}
    });

    const { data, loading, error} = useQuery(USER_SET, {
        fetchPolicy: 'cache-and-network',
        variables: {id}
    })

    // console.log(inventoryData);
    // console.log(data);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    if(!inventoryData) return <p>Loading inventory...</p>;

    let sortedInventoryData = false;
    if(sortedInventoryData === false && inventoryLoading === false && inventoryData) {
        // REMEMBER: Sort by category, default is sorted by color
        sortedInventoryData = inventoryData.userSetInventory.slice(); /*.sort(function (a, b) {
            if (a.part && b.part) {

                // alphabetic
                if (a.part.category.name < b.part.category.name) {
                    return -1;
                }
                if (a.part.category.name > b.part.category.name) {
                    return 1;
                }
                return 0;

                // by id
                // return parseInt(a.part.category.cat_id) - parseInt(b.part.category.cat_id);
            }
            return 1;
        })*/
    }

    if(!sortedInventoryData) return <p>Loading inventory...</p>;

    // let image = 'http://graphql.test/images/set/' + data.userSet.set_num;
    let image = BrickLinkImageSrc(data.userSet.set_num, BrickLinkImageType.set);

    return (
        <div className={"sets list"}>

            <nav>
                <Link to={'/set/inventory/' + id}>Inventory</Link>
                &nbsp;|&nbsp;
                <Link to={'/set/print/' + id} target={"_blank"}>Print</Link>
                &nbsp;|&nbsp;
                <Link to={'/set/export/' + id} target={"_blank"}>Export</Link>
            </nav>

            <h1>{data.userSet.set.set_num} : {data.userSet.set.name}</h1>
            <img src={image} width={300} alt={data.userSet.set.name}/>

            {updateInventoryLoading ? <div className={"sending"}>Sending...</div>  : null}

            <Formik
                initialValues={data.userSet}
                validate={values => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    let parts = [];
                    if(values.parts) {
                        for (let index = 0; index < values.parts.length; ++index) {
                            if (values.parts[index] !== undefined) {
                                parts.push({
                                    id: index,
                                    value: parseInt(values.parts[index])
                                });
                            }
                        }
                    }

                    let data = {
                        id: values.id,
                        box: values.box,
                        instruction: values.instruction,
                        notes: values.notes,
                        estimated_price: values.estimated_price,
                        purchase_price: values.purchase_price,
                        parts
                    }

                    updateUserSetInventory({
                        variables: {
                            input: data
                        }
                    });
                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <li>
                            <label htmlFor={"notes"}>Notes</label>
                            <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                        </li>

                        <li>
                            <label htmlFor={"purchase_price"}>Purchase price</label>
                            <Field type={"number"} as={"input"} name={"purchase_price"}/>
                        </li>

                        <li>
                            <label htmlFor={"estimated_price"}>
                                Estimate price
                            </label>
                            <Field type={"number"} as={"input"} name={"estimated_price"}/>
                        </li>
                        <li>
                            <label>&nbsp;</label>
                            <a className={"small_link"} rel="noopener noreferrer" title={"Allegro"} href={"https://allegro.pl/kategoria/zabawki-klocki-11823?string=lego%20" + data.userSet.set.set_num + "&order=p"} target={"_blank"}>Allegro</a>
                            &nbsp;
                            <a className={"small_link"} rel="noopener noreferrer" title={"Blicklink"} href={"https://www.bricklink.com/v2/catalog/catalogitem.page?S="+data.userSet.set.set_num+"-1#T=P"} target={"_blank"}>Bricklink</a>
                        </li>


                        <li>
                            <label htmlFor={"instruction"}>Instruction</label>
                            <Field type={"checkbox"} as={"input"} name={"instruction"}/>
                        </li>


                        <li>
                            <label htmlFor={"box"}>Box</label>
                            <Field type={"checkbox"} as={"input"} name={"box"}/>
                        </li>

                        <h2>Regular items</h2>
                        <ol>
                            <List filter={"regular"} entries={sortedInventoryData}/>
                        </ol>
                        {/*<List prefix={'regularItems'} entries={data.scrapeSetPartsWithSort.regularItems}/>*/}

                        <h2>Minifig</h2>
                        <ol>
                            <List filter={'minifig'} entries={sortedInventoryData}/>
                        </ol>

                        <h2>Minifigs parts</h2>
                        <ol>
                            <List filter={'minifig-part'} entries={sortedInventoryData}/>
                        </ol>

                        <h2>Counterparts</h2>
                        <ol>
                            <List filter={'decorated'} entries={sortedInventoryData}/>
                        </ol>

                        <h2>Gear</h2>
                        <ol>
                            <List filter={'gear'} entries={sortedInventoryData}/>
                        </ol>

                        <h2>Other</h2>
                        <ol>
                            <List filter={'other'} entries={sortedInventoryData}/>
                        </ol>

                        <button type="submit" disabled={isSubmitting}>
                            Save
                        </button>
                    </form>
                )}
            </Formik>
            <div className={"nav"}>
                <Link to="/sets">back</Link>
            </div>

            {/*<img src={data.scrapeSetPartsWithSort.image} width={100}/>*/}
            {/*<h3>Parts {data.scrapeSetPartsWithSort.regularItemsTotalQuantity} Minifig: {data.scrapeSetPartsWithSort.minifigs}</h3>*/}

        </div>
    )
}

export default MissingParts
