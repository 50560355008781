import React from 'react';
import { Formik, Field } from 'formik';
import { useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

const ADD_SET = gql`
    mutation createStorage($name: String!, $notes: String) {
        createStorage(
            name: $name,
            notes: $notes
        ){
            id
        }
    }`;


const Create = (props) => {

    const [createStorage, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(ADD_SET);

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    console.log(mutationData);
    if(mutationData){
        props.push('/storages');
    }

    // let {name, number, notes} = data.set;
    return (
        <div className={"containers form"}>

            <Formik
                initialValues={{name: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    createStorage({
                        variables: values
                    });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li>
                                <label htmlFor="name">Name</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="name"
                                />
                                {errors.name && touched.name && errors.name}
                            </li>

                            <li>
                                <label htmlFor={"notes"}>Notes</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Create
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Create)
