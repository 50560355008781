const BrickLinkImageType = Object.freeze({
  box: 'box', set: 'set', minifig: 'minifig', part: 'part', gear: 'gear', instruction: 'instruction',
});
const BrickLinkImageSrc = (set, color = false, type, big = false) => {
  switch (type) {
    case BrickLinkImageType.box: {
      if (big) {
        return `${process.env.REACT_APP_CDN_BOXS_BIG}${set}${process.env.REACT_APP_IMAGE_BOXS_BIG_FORMAT}`
      }
      return `${process.env.REACT_APP_CDN_BOXS}${set}${process.env.REACT_APP_IMAGE_BOXS_FORMAT}`
    }

    case BrickLinkImageType.set: {
      if (big) {
        return `${process.env.REACT_APP_CDN_SETS_BIG}${set}${process.env.REACT_APP_IMAGE_SETS_BIG_FORMAT}`
      }
      return `${process.env.REACT_APP_CDN_SETS}${set}${process.env.REACT_APP_IMAGE_SETS_FORMAT}`
    }

    case BrickLinkImageType.gear:
    case BrickLinkImageType.part: {
      if (big) {
        return `${process.env.REACT_APP_CDN_PARTS_BIG}${color}/${set}${process.env.REACT_APP_IMAGE_PARTS_BIG_FORMAT}`
      }
      return `${process.env.REACT_APP_CDN_PARTS}${color}/${set}${process.env.REACT_APP_IMAGE_PARTS_FORMAT}`
    }

    case BrickLinkImageType.minifig: {
      if (big) {
        return `${process.env.REACT_APP_CDN_MINIFIGS_BIG}${set}${process.env.REACT_APP_IMAGE_MINIFIGS_BIG_FORMAT}`
      }
      return `${process.env.REACT_APP_CDN_MINIFIGS}${set}${process.env.REACT_APP_IMAGE_MINIFIGS_FORMAT}`
    }

    case BrickLinkImageType.instruction: {
      if (big) {
        return `${process.env.REACT_APP_CDN_INSTRUCTION_BIG}${set}${process.env.REACT_APP_IMAGE_INSTRUCTION_BIG_FORMAT}`
      }
      return `${process.env.REACT_APP_CDN_INSTRUCTION}${set}${process.env.REACT_APP_IMAGE_INSTRUCTION_FORMAT}`
    }

    default: {
      return ''
    }
  }
}

export { BrickLinkImageSrc, BrickLinkImageType }
