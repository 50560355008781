import React from 'react'

export const PageNavigation = (props) => {
  const { children } = props
  return (
    <div className="navigation">{children}</div>
  )
}

export default PageNavigation
