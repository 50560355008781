import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom'
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {BrickLinkImageType} from "../../components/BrickLinkImageSrc";
import {ImageWidget} from "../../components/ImageWidget";
import BricklinkButtonSet from "../../components/BricklinkButton";
import {showErrorNotification, showSendingNotification, showSuccessNotification} from "../../functions";
import {ReactReduxContext} from "react-redux";
import SearchButton from "../../components/SearchButton";
import InventoryPartActions from "../../components/InventoryPartActions";

const EXCHANGE_RATES = gql`
    query parts {
        parts {
            id
            part_num
            part {
                name
                cat_id
            }
            quantity
            col_id
        }
    }`;

const REMOVE_PART = gql`
    mutation removePartFromUserInventory($id: Int!) {
        removePartFromUserInventory(
            id: $id
        ){
            status
        }
    }`;


const PartsListEntries = ({colors}) => {

  const {store} = useContext(ReactReduxContext);
  const state = store.getState();
  const {categories} = state;

  const [removePart, {loading: mutationLoading, error: mutationError, data: mutationData}] = useMutation(REMOVE_PART);
  const [getParts, {data, loading, error}] = useLazyQuery(EXCHANGE_RATES, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    getParts()
  }, []);

  console.log('redraw');
  console.log('loading ' + !!loading);
  console.log('data ' + !!data);

  console.log('mutationLoading ' + !!mutationLoading);
  console.log('mutationData ' + !!mutationData);


  if (!data) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if(mutationLoading){
    showSendingNotification();
  }

  if(mutationData){
    let data = mutationData.removePartFromUserInventory;
    if(data.status){
      showSuccessNotification();
    } else {
      showErrorNotification(data.msg);
    }
  }

  if(mutationError){
    showErrorNotification();
  }

  console.log(categories);

  return data.parts.map((node) => {
    let content = <div>
      <div>{node.part_num}</div>
      <small><b>Name:</b> {node.part.name}</small><br/>
      <small><b>Color:</b> {colors[node.col_id].name} (ID: {node.col_id})</small><br/>
      <small><b>Condition:</b> {node.condition === 'new' ? 'NEW' : 'USED'}</small><br/>
      <div className={"tag"}>{categories[node.part.cat_id].name}</div>
    </div>

    return (
      <li key={node.id} id={`part-${node.id}`} className={"part"}>
        <ImageWidget content={content} color={node.col_id} set={node.part_num} type={BrickLinkImageType.part}
                     maxHeight={'100px'} maxWidth={'100%'} colorName={colors[node.col_id].name}/>
        <div className={"toolbar"}>
            <InventoryPartActions part_num={node.part_num} col_id={node.col_id} quantity={node.quantity} />
            <br/><br/>
          <div>
          <BricklinkButtonSet name={node.part_num} set={node.part_num} color={node.col_id} type={"P"} />&nbsp;
          <SearchButton part_num={node.part_num} cat_id={node.part.cat_id} />
          </div>
        </div>
      </li>
    )
  });
};

const Parts = (props) => {

  const {store} = useContext(ReactReduxContext);
  const state = store.getState();
  let {colors} = state;

  return (
    <div className={"inventory parts list"}>
      <nav className={"second-line"}>
        <ul className={"inventory-parts"}>
          <li>
            <Link className={"button"} to="/inventory/part/create">Add new part</Link>
          </li>
        </ul>
      </nav>
        <ul className={"tiles"}>
          <PartsListEntries colors={colors}/>
        </ul>
    </div>
  )
}

export default Parts
