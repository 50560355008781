import React from 'react'
import { Route, Switch } from 'react-router'
import Home from '../pages/Home'
import {Used as UsedSets, Sets, SetsOnSale, SetsReadyToSell, Minifigs, MinifigsReadyToSell, MinifigsOnSale} from '../pages/Sets/Used'
import NewSetList from '../pages/Sets/NewSetList'

import NoMatch from '../pages/NoMatch'
import NavBar from '../pages/NavBar.tsx'

import CreateSet from '../pages/Sets/Create'
import UpdateSet from '../pages/Sets/Update'
import InventorySet from '../pages/Sets/Inventory'
import MissingPartsInventorySet from '../pages/Sets/Missing'
import MissingBoxSets from '../pages/Sets/MissingBoxSets'
import MissingInstructionsSets from '../pages/Sets/MissingInstructionsSets'
import DeleteSet from '../pages/Sets/Delete'
import PrintSet from '../pages/Sets/Print'
import ExportSet from '../pages/Sets/Export'
import MissingPartsSets from '../pages/Sets/MissingPartsSets'

import CreateContainer from '../pages/Containers/Create'
import UpdateContainer from '../pages/Containers/Update'
import DeleteContainer from '../pages/Containers/Delete'
import ContainersList from '../pages/Containers/List'

import CreateStorage from '../pages/Storages/Create'
import UpdateStorage from '../pages/Storages/Update'
import DeleteStorage from '../pages/Storages/Delete'
import StoragesList from '../pages/Storages/List'

import InventoryMinifigs from '../pages/Inventory/Minifigs'
import InventoryMinifigCreate from '../pages/Inventory/Minifig/Create'
import InventoryParts from '../pages/Inventory/Parts'
import InventoryPartCreate from '../pages/Inventory/Part/Create'
import InventoryDecorations from '../pages/Inventory/Decorations'
import InventorySearch from '../pages/Search'

import MissingSetsPartList from '../pages/Sets/Missing/MissingSetsPartsList.tsx'

import InventoryWantedPartsList from '../pages/Inventory/InventoryWantedPartsList'

import DiscoveryMinifigs from '../pages/Discovery/DiscoveryMinifigs'
import DiscoverySetsByParts from '../pages/Discovery/DiscoverySetsByParts'
import DiscoverySetsByMinifigs from '../pages/Discovery/DiscoverySetsByMinifigs'

import SoldSets from '../pages/Sets/SoldSets'
import SellSet from '../pages/Sets/SellSet';

import CreateSetting from '../pages/Settings/Create'
import UpdateSetting from '../pages/Settings/Update'
import DeleteSetting from '../pages/Settings/Delete'
import SettingsList from "../pages/Settings/List";
import AlbumCategories from "../pages/Album/AlbumCategories";
import AlbumCategoryElements from "../pages/Album/AlbumCategoryElements";

import Game from "../pages/Game/Game";
import setPreview from "../pages/Sets/Preview";

import ListOfUserInstruction from '../pages/User/Instruction/Index.tsx'
import CreateUserInstruction from '../pages/User/Instruction/Create.tsx'
import EditUserInstruction from '../pages/User/Instruction/Edit.tsx'
import DeleteUserInstruction from '../pages/User/Instruction/Delete'

import UserSetsContain from "../pages/User/Sets/Contain";
import SetsContain from "../pages/Sets/Contain";

const routes = (
  <div>
    <NavBar />

    <Switch>
      <Route exact path="/" component={Home} />

      <Route exact path="/discovery/minifigs" component={DiscoveryMinifigs} />
      <Route exact path="/discovery/sets_by_parts" component={DiscoverySetsByParts} />
      <Route exact path="/discovery/sets_by_minifigs" component={DiscoverySetsByMinifigs} />

      <Route path="/search" component={InventorySearch} />

      <Route path="/inventory/minifig/create" component={InventoryMinifigCreate} />
      <Route path="/inventory/minifigs" component={InventoryMinifigs} />

      <Route path="/inventory/part/create" component={InventoryPartCreate} />
      <Route path="/inventory/parts" component={InventoryParts} />

      <Route path="/inventory/wanted/parts/list" component={InventoryWantedPartsList} />

      <Route path="/inventory/decorations" component={InventoryDecorations} />

      <Route path="/set/create" component={CreateSet} />
      <Route path="/set/update/:id" component={UpdateSet} />
      <Route path="/set/inventory/:id" component={InventorySet} />
      <Route path="/set/missing/:id" component={MissingPartsInventorySet} />
      <Route path="/set/print/:id" component={PrintSet} />
      <Route path="/set/export/:id" component={ExportSet} />
      <Route path="/set/delete/:id" component={DeleteSet} />
      <Route path="/set/sell/:id" component={SellSet} />

      <Route path="/set/preview/:id" component={setPreview} />

      <Route path="/user/sets/contain/:item/:col_id" component={UserSetsContain} />
      <Route path="/sets/contain/:item/:col_id" component={SetsContain} />

      <Route path="/sets/missing_parts" component={MissingPartsSets} />
      <Route path="/sets/box" component={MissingBoxSets} />
      <Route path="/sets/instructions" component={MissingInstructionsSets} />

      <Route path="/sets/used/:type/:on_sale" component={UsedSets} />

      <Route exact path="/sets/used" component={Sets} />
      <Route exact path="/sets/new" component={NewSetList} />
      <Route path="/sets/ready_to_sell" component={SetsReadyToSell} />
      <Route path="/sets/sold" component={SoldSets} />
      <Route path="/sets/on_sale" component={SetsOnSale}/>

      <Route exact path="/minifigs" component={Minifigs} />
      <Route exact path="/minifigs/ready_to_sell" component={MinifigsReadyToSell}/>
      <Route exact path="/minifigs/on_sale" component={MinifigsOnSale}/>

      <Route path="/container/create" component={CreateContainer} />
      <Route path="/container/update/:id" component={UpdateContainer} />
      <Route path="/container/delete/:id" component={DeleteContainer} />
      <Route exact path="/containers" component={ContainersList} />

      <Route path="/missing/sets/parts/list/:sets" component={MissingSetsPartList} />

      <Route path="/storage/create" component={CreateStorage} />
      <Route path="/storage/update/:id" component={UpdateStorage} />
      <Route path="/storage/delete/:id" component={DeleteStorage} />
      <Route exact path="/storages" component={StoragesList} />

      <Route path="/setting/create" component={CreateSetting} />
      <Route path="/setting/update/:id" component={UpdateSetting} />
      <Route path="/setting/delete/:id" component={DeleteSetting} />
      <Route exact path="/settings" component={SettingsList} />

      <Route exact path="/album" component={AlbumCategories} />
      <Route path="/album/:cat_id" component={AlbumCategoryElements} />

      <Route exact path="/game" component={Game} />

      <Route exact path="/user/instructions" component={ListOfUserInstruction} />
      <Route path="/user/instruction/edit/:id" component={EditUserInstruction} />
      <Route path="/user/instruction/delete/:id" component={DeleteUserInstruction} />
      <Route path="/user/instruction" component={CreateUserInstruction} />


      <Route component={NoMatch} />
    </Switch>

    <div id="data-error" className="data-error">Error!</div>
    <div id="data-sending" className="data-sending">Sending...</div>
    <div id="data-success" className="data-success">Done</div>

  </div>
)

export default routes
