import React, {useContext} from 'react';
import { Formik, Field } from 'formik';
import { useQuery, useMutation, gql } from '@apollo/client';
import { push } from 'connected-react-router'
import {connect, ReactReduxContext} from 'react-redux'
import {ImageWidget} from "../../../components/ImageWidget";
import {BrickLinkImageType} from "../../../components/BrickLinkImageSrc";

const ADD_PART = gql`
    mutation addPartToInventory($number: String!, $condition: String!, $quantity: Int!, $color: Int!,  $notes: String) {
        addPartToInventory(
            number: $number
            condition: $condition
            quantity: $quantity
            color: $color
            notes: $notes
        ){
            status
        }
    }`;

const Create = (props) => {

    const {store} = useContext(ReactReduxContext);
    const state = store.getState();
    const {colors} = state;

    const [addPart, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(ADD_PART);

    if (mutationLoading) return <p>Sending...</p>;
    if (mutationError) return <p>Error :(</p>;

    if(mutationData){
        props.push('/inventory/parts');
    }

    return (
        <div className={"sets form"}>
            <Formik
                initialValues={{number: '', quantity: 1, color: 0, condition: 'new', notes: ''}}
                validate={values => {
                    const errors = {};
                    if (!values.number) {
                        errors.number = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {

                    console.log(values);
                    addPart({
                        variables: values
                    });

                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ul>
                            <li>
                                <label>Set preview</label>
                            </li>
                            <li>
                                <div style={{maxWidth: 250}}>
                                    {values.number && values.color ? <ImageWidget set={values.color + '/' + values.number} type={BrickLinkImageType.part}/> : null}
                                </div>
                            </li>
                            <li>
                                <label htmlFor="number">Part Number</label>
                            </li>
                            <li>
                                <Field
                                    type="text"
                                    name="number"
                                    autocomplete="off"
                                />
                                {errors.number && touched.number && errors.number}
                            </li>
                            <li>
                                <label htmlFor="condition">Condition</label>
                            </li>
                            <li>
                                <Field as="select" name="condition">
                                    <option value="new">New</option>
                                    <option value="used">Used</option>
                                </Field>
                                {errors.condition && touched.condition && errors.condition}
                            </li>


                            <li>
                                <label htmlFor="color">Color</label>
                            </li>
                            <li>
                                <Field as="select" name="color">
                                    {colors.filter((node) => node != null).map((node) => (
                                        <option value={node.col_id}>{node.name}</option>
                                    ))}
                                </Field>
                                {errors.color && touched.color && errors.color}
                            </li>


                            <li>
                                <label htmlFor="quantity">Quantity</label>
                            </li>
                            <li>
                                <Field
                                    type="number"
                                    name="quantity"
                                    autocomplete="off"
                                />
                                {errors.quantity && touched.quantity && errors.quantity}
                            </li>

                            <li>
                                <label htmlFor={"notes"}>Notes</label>
                            </li>
                            <li>
                                <Field type={"text"} as={"textarea"} name={"notes"} rows={"5"}/>
                            </li>

                            <li>
                                <button type="submit" disabled={isSubmitting}>
                                    Create
                                </button>
                            </li>
                        </ul>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default connect(null, {push})(Create)
